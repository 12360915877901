import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import '../../theme/variables.css';
import '../style/admin/product.css';
import '../style/animations.css';
import '../style/controls/controls.css';
import '../style/cssvariables.css';
import '../style/ionic.css';
import '../style/layout.css';
import '../style/measurement-tool/measurement.css';
import "../style/store/store.css";
import '../style/style.css';
import '../style/ui-components/borders.css';
import '../style/ui-components/buttons.css';
import '../style/ui-components/design.css';
import '../style/ui-components/form.css';
import '../style/ui-components/tables.css';

import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';

import {
  API_INIT,
  apiGatwayAuthentication,
  createSession,
  getAllCategories,
  getAllSubCategories,
  getIpAddress,
  getProductsList,
  logout,
  tokenVerification
} from '../../services/api';
import PrivateRoute from '../../utils/PrivateRoute';
import PublicRoute from '../../utils/PublicRoute';
import { hideComponentLoader, showComponentLoader } from '../../utils/UI_methods/global';
import { Logging } from '../../utils/logging/logs';
import {
  filterProductsWithCategoryType,
  generateSessionId,
  getToken,
  getUser,
  getUserType,
  globalMethodsInit,
  removeUserSession,
  showTokenExpiryScreen,
  updateUserSession
} from '../../utils/methods';
import { applicationConfig } from '../../utils/moodboard/applicationConfig';
import { Store, getFilteredProductForDemo, getFilteredProductWithoutAddons } from '../../utils/store/storeConfiguration';
import EstreMaterials from '../Inventory/modules/Materials/Materials';
import EstreProducts from '../Inventory/modules/Products/Products';
import ProductDetails from '../admin/products/store/ProductDetails';
import StoreHomepage from '../admin/products/store/home/StoreHomepage';
import StoreOrders from '../admin/products/store/orders/StoreOrders';
import StoreCategoryProducts from '../admin/products/store/products/StoreCategoryProducts';
import StoreProductSearchResults from '../admin/products/store/products/StoreProductSearchResults';
import Approval from '../admin/products/store/quotation/approval/Approval';
import SharedProduct from '../admin/products/store/shared-product/SharedProduct';
import VariantCreater from '../admin/products/store/variant-creator-3d/VariantCreater3D';
import Login from '../login';
import SharedProductViewer from '../share/SharedProductViewer';
import Fabricate from '../admin/products/store/Fabricate/Fabricate';

/* Core CSS required for Ionic components to work properly */
/* Basic CSS for apps built with Ionic */
/* Optional CSS utils that can be commented out */

const StoreController: React.FC = () => {

  const [clientName,setClientName] = useState<string>("");
  const [CLIENT_ID,setClientId] = useState<number>(0);


  const [BASE_PATH] = useState<string>("http://13.233.90.128/model-viewer/model-viewer/");

 
 
  const [allCategories, setAllCategories]:Array<any> = useState([]);
  const [allSubCategories, setAllSubCategories]:Array<any> = useState([]);
  const [productsList, setProductsList]:Array<any> = useState([]);
 

  const [isLoadedPageConfiguration,setIsLoadedPageConfiguration]:Array<any> = useState(false);


  //Authentication and authorization
  const [isLogin, setIsLogin] = useState<boolean>(false);
  // const[token,setToken]:any = useState(null)
  

  //config objects
  const[applicationConfigObject,setApplicationConfigObject] = useState<applicationConfig>(null)
  const[storeConfig,setStoreConfig] = useState<any>(null)

  const[mainLoaderId] = useState<string>("mainLoader")
  const[isLoadedLogs,setIsLoadedLogs] = useState(false)


  var logs = null

  useEffect(()=>{
    let user = getUser()

   
    //Set is share mode false 
    if(user){
      if(user.userType === "admin"){
        updateUserSession(false,null)
      }
    }
    
  },[])




  useEffect(() => {
    if(!applicationConfigObject){
      // showGlobalToast("Session expired try refreshing",2000,"error")
      return
    }

    // if(isSubscriptionEnd()){
    //   handleLogout()
    //   return
    // }

    let isDataLoaded = false
    showComponentLoader(mainLoaderId)
    
    // updateLoaderProgress(mainLoaderId,1,1,"Fetching data..")
  
    let token:string = getToken()!

    if(token && !isDataLoaded){
      tokenVerification(token).then(async (data)=>{
        await apiGatwayAuthentication()

        //Check if user is online
        // if(!isError){
          let clientId = data?.clientId
          setClientId(data?.clientId)  
          setClientName(data?.clientName)
          applicationConfigObject.clientId = clientId
          applicationConfigObject.clientName = data?.clientName
          applicationConfigObject.isLiveClient = applicationConfigObject.liveClients?.includes(data?.clientName)
          API_INIT({clientId:clientId,shareItemId:data?.shareItemId,moduleName:data?.moduleName})
          let promiseArray = [getProductsList(),getAllCategories(),getAllSubCategories()]
          Promise.all(promiseArray)
          .then(function (apidata) {
            if(!isDataLoaded){
              applicationConfigObject.data.allProductsList = apidata[0]
              let products = filterProductsWithCategoryType(data.username,apidata[0])
              products = getFilteredProductForDemo(products)
              products = getFilteredProductWithoutAddons(products)

              if(getUser()?.shareItemDetails?.categoryName){
                let category = getUser()?.shareItemDetails?.categoryName
                products = products.filter(currProduct => currProduct.category_name === category)
              }
              applicationConfigObject.data.productsList = products
              applicationConfigObject.data.categories = apidata[1]
              applicationConfigObject.data.subCategories = apidata[2]
              setIsLogin(true)
              if(!window.location.pathname.toLowerCase().includes("customizein3d")){
                hideComponentLoader(mainLoaderId)
              }
            }
          })
          .catch(function (error) {
            console.log(error);
          });
        // }
      
      }).catch(err=>{
        // showGlobalToast("Session expired try refreshing",2000,"error")
        hideComponentLoader(mainLoaderId)
        handleLogout()
      })
    }else{
        // showGlobalToast("Session expired try refreshing",2000,"error")
        hideComponentLoader(mainLoaderId)
        handleLogout()
    }
    return () => {
      isDataLoaded = true
    }
  // },[isLogin,shareToken]);
},[applicationConfigObject]);




function isSubscriptionEnd(){
  let user = applicationConfigObject.user
  if(user){
    let subscriptionStartDate = user.subscriptionStartDate
    let subscriptionEndDate = user.subscriptionEndDate
    let enddate = Number(subscriptionEndDate)
    let currDate = Number(subscriptionStartDate)
    const diffTime = enddate - currDate;
    if(diffTime > -1){
        return false
    }else{
        return true
    }
  }
}



  function handleLogout() {
    if(getUserType() === "customer"){
      showTokenExpiryScreen()
    }else{
      logout()
      .then((response:any) => {
        removeUserSession()
      })
      .catch((error:any) => {
        console.log(error)
      });
    }
  }



 
  useEffect(()=>{
    let configObj:any = new applicationConfig(CLIENT_ID,"",0,0,'','',[],{})
    let store:any = new Store()
    configObj?.setBasePathAssets(BASE_PATH)
    configObj.setUser(getUser())
    setStoreConfig(store)
    setApplicationConfigObject(configObj)
  },[])

  
  useEffect(()=>{
    // let configObj:any = new applicationConfig(CLIENT_ID,clientName,0,0,'','',[],{})

    //Create config object for the application which will contain the data and other 

    if(applicationConfigObject){
      setLogs()
      globalMethodsInit(applicationConfigObject,logs)
    }
  },[allCategories,allSubCategories,productsList,isLogin,applicationConfigObject])
 


    function setLogs() {
      if(!isLoadedLogs){
        let sessionId = generateSessionId(32)
        logs = new Logging(applicationConfigObject?.user?.username || "Guest",sessionId)
        getIpAddress().then(ipInfo=>{
          saveSessionToDatabse(sessionId,ipInfo.ipAddress)
        }).catch(err=>{
          console.log(err)
          saveSessionToDatabse(sessionId,"")
        })
        globalMethodsInit(applicationConfigObject,logs)
      }
      setIsLoadedLogs(true)
    }

    function saveSessionToDatabse(sessionId:string,ipAddress:string) {
        createSession(sessionId,ipAddress).then(data=>{
          const insertId = data.data.data.id
          applicationConfigObject.setSessionId(insertId)
          logs.setSessionDatabaseId(insertId)
          logs.info("login","Session Start",`START SessionId: ${sessionId}`)
        }).catch(err=>{
          console.log(err)
          logs.error("login","Session Start",`START unable to create session:`)
        })
    }


    function setLoginTrue(){
      // setIsLogin(true)
    }
  

  return (

    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>

          <PublicRoute path="/login" component={()=>(<Login
            setLoginTrue = {setLoginTrue}
            // setToken={setToken}
          />)} exact={true} />

             

          <PrivateRoute 
              path="/"
              component={()=>(
                <StoreHomepage
                  configObj = {applicationConfigObject}
                  setIsLoadedPageConfiguration={setIsLoadedPageConfiguration}
                  isLoadedPageConfiguration={isLoadedPageConfiguration}
                  isLogin={isLogin}
                />
              )}
              exact={true}
          />



            <PrivateRoute 
              path="/products"
              component={()=>(
                <StoreHomepage
                  configObj = {applicationConfigObject}
                  setIsLoadedPageConfiguration={setIsLoadedPageConfiguration}
                  isLoadedPageConfiguration={isLoadedPageConfiguration}
                  isLogin={isLogin}
                />
              )}
              exact={true}
            />

            <PrivateRoute 
              path="/search"
              component={()=>(
                <StoreProductSearchResults
                  configObj = {applicationConfigObject}
                  setIsLoadedPageConfiguration={setIsLoadedPageConfiguration}
                  isLoadedPageConfiguration={isLoadedPageConfiguration}
                  isLogin={isLogin}
                />
              )}
              exact={true}
            />

            <Route 
              path="/inventory/products"
              component={()=>(
                <EstreProducts
                  configObj = {applicationConfigObject}
                  setIsLoadedPageConfiguration={setIsLoadedPageConfiguration}
                  isLoadedPageConfiguration={isLoadedPageConfiguration}
                  isLogin={isLogin}
                />
              )}
              exact={true}
            />

            
          <Route
            path="/product-viewer"
            component={()=>(
              <SharedProductViewer
                configObj={applicationConfigObject}
              />
            )}
            exact={true}
          />

            <Route 
              path="/inventory/materials"
              component={()=>(
                <EstreMaterials
                  configObj = {applicationConfigObject}
                  setIsLoadedPageConfiguration={setIsLoadedPageConfiguration}
                  isLoadedPageConfiguration={isLoadedPageConfiguration}
                  isLogin={isLogin}
                />
              )}
              exact={true}
            />

            <Route 
              path="/view/:token"
              component={()=>(
                <Approval
                  
                />
              )}
              exact={true}
            />

            <Route 
              path="/share/:token"
              component={()=>(
                <SharedProduct
                  configObj = {applicationConfigObject}
                  setIsLoadedPageConfiguration={setIsLoadedPageConfiguration}
                  isLoadedPageConfiguration={isLoadedPageConfiguration}
                  setIsLogin={setIsLogin}
                />
              )}
              exact={true}
            />


            <PrivateRoute 
              path="/store/product-details/:sku"
              component={()=>(
                <ProductDetails
                  configObj = {applicationConfigObject}
                  setIsLoadedPageConfiguration={setIsLoadedPageConfiguration}
                  isLoadedPageConfiguration={isLoadedPageConfiguration}
                  isLogin={isLogin}
                />
              )}
              exact={true}
            />

            <PrivateRoute 
              path="/customizein3d"
              component={()=>(
                <VariantCreater
                  configObj = {applicationConfigObject}
                  isLogin={isLogin}
                />
              )}
              exact={true}
            />

            <PrivateRoute 
              path="/kanvas"
              component={()=>(
                <Fabricate
                  configObj = {applicationConfigObject}
                  isLogin={isLogin}
                />
              )}
              exact={true}
            />



          <PrivateRoute 
              path="/store/products/:category"
              component={()=>(
                <StoreCategoryProducts
                  configObj = {applicationConfigObject}
                  setIsLoadedPageConfiguration={setIsLoadedPageConfiguration}
                  isLoadedPageConfiguration={isLoadedPageConfiguration}
                  isLogin={isLogin}
                />
              )}
              exact={true}
          />

            <PrivateRoute 
              path="/orders"
              component={()=>(
                <StoreOrders
                  configObj = {applicationConfigObject}
                  setIsLoadedPageConfiguration={setIsLoadedPageConfiguration}
                  isLoadedPageConfiguration={isLoadedPageConfiguration}
                  isLogin={isLogin}
                />
              )}
              exact={true}
            />
 

          {/* <Route exact path="/" render={() => <Redirect to="/" />} /> */}
          <Redirect to="/login" />

        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default StoreController;
