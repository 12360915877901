import { getProductsList } from "../../services/api"
import { StoreApi } from "../../services/storeApis"
import { untarAndSaveToCache } from "../cache/cache"
import { addItemToLocalStorage, getItemFromLocalStorage } from "../cache/localstorage"
import { Configuration, Module } from "../customizein3d/Configuration"
import { ModelViewer } from "../customizein3d/productViewer/productViewer"
import { TextureInfo } from "../customizer/ProjectConfiguration"
import { getUrlPrefix } from "../customizer/customizer"
import { categoryForStore, productsForDemo, productsForOpusTl } from "../data"
import { applicationConfig, getObjectByParameter, getRandomString, getUser } from "../methods"
import { addonsGroup, modulesGroup } from "../moodboard/modules/sofa"
import { getFormattedPrice, getRandomNumber } from "../products/productviewer_shopify"
import { displayImages } from "./UI_methods"
import { Cot_Storage_Options, Cot_Variants } from "./customization-options-data/cot"
import { Reclining_Options } from "./customization-options-data/recliner"
import $ from "jquery"


export var STORE:Store = null
var customerKey = "store_customer"


export class Store{
    
    customers:Array<Customer> = []
    activeCustomer:Customer = null
    
    cartItems:Array<any> = []

    storeApi:StoreApi = new StoreApi()
    isCustomerSessionActive:boolean = false

    mainLoaderId:string = "storeLoader"

    data = new Data()

    BASE_URL_ASSETS:string = `https://opusassets.s3.ap-south-1.amazonaws.com/public/estre/assets/`

    currProduct = null
    productConfiguration:any = null
    productConfiguration3D:Configuration = null

    modelViewer:ModelViewer = new ModelViewer()

    isViewin3D:boolean = false
    isPresentingWebXr:boolean = false



    pricing = {
        sofa: new SofaPricing()
    }

    galleryIndex:number = 0

    currSelectedSwingPart:string = ""

    checkoutPricing = {
        totalPrice:0,
        totalDiscount:0,
        advanceAmount:0,
    }


    functionRefs = {
        setSearchedProductsList:null,
        closeProductSearch:null,
        checkout:{
            setCartItems:null,
            setIsCheckoutFromQuotation:null
        },
        productCustomizer:{
            setIsPriceAvailable:null,
            refreshAdvanceOptions:null,
            openAdvanceConfiguration:null
        }
    }

    currDiscount = 0

    cartItemsDiscount = {

    }

    constructor(){
        this.createConfiguration()
        STORE = this
    }


    createConfiguration(){
        const customer = getItemFromLocalStorage(customerKey)
        if(customer){
            this.activeCustomer = new Customer({
                customer_id:customer.id,
                customerSessionId:customer.customerSessionId,
                customer_name:customer.customerName,
                mobile:customer.mobile,
                email:customer.email,
            })
        }
    }
    

    addCustomer(phone:number){
        let info = {
            firstName:getRandomString(5),
            lastName:getRandomString(5),
            email:getRandomString(5),
            phone:getRandomNumber(9132569857,9874586521),
        }
        this.customers.push(new Customer(info))      
    }

    setActiveCustomer(customer:Customer){
        this.activeCustomer = customer
        this.updateCustomerSessionInLocalStorage(customer)  
    }

    updateCustomerSessionInLocalStorage(customer:Customer){
        addItemToLocalStorage(customerKey,customer)
    }

    getProductName(productDetails:any){
        return productDetails?.product_type !== "custom" ? productDetails?.product_name : "Not Applicable"
    }


    getTotalPrice(){
        for (const currItem of this.cartItems) {
        }
    }

    getCurrCategory(){
        return this.currProduct?.category_name || ""
    }


    getProductImageUrl(product:any){
        let variantName = this.productConfiguration.variant.variantName
        let material = this.productConfiguration.texture
        let productName = product.product_name
        let url = ""
        if(material){
            let materialCode = material.materialCode
            let collectionName = material.collectionName
            if(this.galleryIndex == 0 && false){
                url = `${productName}_${variantName}_${materialCode}_HDRI.webp`
            }else{
                url = `${productName}_${variantName}_${collectionName}_${materialCode}_Camera${this.galleryIndex || 1}.webp`
            }
        }else{
            url = `${productName}_${variantName}_Default_Default_Camera${this.galleryIndex || 1}.webp`
        }
        return `productImages/${applicationConfig?.clientName}/${product.category_name}/${product.sub_category_name}/${productName}/${url}`
    }

    async updateProductImage(imageContainerId:string,product:any) {
        const productImageElement:any = document.getElementById(imageContainerId)
        if(productImageElement){
        //   const categoryName = product.category_name
        //   const subCategoryName = product.sub_category_name
        //   const productName = product.product_name
          let imageUrlKey = this.getProductImageUrl(product)
        //   let urlPrefix = getUrlPrefix(imageUrlKey);
        //   let tarFileKey = applicationConfig.awsConfig.getTarFileKey("products",{
        //     clientName: applicationConfig?.clientName,
        //     category: categoryName,
        //     subCategory: subCategoryName,
        //     productName: productName,
        //   });
        //   await untarAndSaveToCache(applicationConfig.awsConfig, urlPrefix, tarFileKey)
          displayImages(productImageElement,imageUrlKey);
          console.log(imageUrlKey)
        }
        this.updateGalleryImages(imageContainerId,product)
    }

    updateGalleryImages(imageContainerId:string,product:any){
        let allCameras = ["Camera1","Camera2","Camera3"]
        const productName = product.product_name
        let variantName = this.productConfiguration.variant.variantName
        let material = this.productConfiguration.texture
        let materialCode = material.materialCode
        let collectionName = material.collectionName

        for (let i = 0; i < allCameras.length; i++) {
            const currCamera = allCameras[i];
         
            const productImageElements = document.querySelectorAll(`.${imageContainerId}_${i+1}`);
            // Iterate over all selected elements
            productImageElements.forEach((productImageElement) => {
                let prefix = `productImages/${applicationConfig?.clientName}/${product.category_name}/${product.sub_category_name}/${productName}/`;
                let imageUrlKey = `${productName}_${variantName}_${collectionName}_${materialCode}_${currCamera}.webp`;
                displayImages(productImageElement, prefix + imageUrlKey);
            });
            
        }
    }

}

 

export class Customer{
    id:number = getRandomNumber(1,2548)
    customerName:string
    mobile:string
    email:string
    addresses:Array<Address> = []
    customerSessionId:number = null

    constructor(customer:any){
        if(customer){
            this.id = customer.customer_id
            this.customerName = customer.customerName || customer.customer_name
            this.mobile = customer.mobile
            this.email = customer.email
            this.customerSessionId = customer.customerSessionId
        }
    }
}


export class Address{
    id:number = getRandomNumber(1,2548)
    streetNumber:string
    addressLine1:string
    addressLine2:string
    city:string
    state:string
    country:string
    postalCode:number
    isDefault:boolean = false

    constructor(address:any){
        if(address){
            this.streetNumber = address.streetNumber || ""
            this.addressLine1 = address.addressLine1
            this.addressLine2 = address.addressLine2
            this.city = address.city
            this.state = address.state
            this.country = address.country
            this.postalCode = address.postalCode
        }
    }
}


class Data{

    productsList = []
    materialList = []
    configPrices = []
    modulesPrice = []

    materialsForCustomization = []

    constructor(){
        
    }


    async fetch(){
        const promises = [getProductsList()]
        return Promise.all(promises).then(data=>{
            this.productsList = data[0].data.data || []
        }).catch(err=>{

        })
    }
}


export class ProductConfiguration{

    //For generate quotation
    clientName = applicationConfig.clientName
    clientId = applicationConfig.clientId

    variant:Variant = new Variant()

    isCustomVariant:boolean = false
    isCustomOrder:boolean = false


    materials:Array<MaterialAndPart> = []
    instructions:Array<Instruction> = []
    quality:string = "Basic"
    dimensions = new Dimensions()

    price:number = 0

    isPriceSetManually:boolean = false

    texture:TextureInfo

    constructor(defaultConfiguration:any){
        if(defaultConfiguration){
            defaultConfiguration = JSON.parse(defaultConfiguration)
            const variant = defaultConfiguration.variant
            if(variant){
                this.variant.variantName = variant 
            }
        }


    }

    setDefaultVariant(){

    }

    addMaterials(materials:Array<any>){
        this.materials = []
        for (const material of materials) {
            let materialAndPart = new MaterialAndPart(material)
            this.materials.push(materialAndPart)
        }
    }

    getQualityPrice(quality:string){
        let priceToAdd = 0
        if(quality === "Superior"){
            priceToAdd = 2800
        }
        if(quality === "Premium"){
            priceToAdd = 4500
        }
        if(quality === "Exclusive"){
            priceToAdd = 8000
        }
        return priceToAdd
    }

}


export class SofaConfiguration extends ProductConfiguration{
    
    fabric:string = ""
    addon:Array<Addon> = []
    cushionFeel:string = ""
    removableSeat:string = ""
    seatSplit:string = ""

    seatHeight:number = 0
    legsHeight:number = 0
    handleHeight:number = 0
    backHeight:number = 0

    customModulesList:Array<Module> = []


    constructor(variant:any,defaultConfig:any = null) {
        super(defaultConfig);
        if(variant){
            this.variant.variantName = variant.variantName
        }
    }

    updatePriceWithVariant() {
        this.price = this.getVariantPrice()
    }

    getVariantPrice(){
    

        if(this.isPriceSetManually){
            return this.price
        }

        let price = 0
        let isPriceAvailable = false

        if(this.isCustomVariant){
            let modulesList = [...Object.values(modulesGroup.children),...Object.values(addonsGroup.children)]
            for (const currModule of modulesList) {
                if(typeof currModule === "object"){
                    const moduleName = currModule.attrs?.product?.productName
                    let priceDetails = getObjectByParameter(STORE.data.modulesPrice,"module_name",moduleName)
                    if(priceDetails){
                        price += Number(priceDetails.price)
                        isPriceAvailable = true
                    }
                }
            }
            if(STORE.functionRefs.productCustomizer.setIsPriceAvailable){
                STORE.functionRefs.productCustomizer.setIsPriceAvailable(isPriceAvailable)
            }
            return price
        }
        let configPrices = STORE.data.configPrices
        for (const currConfig of configPrices) {
          let configuration = JSON.parse(currConfig.configuration)
          if(this.variant.variantName === configuration.variant.variantName){
              price = Number(configuration.price)
              price = getPriceWithVariantSize(this.variant,price)
              isPriceAvailable = true
          }
        }
        if(STORE.functionRefs.productCustomizer.setIsPriceAvailable){
            STORE.functionRefs.productCustomizer.setIsPriceAvailable(isPriceAvailable)
        }
        return price
    }

    getBasePrice(){
        return this.getVariantPrice() 
    }

    updatePriceWithQuality(quality:string) {
        this.price = this.price + this.getQualityPrice(quality)
    }

    

    updatePriceWithCushionFeel() {
        let priceToAdd = STORE.pricing.sofa.cushion * this.variant.noOfSeats
        return this.price + priceToAdd
    }

    updatePriceWithInstructons() {
        for (const instruction of this.instructions) {
            this.price = this.price + Number(instruction.price)
        }
    }

    updatePrice(){
        this.updatePriceWithVariant()
        this.updatePriceWithQuality(this.quality)
        if(this.cushionFeel){
            this.updatePriceWithCushionFeel()
        }
        if(this.addon){
            for (const currAddon of this.addon) {
                this.price = this.price + (currAddon.price * currAddon.quantity)
            }
        }
        if(this.instructions){
            this.updatePriceWithInstructons()
        }
        $(".total_price").text(getFormattedPrice(this.price))
    }

    setCustomModulesList(){
        this.customModulesList = []
        let modulesList = [...Object.values(modulesGroup.children),...Object.values(addonsGroup.children)]
        for (const currModule of modulesList) {
            if(typeof currModule === "object"){
                let moduleDetails = currModule.attrs.product
                const module = {
                    module_name:moduleDetails.productName,
                    module_type:moduleDetails.productName,
                    sub_module_type:moduleDetails.productName,
                }
                this.customModulesList.push(new Module(module,currModule.attrs.isAddedAsAddon))
            }
        }
    }

    setCustomModulesList3D(modulesConfigurtion:any){
        this.customModulesList = []
        for (const currModule of modulesConfigurtion.modules) {
              this.customModulesList.push(currModule)
        }
    }
}


export class CotConfiguration extends ProductConfiguration{
    
    length:string = "72"
    variant:Variant = new Variant(Cot_Variants[0].name)
    storage:CotStorage = new CotStorage()


    constructor(variant:Variant,defaultConfig:any = null) {
        super(defaultConfig);
        if(variant){
            this.variant.variantName = variant.variantName
        }
    }

    getBasePrice(){
        return 0
    }

    updatePrice() {
        let price = 0
        let configPrices = STORE.data.configPrices
        for (const currConfig of configPrices) {
          let configuration = JSON.parse(currConfig.configuration)
          if(this.variant.variantName === configuration.variant.variantName &&
            this.length === configuration.length &&
            this.storage.name === configuration.storage.name &&
            this.storage.type === configuration.storage.type){
              price = Number(configuration.price)
          }
        }
        price = this.updatePriceWithQuality(this.quality,price)
        price = getPriceWithVariantSize(this.variant,price)
        this.price = price
    }

    updatePriceWithQuality(quality:string,price:number) {
        let priceToAdd = 0
        if(quality === "Superior"){
            priceToAdd = 4500
        }
        if(quality === "Premium"){
            priceToAdd = 6000
        }
        if(quality === "Exclusive"){
            priceToAdd = 9500
        }
        return price + priceToAdd
    }
}

export class ReclinerConfiguration extends ProductConfiguration{
    
    recliningOption:string = Reclining_Options[0].name
    addon:Array<Addon> = []

    constructor(variant:any,defaultConfig:any = null) {
        super(defaultConfig);
        if(variant){
            this.variant.variantName = variant.variantName
        }
    }

    getBasePrice(){
        return 0
    }

    updatePrice() {
        let price = 0
        let configPrices = STORE.data.configPrices
        for (const currConfig of configPrices) {
            let configuration = JSON.parse(currConfig.configuration)
            if(this.recliningOption === configuration.recliningOption){
                price = Number(configuration.price)
            }
          }
        price = this.updatePriceWithQuality(this.quality,price)
        this.price = price
    }

    updatePriceWithQuality(quality:string,price:number) {
        let priceToAdd = 0
        if(quality === "Superior"){
            priceToAdd = 4500
        }
        if(quality === "Premium"){
            priceToAdd = 6000
        }
        if(quality === "Exclusive"){
            priceToAdd = 9500
        }
        return price + priceToAdd
    }
}


export class ArmChairConfiguration extends ProductConfiguration{
    
    variant:Variant = new Variant()

    fabric:string = ""
    addons:string = ""
    legs:string = ""
    cushionFeel:string = ""
    removableSeat:string = ""

    constructor(defaultConfig:any = null) {
        super(defaultConfig);
    }

    getBasePrice(){
        return 0
    }

    updatePrice() {
        let price = 0
        let configPrices = STORE.data.configPrices
        price = this.updatePriceWithQuality(this.quality,price)
        price = getPriceWithVariantSize(this.variant,price)
        this.price = price
    }

    updatePriceWithQuality(quality:string,price:number) {
        if(price === 0){
            return 0
        }
        let priceToAdd = 0
        if(quality === "Superior"){
            priceToAdd = 2800
        }
        if(quality === "Premium"){
            priceToAdd = 4500
        }
        if(quality === "Exclusive"){
            priceToAdd = 8000
        }
        return price + priceToAdd
    }
}


export class WardrobeConfiguration{
    
    hasLoft:boolean = false
    hasMorror:boolean = false
    
    constructor(){

    }
}


export class SwingConfiguration{
    
    hasStand:boolean = false
    
    constructor(){

    }
}



export class Variant{
    
    variantName:string = ""
    size:string = "Standard"
    type:string = "LHS"
    noOfSeats:number=0
    
    constructor(variantName:string = ""){
        this.variantName = variantName
    }
}

export class Addon{
    
    id:number
    name:string = ""
    size:string = ""
    type:string = ""
    quantity:number = 1
    price:number = 0

    constructor(config:any,quality:string){
        this.id = config.id
        this.name = config.name
        this.size = config.size
        this.type = config.type
        if(config.pricing){
            this.price = config.pricing[quality]
        }
    }
}

export class CotStorage{
    
    name:string = ""
    type:string = ""
    
    constructor(){
        this.name = Cot_Storage_Options[0].name
    }
}

export class Instruction{
    id:number = 0
    instruction:string = ""
    price:number = 0

    constructor(instruction:string){
        this.id = getRandomNumber(1,200)
        this.instruction = instruction
    }
}

export class MaterialAndPart{
    id:number = 0
    material:TextureInfo
    partNames:Array<string> = []

    constructor(texture:any){
        this.id = getRandomNumber(1,200)
        this.material = new TextureInfo(texture)
    }

    addPart(partName:string){
        this.partNames.push(partName)
    }
}

export class SofaPart{
    id:number = 0
    partName:string = ""
    partCode:string = ""

    constructor(id:number,part:string,partCode:string = ""){
        this.id = id
        this.partName = part
        this.partCode = partCode
    }

}

export class Dimensions{
    length:number = 0
    depth:number = 0

    constructor(){
    }

}

export class SofaPricing{
    
    cushion:1200
    
    constructor(){

    }
}

  function getPriceWithVariantSize(variant:Variant,price:number) {
    let priceToAdd = 0
    if(variant.size === "XL"){
      priceToAdd = price + (price * 0.25)
    }
    return price + priceToAdd
  }

  export function getCategoryPartsList(category:string) {
    let sofaParts = []
    if(category === "Sofa" || category === "ArmChair"){
      sofaParts.push(new SofaPart(1,"Base and ArmRest","A1"))
    //   sofaParts.push(new SofaPart(2,"SeatCushion & BackCushion","A2"))
      sofaParts.push(new SofaPart(2,"Base and ArmRest","A2"))
      sofaParts.push(new SofaPart(3,"Throw Pillows","A3"))
    }
    if(category === "Cot"){
      sofaParts.push(new SofaPart(1,"Upholstery"))
    }
    return sofaParts
  }

export function getFilteredProductForDemo(products:any = applicationConfig?.data?.productsList) {
    let domain = window.location.hostname
    domain = domain.toLowerCase()
    products = products?.filter(currProduct => currProduct.product_type !== "custom")
    if(domain.includes("elevate")){
        return products?.filter(currProduct => 
            currProduct.product_name === "Apollo"||
            currProduct.product_name === "Fantasy"
        ) 
    }
    if(getUser()?.username === "DemoMaster"){
        return products
    }
    if(getUser()?.username === "DemoClient_3"){
        return products
    }
    if(getUser()?.username === "DemoClient_3"){
        return products
    }
    if(getUser()?.username === "opus_tl" || getUser()?.username === "opus_tl2" || getUser()?.username === "opus_Shreedhar"){
        let result =  products?.filter(currProduct => productsForOpusTl.includes(currProduct.product_name))
        return result
    }
    if(applicationConfig?.clientName === "DemoClient2"){
        return products?.filter(currProduct => productsForDemo.includes(currProduct.product_name))
    }
    return products
}


export function getFilteredProductWithoutAddons(products:any = applicationConfig?.data?.productsList) {
    if(applicationConfig.isMasterLogin){
        categoryForStore.push("CentreTable")
        categoryForStore.push("Swing")
    }
    return products?.filter(currProduct => categoryForStore.includes(currProduct.category_name))
}

export function getImageFromModules(configuration:any,product:any,productConfiguration:any = {}) {

    let fileName = ""
    let modules = configuration.modules
    if(configuration?.isLshapeWithSingleSeater){
        modules = configuration.modules.concat(configuration.addons)
    }
    for (const module of modules) {
      let moduleName = module.moduleName
      moduleName = moduleName.replace("_XL","")
      moduleName = moduleName.replace("_Centre_Console","")
      moduleName = moduleName.replace("_Centre_Console_XL","")
      fileName = fileName + moduleName + "_"
    }
    fileName = fileName.slice(0, -1)
    let subCategory = product.sub_category_name
    let result = `https://opusassets.s3.ap-south-1.amazonaws.com/public/store/icons/Sofa/${fileName}.webp`
    return result
    let variantName = encodeURIComponent("2+Corner+2")
    let modulesList = productConfiguration.customModulesList
    let url = `${STORE.BASE_URL_ASSETS}icons/variants/${"Sofa"}/${"Standard"}/${variantName}.svg`
    if(subCategory.toLowerCase().includes("setsofa")){
      
      if(modulesList.length === 1){
        let variantNameString = ""
        let currModule = modulesList[0]
        let moduleName = currModule.displayName || ""
        let seats = moduleName.split("")[0] 
        variantNameString = `${seats}`
        variantName = encodeURIComponent(`${seats}Seater`)
        url = `${STORE.BASE_URL_ASSETS}icons/variants/${"Sofa"}/${"Standard"}/${variantName}.svg`
      }else{
        let variantNameString = ""
        for (const currModule of modulesList) {
          let moduleName = currModule.displayName || ""
          let seats = moduleName.split("")[0] 
          variantNameString = `${variantNameString}+${seats}`
        }
        variantNameString = variantNameString.substring(1)
        url = `${STORE.BASE_URL_ASSETS}icons/variants/${"Sofa"}/${"Standard"}/${encodeURIComponent(variantNameString)}.svg`
      }
      return url
    }
    if(isCorner(modulesList)){
      let count = modulesAfterCorder(modulesList)>2?3:2
      variantName = `2+Corner+${count}${count===3?"_RHS":""}`
      url = `${STORE.BASE_URL_ASSETS}icons/variants/${"Sofa"}/${"Standard"}/${encodeURIComponent(variantName)}.svg`
    }else{
      let count = modulesList.length>2?3:2
      if(modulesList[0]?.displayName?.includes("Chaise")){
        variantName = `2+Lounger${"_LHS"}`
      }else if(modulesList[modulesList.length-1]?.displayName?.includes("Chaise")){
        variantName = `2+Lounger${"_RHS"}`
      }else{
        variantName = `${count}Seater`
      }
      url = `${STORE.BASE_URL_ASSETS}icons/variants/${"Sofa"}/${"Standard"}/${encodeURIComponent(variantName)}.svg`
    }
    return url
  }

    

  function isCorner(modulesList:any) {
    return getObjectByParameter(modulesList,"moduleType","Corner")
  }

  function modulesAfterCorder(modulesList) {
    let index = 2
    let isFoundCorner = false
    for (const currModule of modulesList) {
      if(isFoundCorner){
        index += 1
      }
      if(currModule.moduleType === "Corner"){
        isFoundCorner = true
      }
    }
    return index
  }