import React, { useEffect, useState } from "react";
import { hideComponentLoader } from "../../../../../utils/UI_methods/global";
import { configuration, releaseMemory, setCustomizein3dConfiguration } from "../../../../../utils/customizein3d/modules/customizein3d";
import { applicationConfig } from "../../../../../utils/methods";
import { getPageTourElements } from "../../../../../utils/siteTour/elements";
import { STORE } from "../../../../../utils/store/storeConfiguration";
import Canvas from "../../../../common/modules/customizein3d/canvas/Canvas";
import CameraSettings from "../../../../common/modules/customizein3d/canvas/Render/CameraSettings";
import Actions from "../../../../common/modules/customizein3d/controls/Actions";
 

const CanvasContainer: React.FC<{
  product:any
  productConfiguration:any
  setIsOpenAdvanced:(val:boolean)=>void
  isElevateMode:any
  changeProduct:(val:any)=>void
  isProductChanged:boolean
  modulesConfigurtion:any
  setModulesConfiguration:(val:any)=>void
}> = (props) => {
 
  
    const[isSpacePlannerMode,setIsSpacePlannerMode] = useState(false)
    const[isFloorplanMode,setIsFloorplanMode] = useState(false)
    const[isTakeRenderMode,setIsTakeRenderMode] = useState(false)
    const[isViewProductMode,setIsViewProductMode] = useState(configuration?.isViewProductMode)
    const[showGoBackAlert,setShowGoBackAlert] = useState(false)
    const[tourElementsList] = useState(getPageTourElements("VariantCreator3D"))


    useEffect(() => {
      if(!props.isProductChanged){
        setTimeout(() => {
          init(props.product)
          hideComponentLoader("mainLoader")
        }, 300);
    
        applicationConfig.setFunctionRef("customizein3d",{setIsViewProductMode:setIsViewProductMode})
        applicationConfig.setFunctionRef("customizein3d",{toggleSpacePlannerValue:setIsSpacePlannerMode})
        applicationConfig.setFunctionRef("customizein3d",{setShowGoBackAlert:setShowGoBackAlert})
        applicationConfig.setFunctionRef("customizein3d",{setIsTakeRenderMode:setIsTakeRenderMode})
        applicationConfig.setFunctionRef("customizein3d",{setIsFloorplanMode:setIsFloorplanMode})
      }
          
    }, [props.product]);

  
    function init(product:any) {
      releaseMemory()
      let configuration = setCustomizein3dConfiguration(product,isViewProductMode)
      props.setModulesConfiguration(configuration)
      STORE.productConfiguration3D = configuration
    }
  
  
    return (
          <div>
              {props.modulesConfigurtion?
              <Canvas
                modulesConfigurtion={props.modulesConfigurtion}
                isSpacePlannerMode={isSpacePlannerMode}
                isFloorplanMode={isFloorplanMode}
                currProduct={props.product}
                isViewProductMode={isViewProductMode}
                setIsSpacePlannerMode={setIsSpacePlannerMode}
                isElevateMode={props.isElevateMode}
              />
            :null}
              {isTakeRenderMode?
                <CameraSettings
                />
              :null}
              <Actions
                configObj={applicationConfig}
              />
          </div>
    );
};

export default CanvasContainer;
