import React, { useEffect, useState } from "react";
import { configuration, updateTexture } from "../../../../../utils/customizein3d/modules/customizein3d";
import "../../../../style/controls/customizeLeftControls.css";
import Materials from "../../../shared-components/Materials/Materials";
import HorizontalScroll from "../../../ui-components/scrollbar/HorizontalScroll";
import { applicationConfig, categoryFilteredTextures, getClientFilteredMaterials, getFilteredArray, getObjectByParameter, getUser } from "../../../../../utils/methods";
import { STORE } from "../../../../../utils/store/storeConfiguration";

const SwingFinish: React.FC<{
  
}> = (props) => {

  const[partsList,setPartsList] = useState([
    {
      partName:"Platform",
      img:"https://opusassets.s3.ap-south-1.amazonaws.com/public/store/icons/Swing/parts/Platform.webp"
    },
    {
      partName:"Stand",
      img:"https://opusassets.s3.ap-south-1.amazonaws.com/public/store/icons/Swing/parts/Stand.webp"
    },
    {
      partName:"Rope",
      img:"https://opusassets.s3.ap-south-1.amazonaws.com/public/store/icons/Swing/parts/Rope.webp"
    },
    {
      partName:"Handle",
      img:"https://opusassets.s3.ap-south-1.amazonaws.com/public/store/icons/Swing/parts/Handle.webp"
    },
    {
      partName:"Upholstery",
      img:"https://opusassets.s3.ap-south-1.amazonaws.com/public/store/icons/Swing/parts/Upholstery.webp"
    },
  ])
  const[currPart,setCurrPart] = useState("Platform")
  const [allMaterials,setallMaterials]: any = useState([]);
  const [partMaterials,setPartMaterials]: any = useState([]);

  const[partMaterialsMapping] = useState([
    {
      partName:"Base",
      materialTypes:["Wood"]
    },
    {
      partName:"Stand",
      materialTypes:["Metal"]
    },
    {
      partName:"Handle",
      materialTypes:["Metal"]
    },
    {
      partName:"Rope",
      materialTypes:["Metal"]
    },
    {
      partName:"Platform",
      materialTypes:["Wood","Acrylic"]
    },
    {
      partName:"Upholstery",
      materialTypes:["Fabric","Leather"]
    }
  ])
  useEffect(()=>{
    let materials = applicationConfig?.data.materials || []
    if(configuration){
      let currProduct = configuration.product
      // materials = getClientFilteredMaterials(materials)
      materials = materials.filter(currMaterial=>  currMaterial.company_name === "Metal" || currMaterial.company_name === "Acrylic" || currMaterial.company_name === "Sarom" || currMaterial.company_name === "Darpan" || currMaterial.material_type === "Wood")
      let categoryName = currProduct?.categoryName || currProduct?.category_name
      materials = categoryFilteredTextures(materials,applicationConfig?.data.objectMaterialTypes,categoryName)

      if(getUser()?.isShareMode){
        let collectionNames = getUser()?.collectionNames
        if(collectionNames?.length){
          materials = materials.filter(currMaterial=> collectionNames.includes(currMaterial.collection_name))
        }
      }

      STORE.data.materialsForCustomization = materials
      setallMaterials(materials)
    }
    
    applicationConfig.setFunctionRef("customizein3d",{setTextures:setallMaterials})
  },[configuration])

  useEffect(()=>{
    if(currPart && allMaterials?.length){
      STORE.currSelectedSwingPart = currPart
      let materialTypes = getObjectByParameter(partMaterialsMapping,"partName",currPart)?.materialTypes
      if(materialTypes){
        let result = allMaterials.filter(currMaterial => materialTypes.includes(currMaterial.material_type))
        setPartMaterials(result)

      }
    }
  },[currPart,allMaterials])



 

  function Categories() {
    return(
        <>
          {partsList.map((part:any,index:number)=>{
            return(
              <div data-part-name={part.partName}  onClick={()=>setCurrPart(part.partName)} className={part.partName===currPart?"marginleft5 marginTop5":"marginleft5 marginTop5 border"} key={index} style={{border:`${part.partName===currPart?"1px solid #000":"none"}`, padding:"0.7rem 1rem",borderRadius:"5px",whiteSpace:"nowrap"}}>
                  <div className="icon center-aligned-column pointer-none">
                    <img src={part.img} alt="" style={{width:"3rem",height:"3rem",objectFit:"contain"}}/>
                  </div>
                <div className='heading3 font-small marginTop5 color-black pointer-none text-center' style={{marginTop:"-0.3rem"}}>{part.partName}</div>
              </div>
            )
          })}
        </>
    )
  }

 
  return (
    <div className="products-left-sidebar full-height-width position-relative">
        <div id="partsListContainer" className="full-width space-around display-flex-row no-wrap">
          {/* <HorizontalScroll
            itemsClass="sub-categories-container"
            Component={Categories}   
            itemsList={partsList}
            id={`partsListContainer`}    
          /> */}

          {/* <Categories/> */}

          {partsList.map((part:any,index:number)=>{
            return(
              <div  data-part-name={part.partName}  onClick={()=>setCurrPart(part.partName)} className={part.partName===currPart?"marginleft5 marginTop5":"marginleft5 marginTop5 border"} key={index} style={{flex:1,border:`${part.partName===currPart?"1px solid #000":"none"}`, padding:"0.7rem 0.7rem",borderRadius:"5px",whiteSpace:"nowrap"}}>
                  <div className="icon center-aligned-column pointer-none">
                    <img src={part.img} alt="" style={{width:"3rem",height:"3rem",objectFit:"contain"}}/>
                  </div>
                <div className='heading3 font-small marginTop5 color-black pointer-none text-ellipsis text-center' style={{maxWidth:"4rem",marginTop:"-0.3rem"}}>{part.partName}</div>
              </div>
            )
          })}

        </div>
        <div className="padding5 full-width fill-available-height">
            {allMaterials.length?
              <Materials
               materials={partMaterials}
               action={updateTexture}
               moduleName="customizein3d"
             />
           :
             <div className="middle heading4">Materials Not Available</div>
           }
        </div>
    </div>
  );
};

export default SwingFinish;