import React, { useEffect, useState } from "react";
import { hideComponentLoader } from "../../../../../utils/UI_methods/global";
import { configuration, releaseMemory, setCustomizein3dConfiguration, sprites } from "../../../../../utils/customizein3d/modules/customizein3d";
import { applicationConfig } from "../../../../../utils/methods";
import { getPageTourElements } from "../../../../../utils/siteTour/elements";
import { STORE } from "../../../../../utils/store/storeConfiguration";
import Canvas from "../../../../common/modules/customizein3d/canvas/Canvas";
import CameraSettings from "../../../../common/modules/customizein3d/canvas/Render/CameraSettings";
import Actions from "../../../../common/modules/customizein3d/controls/Actions";
import StartCustomerSession from "../customer/StartCustomerSession";
import ElevateControls from "./ElevateControls";
import StoreControls from "./StoreControls";
import CanvasContainer from "./CanvasContainer";
import ChangeProduct from "./ChangeProduct";
import SpritesMaterials from "../../../../common/modules/customizein3d/controls/SpritesMaterials";
 

const CustomizeIn3D: React.FC<{
  product:any
  productConfiguration:any
  setIsOpenAdvanced:(val:boolean)=>void
  isElevateMode:any
  changeProduct:(val:any)=>void
    isProductChanged:boolean
}> = (props) => {
 
 
    const[modulesConfigurtion,setModulesConfiguration] = useState(configuration)
    const[isOpenStartSession,setIsOpenStartSession] = useState(false)

    const[tourElementsList] = useState(getPageTourElements("VariantCreator3D"))
  
  
    return (
        <div className="full-height-width customize-canvas-controls-wrapper" id="mainCustomizein3DWrapper" style={{flexDirection:"row"}}>
         {/* <SiteTour
          tourElementsList={tourElementsList}
          moduleName="productConfigurator"
        /> */}

        <ChangeProduct changeProduct={props.changeProduct}/>
        <StartCustomerSession isOpen={isOpenStartSession} setIsOpen={setIsOpenStartSession} createCustomerCallback={()=>{}}/>
         
          <div className="customize-canvas-wrapper position-relative center-aligned-row white-card" style={{zIndex:1}}>
              <CanvasContainer 
               isElevateMode={props.isElevateMode} 
               setIsOpenAdvanced={props.setIsOpenAdvanced} 
               product={props.product} 
               productConfiguration={props.productConfiguration}
               changeProduct={props.changeProduct}
               isProductChanged={props.isProductChanged}
               modulesConfigurtion={modulesConfigurtion}
               setModulesConfiguration={setModulesConfiguration}
              />
 
          </div>
          <div className="customize-controls-wrapper store-controls-wrapper  elevate-main-wrapper display-none full-height position-relative white-card" id="controlsWrapper" style={{zIndex:0}}>
            <SpritesMaterials spritesConfig={sprites}/>
            {props.isElevateMode && modulesConfigurtion?
            <ElevateControls
              modulesConfigurtion={modulesConfigurtion}
            />
            :
            <>
            {modulesConfigurtion?
              // <Controls
              //   product={props.product}
              //   modulesConfigurtion={modulesConfigurtion}
              //   productConfiguration={props.productConfiguration}
              //   setIsOpenAdvanced={props.setIsOpenAdvanced}
              //   setIsOpenSummary={props.setIsOpenSummary}
              //   nextHandle={props.nextHandle}
              //   prevHandle={props.prevHandle}
              //   currSelectedTab={props.currSelectedTab}
              //   isElevateMode={props.isElevateMode}
              // />
              <StoreControls
                product={props.product}
                modulesConfigurtion={modulesConfigurtion}
                productConfiguration={props.productConfiguration}
                isElevateMode={props.isElevateMode}
              />
            :null}
            </>
            
            }
            
          </div>
        </div>
    );
};

export default CustomizeIn3D;
