import React, { useEffect, useState } from "react";
import { getFormattedPrice, getRandomNumber } from "../../../../../utils/products/productviewer_shopify";
import ProductImage from "../../../../common/ui-components/elements/ProductImage";
import ModuleImage from "../../../../common/ui-components/elements/ModuleImage";
import ModulesFinishDetails from "./ModulesFinishDetails";
import SofaPartImage from "../variant-creator-3d/SofaPartImage";
import { STORE } from "../../../../../utils/store/storeConfiguration";
import { configuration } from "../../../../../utils/customizein3d/modules/customizein3d";
import { Module } from "../../../../../utils/customizein3d/Configuration";

 
const AddonsDetails: React.FC<{
    configuration:any
    product:any
}> = (props) => {


    const[productAddons,setProductAddons] = useState([])

    useEffect(()=>{
        if(props.configuration){
            let addonsList = [...props.configuration.addons]
            if(props.configuration.isLshapeWithSingleSeater){
                if(addonsList.length){
                    let singleSeaters = addonsList.filter(currAddon => (currAddon.moduleName !== "SingleSeater" || currAddon.moduleName !== "DoubleSeater" || currAddon.moduleName !== "ThreeSeater") && currAddon.productName === props.product.product_name)
                    if(singleSeaters.length){
                        addonsList = addonsList.filter(currAddon => currAddon.productName !== props.product.product_name)
                    }
                }
            }
            setProductAddons(addonsList)
            let isStandVisible = STORE.getCurrCategory() === "Swing" && configuration.modules[0]?.additionalConfiguration.hasStand 
            if(isStandVisible){
                setProductAddons([new Module({moduleName:"Stand",categoryName:"Swing"},"")])
            }
        }
    },[])

    
     
    function Addons() {
        return(
            <div className="marginTop10">
                <div className="marginTop10">
                <div className="Pricing"></div>
                <table className="table store-quotation-table summary-addons-table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Image</th>
                            <th>Name</th>
                            <th>Category</th>
                            <th>Configuration <SofaPartImage product={props.product}/> </th>
                            <th>Price</th>
                        </tr>
                    </thead>
                <tbody>
                    {productAddons?.map((addon:any,i:number)=>{
                        let product = {
                            product_name:addon.moduleName,
                            module_name:addon.moduleName,
                            module_type:addon.moduleType,
                            category_name:addon.categoryName,
                            sub_category_name:addon.subCategoryName
                        }
                        return(
                            <tr key={i}>
                                <td>{i+1}</td>
                                <td className="center-aligned-column image">
                                    {product.category_name === "Sofa"?
                                    <ModuleImage module={product}/>
                                    :
                                    <ProductImage product={product}/>
                                    }
                                </td>
                                <td className="heading2">{product?.product_name}</td>
                                <td className="heading2">{product?.category_name}</td>
                                <td> 
                                    <ModulesFinishDetails product={props.product} configurations={addon.configuration} />
                                </td>
                                <td>{getFormattedPrice(getRandomNumber(5000,15000))}</td>
                            </tr>
                        )
                    })}
                </tbody>
                </table>
            </div>
            </div>
        )
    }
     
 
  return (
    <Addons/>
  );
};

export default AddonsDetails;
