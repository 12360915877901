import React, { useEffect, useState } from "react";
import { applicationConfig } from "../../../../../../utils/methods";
import { STORE, SofaConfiguration, getImageFromModules } from "../../../../../../utils/store/storeConfiguration";
import Loader from "../../../../../common/shared-components/main-ui/Loader";
import { getWindowWidth, showGlobalToast } from "../../../../../../utils/UI_methods/global";

 

const CustomVariantMeasurements: React.FC<{
  productConfiguration:SofaConfiguration
  isReadOnly?:boolean
  product:any
  configuration3D?:any
}> = (props) => {

    const[dimensions] = useState(props.productConfiguration?.dimensions)
    const[imageUrl,setUrl]  = useState(``)
    const[isLayoutImageFound,setIsLayoutImageFound]  = useState(false)
    const[canDimensionsChange]  = useState(applicationConfig.clientName === "SiddhiSofas"?true:false)

    useEffect(()=>{
      setTimeout(() => {
        if(props.configuration3D && props.product){
          let productName = props.product.product_name
          let category = props.product.category_name
          let configId = props.configuration3D.configId
          let url = ""
          url = getImageFromModules(props.configuration3D,props.product)
          var img = new Image();
          img.onload = function() {
              setUrl(url)
              setIsLayoutImageFound(true)
          };
          img.onerror = function() {
              url=  `https://opusassets.s3.ap-south-1.amazonaws.com/public/store/summaryTopView/${applicationConfig?.clientName}/${category}/${productName}/${configId}.png`
              setUrl(url)
          };
          img.src = url;
        }
      }, 2000);
    },[props.product, props.configuration3D])






    function onChangeLength(event:any){
      props.productConfiguration.dimensions.length = Number(event.target.value)
      if(STORE?.productConfiguration?.dimensions){
        STORE.productConfiguration.dimensions.length = Number(event.target.value)
      }
      showGlobalToast("Updated")
    }

    function onChangeDepth(event:any){
      props.productConfiguration.dimensions.depth = Number(event.target.value)
      if(STORE?.productConfiguration?.dimensions){
        STORE.productConfiguration.dimensions.depth = Number(event.target.value)
      }
      showGlobalToast("Updated")
    }


    function AnnotationLines(){
      return(
        <>
        {isLayoutImageFound || true?
        <>
        <div className="top-middle heading3  full-width" style={{top:"-2rem",zIndex:2}}>
          <span className="middle" style={{background:"#000",padding:"1px",width:"100%"}}></span>
          <span className="middle heading3 color-black" style={{padding:"5px"}}>
            <div className="white-card no-shadow padding5 display-flex-row no-wrap">
              {props.isReadOnly || !canDimensionsChange?
              <span className="heading3 font-md-small">{dimensions.length}</span>
              :
              <input onBlur={onChangeLength} style={{maxWidth:"5rem"}} type="number" className="input marginleft5" defaultValue={dimensions?.length}/>
              }
              <span className="heading4 font-small center-aligned-column marginleft5">In</span>
            </div>
          </span>
        </div>

        <div className="right-middle heading3  full-height" style={{right:"-2rem"}}>
        <span className="middle" style={{background:"#000",padding:"1px",height:"100%"}}></span>
        <span className="middle heading3 color-black" style={{padding:"5px",width:"max-content"}}>
          <div className="white-card no-shadow padding5 display-flex-row no-wrap">
            {props.isReadOnly || !canDimensionsChange?
            <span className="heading3 font-md-small">{dimensions.depth}</span>
            :
            <input onBlur={onChangeDepth} style={{maxWidth:"5rem"}} type="number" className="input marginleft5" defaultValue={dimensions?.depth}/>
            }
            <span className="heading4 font-small center-aligned-column marginleft5">In</span>
          </div>  
        </span>
        </div>
        </>
      :null}
        </>

      )
    }

 
  return (
    <div className="center-aligned-column ion-padding full-width" style={{height:"25rem"}}>
      

      <div className="position-relative" style={{height:"83%"}}>

        <AnnotationLines/>

        {imageUrl?
        <>
        {getWindowWidth() <= 480 && isLayoutImageFound?
          <img className="module-image summary-modules-image full-height"
            alt="" 
            src={imageUrl}
            style={{transform:"scale(1)", objectFit:"contain"}}
          />
        :
          <img className="module-image summary-modules-image full-height"
            alt="" 
            src={imageUrl}
            style={{transform:"scale(1)", objectFit:"contain"}}
          />
        }
        </>
        :
          <Loader
            id=""
            className="--is-active fade-background-loader"
            loaderType="spinner"
            data={{}}
          />
        }
        
      </div>
                
      {/* ) */}
    {/* // })} */}
  </div>
  );
};

export default CustomVariantMeasurements;
