import { IonIcon, IonModal } from '@ionic/react';
import { close } from 'ionicons/icons';
import React, { useEffect, useState } from "react";
import {
  displayAllMoodboardImages, hideComponentLoader,
  showComponentLoader,
  updateDragImagePosition
} from "../../../../../../utils/UI_methods/global";
import { createBounceEffectEvents } from '../../../../../../utils/UI_methods/ui_effects';
import {
  applicationConfig,
  getArrayOfDistinctValuesFromObject,
  getFilteredArray,
  getSubCategoryDisplayName,
  logger,
  stringWithoutWhiteSpace
} from "../../../../../../utils/methods";
import { closeProductSearch, closeSubCategories, openProductSearch, toggleProductSearch } from '../../../../../../utils/moodboard/UI_methods';
import { closeCategories, hideProductDesc, openCategories, showProductDesc, toggleCategories } from "../../../../../../utils/moodboard/helper";
import Loader from '../../../../../common/shared-components/main-ui/Loader';
import SelectStoreFront from '../../../../../common/ui-components/alerts/SelectStoreFront';
import ProductImage from '../../../../../common/ui-components/elements/ProductImage';
import HorizontalScroll from '../../../../../common/ui-components/scrollbar/HorizontalScroll';
import ProductsSearch from "../../../../../common/ui-components/search/ProductsSearch";
import ProductDetails from '../../../../products/ProductDetails';
import ProductCategories from "./ProductCategories";
import { addItemToLocalStorage, getItemFromLocalStorage } from '../../../../../../utils/cache/localstorage';


const ProductImages: React.FC<{
  setProductModelSrc: (src: string) => void;
  configObj: any;
  currArea: string;
  action:(product:any)=>void
  dragStart:(event:any)=>void
  moduleName:string
  parentContainerId:string
}> = (props) => {
  const [allProductsList,setAllProductsList]: any = useState([]);
  const [productsList,setProductsList]: any = useState([]);
  const [prevProductsList,setPrevProductsList]: any = useState([]);
  const [currSelectedCategory, setCurrSelectedCategory] = useState<string>("");
  const [currSelectedSubCategory, setCurrSelectedSubCategory] = useState<string>("");
  const [subCategories, setSubCategories] = useState<any>([]);
  const [isSearchMode, setIsSearchMode] = useState<any>(false);

  const [isOpenProductDetails,setIsOpenProductDetails] = useState<any>(null);
  const [currProduct,setCurrProduct] = useState<any>(null);

  const [loadLimit, setLoadLimit] = useState(10);
  const [filteredProducts, setFilteredProducts] = useState<any>([]);


  const[productLoaderId] = useState("productLoader")

  useEffect(() => {
    // if(imagesLoaded){
    updateCategory();
    applicationConfig.setFunctionRef("moodboard",{updateCategory:filterProductWithCategory})
    applicationConfig.setFunctionRef("customizer",{updateCategory:filterProductWithCategory})
    applicationConfig.setFunctionRef("products",{filterProductsWithSubCategory:filterProductsWithSubCategory})
    applicationConfig.setFunctionRef("products",{resetProductImages:resetProductImages})
    // }
  }, [props.currArea,applicationConfig]);

 

  useEffect(()=>{
    showComponentLoader("mainLoaderSpinner")
    if(currSelectedCategory && currSelectedSubCategory){
      setLoadLimit(10)
      let filteredProductsList = allProductsList.filter(product=>stringWithoutWhiteSpace(product.category_name)===stringWithoutWhiteSpace(currSelectedCategory));
      if(currSelectedSubCategory?.toLowerCase() === "all"){
        setSubCategories(getArrayOfDistinctValuesFromObject(filteredProductsList,"sub_category_name").reverse())
        setProductsList(filteredProductsList)
        hideComponentLoader("mainLoaderSpinner")
        return
      }
      setSubCategories(getArrayOfDistinctValuesFromObject(filteredProductsList,"sub_category_name").reverse())
      filteredProductsList = allProductsList.filter(product=>product.category_name===currSelectedCategory&&product.sub_category_name===currSelectedSubCategory);
      setProductsList(filteredProductsList)
      closeCategories()

      //Add For recent activities
      // let recentActivities =  getItemFromLocalStorage("recentActivities")
      // if(!recentActivities){
      //   recentActivities = {}
      // }
      // recentActivities.currCategory = currSelectedCategory
      // recentActivities.currSubCategory = currSelectedSubCategory
      // addItemToLocalStorage("recentActivities",recentActivities)
      //end


      hideComponentLoader("mainLoaderSpinner")
      }else{
      setTimeout(() => {
        openCategories()
        hideComponentLoader("mainLoaderSpinner")
      }, 800);
    }
  },[currSelectedCategory,currSelectedSubCategory,allProductsList])

  useEffect(()=>{
    if(filteredProducts.length){
      // if(props.moduleName.toLowerCase() === "customizer"){
      //   addEventListenerOnMobileDragIcon(".image-drag-icon")
      // }else{
      //   addEventListenerOnImages(".image-drag-icon")
      // }
    }
  },[filteredProducts])

  useEffect(() => {
    // if (productsList?.length) {
    //   setFilteredProducts((prevProducts) => {
    //     return prevProducts.concat(
    //       productsList?.slice(loadLimit - 10, loadLimit)
    //     );
    //   });
    // }

  }, [productsList, loadLimit]);

  function resetProductImages() {
    setCurrSelectedCategory("")
    setCurrSelectedSubCategory("")
    openCategories()
    hideComponentLoader(null,"product-component-loader")
  }

  //If category is selected from tracker 
  function updateCategory(category:any = null) {
    let filteredArray = applicationConfig?.data.projectAreaCategories?.filter((object: any) =>
        object.project_id === applicationConfig.projectId &&
        object.area_id === applicationConfig.areaId
    );
    //For projects
    if(!category){
      if (filteredArray?.length) {
        category = filteredArray[0].category_name;
      }
      //Default project
      if (applicationConfig?.projectId === 0) {
        category = "Sofa";
      }
    }
   
    setCurrSelectedCategory(category);

   

    // let subCategories = getFilteredArray(applicationConfig?.data.subCategories,"category_name",category);
    // let subCategory = subCategories[0]?.sub_category_name;
    // setCurrSelectedSubCategory(subCategory);

    // filterProductsWithCategory(category);
  }


  function filterProducts(category: string,subCategory:string) {
    setCurrSelectedCategory(category);
    setCurrSelectedSubCategory(subCategory);

    logger?.info("editcollection","filterproducts",`Filter products: ${category}/${subCategory}` )
  }

  function filterProductsWithSubCategory(cate:string,subCate: string) {
    setCurrSelectedCategory(cate)
    if(!subCate){
      subCate = "all"
    }
    setCurrSelectedSubCategory(subCate);
  }


  function filterProductWithCategory(category:string) {
    setCurrSelectedCategory(category);
    let subCategories = getFilteredArray(applicationConfig?.data.subCategories,"category_name",category);
    let subCategory = subCategories[0]?.sub_category_name;
    setCurrSelectedSubCategory(subCategory);
    
    closeProductSearch()
    closeCategories(props.parentContainerId)
    closeSubCategories()

     //Add For recent activities
    //  let recentActivities =  getItemFromLocalStorage("recentActivities")
    //  if(!recentActivities){
    //    recentActivities = {}
    //  }
    //  recentActivities.currCategory = category
    //  recentActivities.currSubCategory = subCategory
    //  addItemToLocalStorage("recentActivities",recentActivities)
     //end
  }

  function activateSearchMode(products:Array<any>) {
    closeCategories(props.parentContainerId)
    displayAllMoodboardImages()
    setPrevProductsList([...productsList])
    // console.log([...productsList])
    setProductsList(products)
    setIsSearchMode(true)
    closeProductSearch()

    closeCategories(props.parentContainerId)
    closeSubCategories()
    
  }

  function deActivateSearchMode(){
    setProductsList([...prevProductsList])
    if(!prevProductsList.length){
      openCategories()
    }
    // setCurrSelectedSubCategory("all")
    setIsSearchMode(false)
  }


  function Search() {
    return(
      <ProductsSearch
        configObj={applicationConfig}
        currSelectedCategory={currSelectedCategory}
        activateSearchMode={activateSearchMode}
        filterProductsWithCategory={filterProductWithCategory}
        filterProductsWithSubCategory={filterProductsWithSubCategory}
        // idSuffix={"ProductsList"}
        idSuffix={props.parentContainerId+"result"}
      />
    )
  }

 
  function SearchModeHeader() {
    return(
      <div className="pointer center-aligned-row" 
      style={{padding:"1rem",marginBottom:"10px"}}
      onClick={deActivateSearchMode}
      >
          <span className="color-black text-center heading letter-spacing">
            <i className="fas fa-chevron-left color-primary marginRight5" aria-hidden="true"></i>
            Go Back
          </span>
      </div>
    )
  }

  function nextPage() {
    setLoadLimit((prevValue) => {
      if (prevValue >= productsList?.length) {
        return prevValue;
      }
      return prevValue + 10;
    });
  }


  function SubCategoriesList() {
    return(
        <>
          <div className={currSelectedSubCategory?.toLocaleLowerCase()==="all"?"marginleft10 marginTop5 bg-color-primary color-white":"marginleft10 marginTop5 border"} style={{padding:"5px 10px",borderRadius:"20px"}}>
              <div className='heading4 font-md-small text-center'  onClick={()=>setCurrSelectedSubCategory("all")}>{"All"}</div>
          </div>
          {subCategories.map((subCategory:any,index:number)=>{
            return(
              <div className={currSelectedSubCategory===subCategory?"marginleft10  marginTop5 bg-color-primary color-white":"marginleft10 marginTop5 border"} key={index} style={{padding:"5px 10px",borderRadius:"20px",whiteSpace:"nowrap"}}>
                <div className='heading4 font-md-small text-center' onClick={()=>setCurrSelectedSubCategory(subCategory)}>{getSubCategoryDisplayName(subCategory)}</div>
              </div>
            )
          })}
        </>
    )
  }

  function InfoIconClickHandle(product:any) {
    setCurrProduct(product)
    setIsOpenProductDetails(true)
  }

  


  return (
    <div className='full-height-width'>

      <ProductCategories
        configObj={applicationConfig}
        filterProducts={filterProducts}
        currSelectedCategory={currSelectedCategory}
        activateSearchMode={activateSearchMode}
        filterProductsWithCategory={filterProductWithCategory}
        moduleName={props.moduleName}
        setSubCategories={setSubCategories}
        parentContainerId={props.parentContainerId}
        filterProductsWithSubCategory={filterProductsWithSubCategory}
      />

      <Loader className="fade-background-loader"
            id={productLoaderId}
            data={{}}
            loaderType="progressCompact"
        />

        <IonModal
          isOpen={isOpenProductDetails} 
          cssClass={"small-y-modal"}
          onDidDismiss={setIsOpenProductDetails}
        >
        <ProductDetails
                configObj={props.configObj}
                currProduct={currProduct}
                setOpenProjectAction={setIsOpenProductDetails}
                viewProductin3DAction={()=>{}}
                gotoCustomizeIn3D={()=>{}}
            />
        </IonModal>

        




      <div style={{height:"4rem"}} className="moodboard-product-filters-container position-relative" >

        {
          !isSearchMode?
            <>

            <div className="product-material-dropdown-wrapper margin5 white-card no-shadow" style={{margin:"0.3rem"}}>
              <div className="pointer  position-relative display-flex-row space-between padding1rem full-width" >
                  <span className="color-black heading3" onClick={()=>{
                    toggleCategories(props.parentContainerId)
                    // expandSubCategories()
                  }}>
                    <i className="fas fa-chevron-left color-danger marginRight5 shadow-light white-card" aria-hidden="true" style={{padding:"7px 10px"}}></i>
                    {currSelectedCategory} / {getSubCategoryDisplayName(currSelectedSubCategory)}
                  </span>

                  <div className='display-flex-row no-wrap'>
                    <div className='white-card shadow-light border marginRight5'>
                        <SelectStoreFront
                            updateProductList={setAllProductsList}
                        />  
                    </div>

                    <div className='white-card shadow-light border' style={{padding:"5px 10px"}} onClick={()=>openProductSearch(props.parentContainerId,"ProductsList")}>
                      <i className="fas fa-search color-black font-md-small" aria-hidden="true"></i>
                    </div>
                  </div>

                 

                  <div className="full-width top-left padding5 moodboard-product-search-wrapper">
                      <Search/>
                      <IonIcon onClick={toggleProductSearch} icon={close} className="font-medium right-middle zindex30"/>
                  </div>

                

              </div>
            </div>
   

            
            </>
          :
          <SearchModeHeader/>
        }

      </div>

      <HorizontalScroll
        itemsClass="sub-categories-container"
        Component={SubCategoriesList}   
        itemsList={subCategories}
        id={`${props.parentContainerId}ScrollBar`}     
      />

      <div style={{height:"calc(100% - 8rem)",alignContent:"baseline"}}  className="overflow-y-scroll padding5 display-flex-row position-relative " onLoad={(e)=>createBounceEffectEvents(e)} >
        
           
   
        {productsList.length?productsList?.map((product: any, index: number) => {
          return (
            <React.Fragment key={index}>
            {Number(index) < loadLimit ? (
            <div key={index} className={`${props.moduleName === "customizer" && !product.is_model_available?"display-none":"position-relative image-medium padding5 category-details-wrapper "}`}
              data-product-name={product.product_name}
              data-product-category={product.category_name}
              data-product-category-id={product.category_id}
              data-product-subcategory={product.sub_category_name}
              data-product-price={product.price}
              data-product-id={product.product_id}
              data-image-type="product"
              onDragStart={(event)=>props.dragStart(event)}
              draggable={props.moduleName !== "customizein3d"?"true":"false"}
              style={{display:"block",height:"fit-content"}}
              onPointerUp={()=>props.action(product)}
              onDrag={updateDragImagePosition}
              >
              
              {props.moduleName === "customizer"?
                <div className='full-height-width product-hover-element padding5 top-left'>
                  <div className='full-height-width position-relative'>
                    <div className="top-right pointer padding5 center-aligned-column information-button"
                      style={{ fontSize: "0.8rem",width:"10px",height:"10px",right:"0.5rem",top:"0.5rem" }}
                      onMouseEnter={(event: any) =>
                        showProductDesc(event)
                      }
                      onMouseLeave={hideProductDesc}
                      onClick={()=>InfoIconClickHandle(product)}
                      >
                      <i className='fas fa-info font-ex-small color-white'></i>
                  </div>
                  </div>
                </div>
              :null}

              <div className='white-card shadow-medium image'>
                <ProductImage product={product}/>
              </div>
              
       
         

              <Loader
                  className={`product-component-loader fade-background-loader`}
                  id={product.product_name}
                  data={{ isAnimated: true }}
                  loaderType="dots"
                />

              {props.moduleName !== "customizein3d"?
              <div className="top-right padding5 zindex30 hide-in-desktop">
                <div className="image-drag-icon">
                  <i className="fas fa-grip-vertical color-dark font-large"></i>
                </div>
              </div>
              :null}
                

              <div className='product-details bottom-middle padding5'>
                <div className="heading2 color-black font-small text-center capitalize">{product.product_name}</div>
              </div>
            </div>
            ) : null}
            
          </React.Fragment>
          );
        }):null}

      {loadLimit < productsList?.length ? (
          <div className="center-aligned-row full-width ion-margin-bottom">
            <div className="display-flex-row pointer border bg-color-light padding5" onClick={nextPage}>
              <div className="font-small center-aligned-column bg-color-light products-toggle marginleft5  heading3 pointer-none"  style={{padding:"10 12px"}}>Load more</div>
            </div>
          </div>
      ) : null}


      </div>

    
      
   </div>
  );
};

export default ProductImages;
