import CameraControls from "camera-controls";
import * as THREE from 'three';
import { ACESFilmicToneMapping, OrthographicCamera, PerspectiveCamera, sRGBEncoding, Vector3, WebGLRenderer} from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { CSS2DRenderer } from "three/examples/jsm/renderers/CSS2DRenderer";
import { getWindowWidth } from "../UI_methods/global";
import { gl } from "../webxr/webxr";
import { MAX_DISTANCE, MIN_DISTANCE, customizerConfig } from "./area3dmodel/area3dModel";
import { removeBoxHelper } from "./area3dmodel/dragging";
import {NeutralToneMapping } from "three/build/three.module"

const CAMERA_INITIAL_FOV = 45
const CAMERA_INITIAL_DISTANCE = 10
const target = new Vector3();

const CAMERA_INITIAL_FOV_Y = 5 // to see the object from top

export function addCamera(data:any,type:string) {
    if(type.toLowerCase() === "perspective"){
      return new PerspectiveCamera(CAMERA_INITIAL_FOV, data.width / data.height,data.near,2000);
    }
    if(type.toLowerCase() === "orthographic"){
      return new OrthographicCamera( data.width / - (2), data.width / (2), data.height / (2), data.height / - (2), 1, 1000 );
    }
    return null
  }
  

  export function addOrthoCamera(orthoCamera:any,width:any,height:any,scale=1) {
    orthoCamera = new OrthographicCamera( width / - (2 ), width / (2 ), height / (2 ), height / - (2 ), 1, 1000 );
    return orthoCamera
  }
  
  export function updateCamera(TWEEN:any,configuration:any,camera:any,boundingBox:any,group:any,controls:any) {
    // if(IS_SPACE_PLANNER_MODE){
    //   return
    // }
    updateCameraPosition(TWEEN,boundingBox,group,controls,camera)
  }

  export function updateCameraPosition(TWEEN:any,boundingBox:any,group:any,controls:any,camera:any,cameraTarget:any=null) {

    boundingBox.setFromObject(group)
    // camera.position.y = 250

    if(!cameraTarget){
      cameraTarget = {x:camera.position.x,y:camera.position.x,z:camera.position.z} 
    }
    
    let tweenCamera = new TWEEN.Tween({x:camera.position.x,y:camera.position.y,z:camera.position.z})
    .to(cameraTarget,500)
        
    let tweenControls = new TWEEN.Tween({x:controls.target.x,y:controls.target.y,z:controls.target.z})
    .to({x:boundingBox.getCenter(target).x,y:boundingBox.getCenter(target).y,z:boundingBox.getCenter(target).z},500)

    tweenControls.onUpdate(function(object:{x:number,y:number,z:number},elapsed:number){
      controls.target.set(object.x,object.y,object.z)
      controls.update()
    })

    tweenCamera.onUpdate(function(object:{x:number,y:number,z:number},elapsed:number){
      camera.position.set(object.x,object.y,object.z);
      camera.updateProjectionMatrix();
    })
    tweenControls.start()
    tweenCamera.start()
    // controls.target.set(boundingBox.center().x,boundingBox.center().y,boundingBox.center().z)
  }
  
  
  export function updateFov(configuration:any,camera:any){
    if(camera.fov>=75){
      return
    }
    let fov = getFov(configuration)
    camera.fov = CAMERA_INITIAL_FOV + fov;
    camera.updateProjectionMatrix();
  }

  export function updateCameraDistance(configuration:any,camera:any){
    // if(camera.fov>=75){
    //   return
    // }
    camera.position.z = CAMERA_INITIAL_DISTANCE + (configuration.getModulesLength() * 1);
    camera.updateProjectionMatrix();
  }

  export function updateZoomOutLimit(configuration:any,controls:any){
    if(configuration.getModulesLength() > 5 && getWindowWidth() <= 480){
      controls.maxDistance = controls.maxDistance + 1 
    }
  }


  function getFov(configuration:any) {
    if(configuration.getModulesLength()<4){
      return 0
    }
    return (configuration.getModulesLength() - 4) * 3
  }

  export function addControls(controls:any,camera:any,renderer:any,attributes:any={minDistance:2,maxDistance:6}) {
    controls = new OrbitControls( camera, renderer.domElement );
    setInitialOrbitControls(controls)
    // set3dControls(controls)
    return controls
  }

  export function setInitialOrbitControls(controls:OrbitControls) {
    controls.rotateSpeed = 0.7;
    controls.zoomSpeed = 0.8;
    controls.panSpeed = 0.6;
    controls.minDistance = 2 
    controls.maxDistance = 24
    controls.minPolarAngle = 0;
    controls.maxPolarAngle = Math.PI / 2;
    controls.enablePan = true
    controls.enableRotate = true
    controls.enableDamping = true
    controls.dampingFactor = 0.2; 
    controls.mouseButtons = {
      LEFT: THREE.MOUSE.ROTATE,
      MIDDLE: THREE.MOUSE.DOLLY,
      RIGHT: THREE.MOUSE.PAN
    }
    controls.touches = {
      ONE : THREE.TOUCH.ROTATE,
      TWO : THREE.TOUCH.DOLLY_PAN
    }
  }

  export function addCameraControls(controls:any,camera:any,renderer:any,attributes:any={minDistance:2,maxDistance:6}) {
    CameraControls.install( { THREE: THREE } );
    controls = new CameraControls( camera, renderer.domElement );
    // controls.rotateSpeed = 0.5;
    // controls.zoomSpeed = 1.2;
    // controls.panSpeed = 0.6;
    set3dControls(controls)
    return controls
  }

  export function getControlsAttributes() {
    if(getWindowWidth() > 480){
      return {minDistance:2,maxDistance:12}
    }else{
      return {minDistance:2,maxDistance:35}
    }
  }

  export function set3dControls(controls:CameraControls) {
    controls.minPolarAngle = 0;
    controls.maxPolarAngle = Math.PI / 2;
    controls.dampingFactor = 0.1

    controls.minDistance = MIN_DISTANCE 
    controls.maxDistance = MAX_DISTANCE;

    controls.azimuthRotateSpeed = 1;
    controls.polarRotateSpeed   = 1;
    controls.mouseButtons.wheel = CameraControls.ACTION.DOLLY

    controls.mouseButtons.left = CameraControls.ACTION.ROTATE

    controls.setFocalOffset(0,1,0,false)
    enableRotation(controls)

    controls.camera.near = 1.01
    updateSceneCameraFov(controls.camera,45)

  }

  export function setSpacePlannerControls(controls:any,attributes:any={minDistance:1,maxDistance:15}) {
    if(controls?.camera){
      controls.mouseButtons.left = CameraControls.ACTION.TRUCK

      if(customizerConfig.currentRenderingType === "3d"  && customizerConfig.isFinalizeMode){
        controls.mouseButtons.wheel = CameraControls.ACTION.NONE
        controls.mouseButtons.left = CameraControls.ACTION.NONE
      }else{
        controls.mouseButtons.wheel = CameraControls.ACTION.ZOOM
      }

      controls.dampingFactor = 1
      disableRotation(controls)
    }else{
      controls.minDistance = attributes.minDistance 
      controls.maxDistance = attributes.maxDistance
      controls.enablePan = true
      controls.enableRotate = false
      controls.mouseButtons = {
        LEFT: THREE.MOUSE.PAN,
        MIDDLE: THREE.MOUSE.DOLLY,
        RIGHT: THREE.MOUSE.ROTATE
      }
      controls.touches = {
        ONE : THREE.TOUCH.PAN,
        TWO : THREE.TOUCH.DOLLY_ROTATE
      }
    }
  }

  export function updateCameraFocalOffset(controls:any,offset:Vector3) {
    controls.setFocalOffset(offset.x,offset.y,offset.z,true)
  }

 
  export function updateSceneCameraAspectRatio(cam:any,width:number,height:number,scale:number) {
    if(cam.isOrthographicCamera){
      cam.left = width / - (2) ;
      cam.right = width / (2 );
      cam.top = height / (2 );
      cam.bottom = - height / (2);
    }
    cam.aspect = (width) / (height);
    cam.updateProjectionMatrix();
  }

  export function disableRotation(controls:CameraControls,isDisableAllRotation:boolean = false) {
    if(isDisableAllRotation){
      controls.minAzimuthAngle = 0
      controls.maxAzimuthAngle = 0
    }
    controls.minPolarAngle = 0
    controls.maxPolarAngle = 0
  }


  export function enableRotation(controls:CameraControls) {
    controls.minAzimuthAngle = -Infinity
    controls.maxAzimuthAngle = Infinity
    controls.minPolarAngle = 0
    controls.maxPolarAngle = Math.PI / 2
  }

  export function enableSmoothControls(controls:any) {
    if(controls){
      controls.enableDamping = true 
      controls.dampingFactor = 0.2
      // controls.rotateSpeed = 1
      // controls.update()
    }
  }

  export function disableSmoothControls(controls:any) {
    if(controls){
      controls.enableDamping = false  
      controls.update()
    }
  }

  export function updateSceneCameraFov(camera:any,fov:number) {
    camera.fov = fov
    camera.updateProjectionMatrix()
  }
  export function updateSceneCameraNear(camera:any,near:number) {
    camera.near = near
    camera.updateProjectionMatrix()
  }

  export function createWebGlRenderer(renderer:any,width:any,height:any) {
    renderer =  new WebGLRenderer({
      alpha: true,
      antialias: true,
      preserveDrawingBuffer: true,
      context: gl // for webxr,
    })
    renderer.setSize( width, height )
    renderer.shadowMap.enabled = true
    renderer.shadowMapSoft = true;
    renderer.outputColorSpace = "srgb"
    renderer.physicallyCorrectLights = true
    renderer.toneMapping = NeutralToneMapping 
    renderer.toneMappingExposure = 1.5
    return renderer
  }




  export function createLabelRenderer(width:any,height:any) {
    const renderer = new CSS2DRenderer()
    renderer.setSize(width,height)
    renderer.domElement.style.position = 'absolute'
    renderer.domElement.style.top = '0px'  
    renderer.domElement.style.left = '0px'  
    renderer.domElement.style.pointerEvents = 'none';
    return renderer
  }



  export function toggleRendererTransparency(renderer:any,transparent:boolean) {
    if (transparent) {
        renderer.setClearColor(0x000000, 0); // Set transparent background
    } else {
        renderer.setClearColor(0x000000, 1); // Set opaque black background
    }
  }

  // export function setSpancePlannerControls(camera:any,controls:any,width:number,height:number,cameraPos:any) {
  //   tweenSpacePlannerControls(camera,controls,width,height,cameraPos)
  // }


  export function addDracoLoader(loader:GLTFLoader,dracoLoader:any) {
    dracoLoader = new DRACOLoader()
    // let path = "https://www.gstatic.com/draco/v1/decoders/"
    let path = "https://www.gstatic.com/draco/versioned/decoders/1.4.1/"
    // let path = "https://raw.githubusercontent.com/mrdoob/three.js/dev/examples/js/libs/draco/"
    dracoLoader.setDecoderPath(path) // use a full url path
    dracoLoader.setDecoderConfig({ type: 'js' })
    loader.setDRACOLoader(dracoLoader)
    dracoLoader.preload()
  }




export function renderCamera(camera) {
  camera.updateProjectionMatrix()
  camera.updateWorldMatrix()
}

export function copyCameraParameters(camera:any,updatedCamera:any) {
  camera.far = updatedCamera.far
  camera.fov = updatedCamera.fov
  camera.zoom = updatedCamera.zoom
  camera.near = updatedCamera.near
  renderCamera(camera)
}

export function initialCameraSettings(camera:any) {
  if(camera){
    camera.far = 20000
    camera.fov = 30
    camera.zoom = 1
    camera.near = 1
    renderCamera(camera)
  }
 
}


export function updateCameraZoom(camera:any,zoom:number) {
  camera.zoom = zoom
}


export function addTransformControls(control:any,orbit:any,dragControls:any,currentCamera:any,renderer:any,render:()=>void) {
  // control = new TransformControls( currentCamera, renderer.domElement );
  control.setMode( 'rotate' )
  control.showZ = false
  control.showX = false
  control.setSpace('world');
  control.setRotationSnap(0.785398)
  // control.setRotationSnap(1.5708)
  
  control.addEventListener( 'mouseDown', function(){
    orbit.enabled = false
    if(dragControls){
      dragControls.enabled = false
    }
    removeBoxHelper()
  } );

  control.addEventListener( 'mouseUp', function ( event ) {
    orbit.enabled = true
    if(dragControls){
      dragControls.enabled = true
    }
  } );
  return control
}
 