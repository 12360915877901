import React, { useState } from "react";
import { SofaConfiguration } from "../../../../../../utils/store/storeConfiguration";
import Loader from "../../../../../common/shared-components/main-ui/Loader";
import { getModuleDimensions } from "../../../../../../utils/customizein3d/modules/customizein3d";

 

const ModuleMeasurement: React.FC<{
  productConfiguration:SofaConfiguration
  product:any
  configuration3D?:any
}> = (props) => {

    const[imageUrl,setUrl]  = useState(``)
    const[modules,setModules]  = useState(props.configuration3D?.modules || [])
 


    function getDimension(module:any){
      return getModuleDimensions(module)
    }


    function AnnotationLines(props:any){
      let currModule = props.currModule
      return(
        <>
        <div className="top-middle heading3  full-width" style={{top:"-2rem",zIndex:2}}>
          <span className="middle" style={{background:"#000",padding:"1px",width:"100%"}}></span>
          <span className="middle heading3 color-black" style={{padding:"5px"}}>
            <div className="white-card no-shadow padding5 display-flex-row no-wrap">
              <span className="heading3 font-md-small">{getDimension(currModule)?.dimX}</span>
              <span className="heading4 font-small center-aligned-column marginleft5">In</span>
            </div>
          </span>
        </div>

        <div className="right-middle heading3  full-height" style={{right:"-2rem"}}>
        <span className="middle" style={{background:"#000",padding:"1px",height:"100%"}}></span>
        <span className="middle heading3 color-black" style={{padding:"5px",width:"max-content"}}>
          <div className="white-card no-shadow padding5 display-flex-row no-wrap">
            <span className="heading3 font-md-small">{getDimension(currModule).dimZ}</span>
            <span className="heading4 font-small center-aligned-column marginleft5">In</span>
          </div>  
        </span>
        </div>
        </>

      )
    }

 
  return (
    <div className="center-aligned-column ion-padding full-width" style={{height:"25rem"}}>
      

      <div className="position-relative center-aligned-column" style={{height:"83%"}}>

        <div className="center-aligned-row">
          {modules.map((currModule:any,index:number)=>{
            let url = ``
            return(
              <div key={index} className="position-relative" style={{marginRight:"4rem"}}>
                  <AnnotationLines currModule = {currModule}/>
                  <img className="summary-modules-image"
                    alt="" 
                    src={`https://opusassets.s3.ap-south-1.amazonaws.com/public/store/icons/Sofa/${currModule.moduleName}.svg`}
                    style={{transform:"scale(1)", objectFit:"contain",height:"10rem"}}
                  />
                  {/* <Loader
                    id=""
                    className="--is-active fade-background-loader"
                    loaderType="spinner"
                    data={{}}
                  /> */}

              </div>
            )
          })}
        </div>

        
      </div>
                
      {/* ) */}
    {/* // })} */}
  </div>
  );
};

export default ModuleMeasurement;
