// import * as THREE from "three"
import * as TWEEN from "@tweenjs/tween.js"
import $ from "jquery"
import {
  Box3, Color,
  Group, LoadingManager, MathUtils, Object3D,
  OrthographicCamera,
  PerspectiveCamera,
  PlaneGeometry, PMREMGenerator, Scene, TextureLoader
} from "three"
import Stats from "three/examples/jsm/libs/stats.module"
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader"
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { Vector3 } from "three/src/math/Vector3"
import { updateProduct } from "../../../services/api"
import { checkFromCache, untarAndSaveToCache } from "../../cache/cache"
import { addItemToLocalStorage } from "../../cache/localstorage"
import { Undo } from "../../customizer/CustomizerConfig"
import { Transform } from "../../customizer/ProjectConfiguration"
import { applicationConfig, getFilteredArray, getObjectByParameter, logger, stringWithoutNumbers, stringWithoutWhiteSpace, waitFor } from "../../methods"
import { startPageTour } from "../../siteTour/tour"
import { STORE } from "../../store/storeConfiguration"
import { clearProgressBarInterval, getCanvasContainerWidthheight, getWindowHeight, getWindowWidth, hideComponentLoader, setCanvasContainerAspectRatio, showComponentLoader, showToast, updateLoaderProgress, updateLoaderProgressWithInterval } from "../../UI_methods/global"
import { activateXR, getBlobUrlFromExporter } from "../../webxr/webxr"
import { areaModel, productLoader } from "../area3dmodel/area3dModel"
import { createCameraForRender } from "../area3dmodel/floorplan/createRender"
import { getOriginalPartName, saveConfiguration } from "../area3dmodel/helper"
import { addModuleToConfiguration, cleanupScene, customizein3dInitialLayout, detachModule, downloadTexturesTarFiles, DRAG_ICON_NAME, draggableGroup, fitRendererIntoElement, iPadPotraitModeLayout, isPotraitMode, loadAndApplyTexture, loadAndApplyTextureToMesh, setRendererDimensions, updateFraming, updateGroupCenter } from "../common"
import { Configuration } from "../Configuration"
import { addCamera, addControls, addDracoLoader, addOrthoCamera, createLabelRenderer, createWebGlRenderer, disableSmoothControls, enableSmoothControls, getControlsAttributes, setInitialOrbitControls, setSpacePlannerControls, updateCamera } from "../controls"
import { Dimensions } from "../Dimensions"
import { addGridHelper, addGround, addPointLightsToScene, assignTextureMap, GRID, loadGeneratedEnvironmentMap, updateFloorDimensions, updatePointLightsPosition } from "../enviornment"
import { Floorplan } from "../floorplanner/floorplan"
import { Sprites } from "../kanvas/sprites"
import { Composer } from "../postProcessing"
import { loadProductModelToScene, updateProductFinish } from "../productViewer/productViewer"
import { dropMaterial, resetCurrSelectedProduct, updateObjectDimensions } from "../raycasting"
import { tweenCameraControls } from "../tween"
import { getTextureInfo, updateActionPanelInformation, updateRoomLayoutInformation } from "../UImethods"
import { WebXr } from "../webxr"
import { updateAnnotationsPosition, updateDragIconPosition, updateFloorDimensionsPosition } from "./annotations"
import { createDragControls, disableDragging } from "./dragControls"
import { Dragging } from "./dragging"
import {
  addEventListeners, addImageToScene, disposeVariableHelperFile, getAddedProductFromScene, getAllUrls, getDefaultModel, getMaterialUpdated, getObjectByName, getObjectByObjectId, getObjectDimensionPosition, getParent, getSavedConfigList, hideObjects, removeObjectFromScene, resetScreenshots, shortListItem, showObjects, udpateConfigurationNameInHeader, updateModelPosInTestMode, updateModelPosition,
  updateOtherModelPositions, updatePositionOfAddon, updateScreenshot, updateTotalPrice
} from "./helper"
import { dropProduct } from "./imageDragging"
import { SofaLayout } from "./layout"
import { updateSofaLeg } from "./legs"
import { CURR_SELECTED_MESHES, CURR_SELECTED_MODULE, deleteObjectFromScene, deselectModule, duplicateObject, isMultipleSelectionMode, raycaster, removeBoxHelper, resetCurrSelectedMeshes, resetCurrSelectedModule, rotateObject } from "./raycasting"
import { SceneBackgound } from "./sceneBackground"
import { createShadow, renderShadow } from "./shadows"
import { enableConfigureMode, hideActionPanel, hideConfigOptionsWindow, hideFloorDimensions, hideModuleAnnotation, showConfigOptionsWindow, showFloorDimensions, showModuleAnnotation, updateActiveClassCotSelection, updateCurrSelectedMaterialValue } from "./UI_methods"
import { setFinishForUndo } from "./undo"
import { applyFinishInLoft, attachAllLoftForUpdateDimensions, attachLoftForUpdateDimensions, detachAllLoftForUpdateDimensions, detachLoftForUpdateDimensions, hideLoft, isLoftAttach, loadWardrobeModuleToScene, postWardrobeAddActions, showLoft } from "./wardrobe"
import { hideSwingStand } from "./swing"

const GROUND_GROUP_NAME = "groundGroup"
const GROUND_OUTLINE_NAME = "groundoutline"
const GROUND_NAME = "ground"
const DIMENSIONS_NAME = "dimensions"
export const SPACE_PLANNER_IMAGES_GROUP_NAME = "spacePlannerImagesGroup"
const MODULES_GROUP_NAME = "3dModules"
const SELECTED_OBJECT_OUTLINE_NAME = "selectedProduct"
const GROUND_DIMENSIONS_GROUP_NAME = "groundDimensions" 
export const GROUND_WIDTH = 4.6, GROUND_HEIGHT = 4.6

export const MODULE_NAME = "Customizein3D"

//variable to dispose materials from memory
export var ALL_MESHES:Array<any> = []
export var ALL_MATERIALS:Array<any> = []

//group = group for 3d models
export var addonsGroup:Group
var groundGroup:any,axis:any


export var boundingBox:any

export var orthoCamera:any = new OrthographicCamera(0,0,0,0)
export var perspectiveCamera:any = new PerspectiveCamera()
 

export var textureLoader:any
var groundGeometry:any = null
var animationFrameId = null

var manager = new LoadingManager()
export var modulesLoader = null
export var dracoLoader = new DRACOLoader()
export var fontLoader = new FontLoader()

export var configuration:any = null
export var configObj:any = null

export var element
export var scene
export var renderer
export var camera:any = new PerspectiveCamera()
export var controls,dragControlsImages
export var group:any  = new Group()
export var spacePlannerImagesGroup:any
var dragControls:any = null 


var isTestingMode = false

// Flags
var IS_DIMENSION_VISIBLE = false
export var IS_SPACE_PLANNER_MODE:boolean = false

//If single module is dragging dont drag the group
export var isModuleDragging = false

export var IS_CUSTOMIZATION_MODE = false
//Variables to check if the model is dragging or clicked
var pointsLight:any = null
var hemiSphereLight:any = null

export var composer:Composer
var stats = null

export var width = 0
export var height = 0
export var shadow:any = null
export var groupBoundingBox = new Box3()
export var outlinePass = null
var size = new Vector3();

export var maskForSpacePlannerImages = 2
export var canvasDimensions = null

export var currRotation = null

export var undo = null
export var redo = null


export var floorplanner:Floorplan = null
export var layouts:SofaLayout = new SofaLayout()

export var virtaulPlanesGroup = new Group()

export var dimensions:Dimensions = null
export var labelRenderer:any
export var webxr:WebXr
export var dragging:Dragging = null


export var sceneBackgrounds = new SceneBackgound()

export var legMeshes = []

export const sprites = new Sprites()
// export var topView:TopView = new TopView()

export function Init(configObject:any) {
  releaseMemory()
  // Flags
  element = document.getElementById("3dCustomizerWrapper")


  scene = null
  
  groundGeometry = new PlaneGeometry( GROUND_WIDTH, GROUND_HEIGHT )


  addonsGroup = new Group()
  //use to drag models group using drag icon

  // dimensionsGroup.name = DIMENSIONS_NAME
  group.name = MODULES_GROUP_NAME
  addonsGroup.name = MODULES_GROUP_NAME
  spacePlannerImagesGroup = new Group()
  groundGroup = new Group()
  groundGroup.name = GROUND_GROUP_NAME
  // groundGroup.position.set(-250,0,-250)
  spacePlannerImagesGroup.name=SPACE_PLANNER_IMAGES_GROUP_NAME
  boundingBox = new Box3()
  textureLoader = new TextureLoader()
  axis = new Vector3(0,1,0)
  // material = new MeshStandardMaterial()

  configObj = configObject
  modulesLoader = new GLTFLoader(manager)
  addDracoLoader(modulesLoader,dracoLoader)


  canvasDimensions = getCanvasContainerWidthheight("customizein3d")

  width = canvasDimensions.width
  height = canvasDimensions.height

  scene = new Scene()
  // scene.background = new Color(0xffffff)
  dragging = new Dragging(scene)


  
  renderer = createWebGlRenderer(renderer,width,height)
  labelRenderer = createLabelRenderer(width,height)
  element.appendChild(renderer.domElement)
  element.appendChild(labelRenderer.domElement)

  renderer.xr.enabled = true
  



  perspectiveCamera = addCamera({width:width,height:height,near:0.01},"perspective")

  camera = perspectiveCamera

  orthoCamera = addOrthoCamera(orthoCamera,width,height)
  orthoCamera.zoom = getWindowWidth() < 480 ? 45 : 120

  createCameraForRender(perspectiveCamera,width,height)

  controls = addControls(controls,camera,renderer,getControlsAttributes())
 
  enableSmoothControls(controls)
  createDragControls(orthoCamera,renderer)

  let PMREMGeneratorObject = new PMREMGenerator(renderer)
  PMREMGeneratorObject.compileCubemapShader()
  loadGeneratedEnvironmentMap(scene,renderer,PMREMGeneratorObject)
  stats = Stats();
  // element.appendChild(stats.dom);
  // $(stats.dom).css("top","10rem")

  addGround(scene,groundGeometry,groundGroup,GROUND_NAME,GROUND_OUTLINE_NAME,boundingBox,fontLoader,GROUND_DIMENSIONS_GROUP_NAME,{x:GROUND_WIDTH/2,y:0,z:GROUND_HEIGHT/2})
  
  addGridHelper(scene,{size:100*1,division:250,scale:1,position:new Vector3(0,0,0)})

  groundGroup.visible = false

  composer = new Composer(renderer,scene,camera)


  scene.add(spacePlannerImagesGroup)
  scene.add(draggableGroup)
  scene.add(group)
  scene.add(addonsGroup)

  spacePlannerImagesGroup.layers.set(maskForSpacePlannerImages)
  perspectiveCamera.layers.disable(maskForSpacePlannerImages)
  raycaster.layers.disable(maskForSpacePlannerImages)

  createShadow()
  setRendererDimensions(renderer)

  renderer.setAnimationLoop(render)

  resetScreenshots()
  
  addEventListeners(manager,renderer,element)
  // addDragIconToScene(scene,camera,renderer,controls,dragControls,group)

  undo = new Undo("360")
  redo = new Undo("360")

  floorplanner = new Floorplan(scene,group,renderer,orthoCamera,controls,element,raycaster,GROUND_WIDTH,GROUND_HEIGHT,"customizein3d")
  floorplanner.toggleVisibility(IS_SPACE_PLANNER_MODE)
  floorplanner.updateFloorplanShape("rect")
  scene.add(virtaulPlanesGroup)
  // floorplanner.group.add(virtaulPlanesGroup)

  dimensions = new Dimensions(scene,renderer,perspectiveCamera,orthoCamera,group,IS_SPACE_PLANNER_MODE,100)
  setTimeout(() => {
    setCanvasContainerAspectRatio(16,10,0,0,"customizeIn3D")
    fitRendererIntoElement(renderer,camera)
    fitRendererIntoElement(labelRenderer,camera)
    // if(getWindowWidth() > 480 && !isPotraitMode()){
      enableConfigureMode()
    // }
  }, 1000)
  iPadPotraitModeLayout("custmoizein3d")
  camera.near = 0.1


  configuration.setIsLshapeWithSingleSeater()

  scene.add(sprites.group)
  // scene.add(topView.group)
  // webxr = new WebXr(scene,renderer)
  // if(getUser()?.username === "DemoMaster"){
  //   webxr.init()
  // }
}


 

export function updateCanvasDimensions() {
  canvasDimensions = getCanvasContainerWidthheight("customizer")
}

export function resetModulesGroup() {
  dimensions.hideDimensions()
  group.children.forEach(modelObject=>{
    modelObject.parent.remove(modelObject)
  })
  scene.remove(group)
  group = new Group()
  group.name = MODULES_GROUP_NAME
  dimensions.setCurrSelectedObject(group)
  scene.add(group)
}


export function setCustomizein3dConfiguration(product:any,isViewProductMode:boolean) {
  configuration = new Configuration(product.product_id||product.productId)
  configuration.setProduct(product)
  let savedConfiguration = applicationConfig.sharedItem?.configuration
  if(savedConfiguration){
    if(savedConfiguration.isViewProductMode){
      loadProductFromSavedConfig(savedConfiguration)
    }else{
      let allModules = savedConfiguration.modules.concat(savedConfiguration.addons)
      if(allModules.length){
        loadModulesFromSavedConfiguration(savedConfiguration)
        configuration.updateConfigId(savedConfiguration.id || savedConfiguration.configId)
      }else{
        loadDefaultModuleToScene()
      }
    }
  }else{
    loadInitialModel()
  }
  fitRendererIntoElement(renderer,camera)
  updateProductPriority()
  return configuration
}

function updateProductPriority() {
  let product = STORE.currProduct
  let priority = Number(product.priority) + 1
  let productData = {
    productId:product.product_id,
    isUpdatePriority:true,
    priority:priority,
  }
  updateProduct(productData).then(res=>{
  }).catch(err=>{
    console.log(err)
  })
  addItemToLocalStorage("recentCustomizeProduct",product)
}


export function getSelectedObjectType(object:any) {
  let selectedObjectType = ""
  if(object?.type==="Object3D"){
    selectedObjectType = "3dmodel"
  }
   if(getParent(object)?.name===SPACE_PLANNER_IMAGES_GROUP_NAME){
    selectedObjectType = "image"
  }
  return selectedObjectType
}


export async function delete3dObject(object:any) {
 
    let modelIndex = configuration.getModuleIndexFromObject(object)
    if(modelIndex===0){
      showToast("Cannot remove first module",1000,"error")
      // return
    }

    deselectModule()

    const isWardrobeActions = STORE.getCurrCategory() === "Wardrobe"
    

    removeModuleFromConfiguration(configuration,object)
    removeObjectFromScene(group,object)
    // removeObjectFromModulesList(object)
    
    //If deleting the default model dont update anything 
    let noOfModules = configuration.getModulesLength()
    if(!noOfModules){
      hideActionPanel()
      showToast("Removed " + object.name,1000)
      resetCurrSelectedProduct()
      return
    }
    updateDragIconPosition(group,renderer,camera)

    let objectLoftVisiblityMapping = []
    if(isWardrobeActions){
      let modules = configuration.modules
      for (const module of modules) {
          let object = getObjectByObjectId(scene,module.moduleObjectId)
          if(object){
            objectLoftVisiblityMapping.push({
              object:object,
              isLoftVisible:object.userData.isLoftVisible
            })
            showLoft(object)
          }
      }
    }
    // await waitFor(3000)

    updateOtherModelPositions(group,configuration,boundingBox,axis,configuration.product.subCategoryName,false)
    // await waitFor(1000)
    if(isWardrobeActions){
      let modules = configuration.modules
      for (const module of modules) {
          let object = getObjectByObjectId(scene,module.moduleObjectId)
          if(object){
            hideLoft(object)
          }
      }

      for (const data of objectLoftVisiblityMapping) {
        if(data.isLoftVisible){
          showLoft(data.object)
        }
      }
    }

    // resetWardrobeParents()



    if(!IS_SPACE_PLANNER_MODE){
      updateFraming(group,groupBoundingBox,camera,controls)
    }
    hideActionPanel()
    showToast("Removed " + object.name,1000)
    resetCurrSelectedProduct()
}

export function resizeObject(dimensionName:string,action:string){
  dimensions.hideDimensions()
  const isWardrobeActions = STORE.getCurrCategory() === "Wardrobe" && !isLoftAttach(CURR_SELECTED_MODULE)
  if(isWardrobeActions){
    attachLoftForUpdateDimensions(CURR_SELECTED_MODULE)
  }
  updateObjectDimensions(dimensionName,action,scene,SELECTED_OBJECT_OUTLINE_NAME,boundingBox,configuration,axis)
  if(isWardrobeActions){
    detachLoftForUpdateDimensions(CURR_SELECTED_MODULE)
    updateActionPanelInformation(CURR_SELECTED_MODULE,boundingBox)
  }
  updateScaleInConfiguration(CURR_SELECTED_MODULE)
}

function updateScaleInConfiguration(object:any){
  if(object){
    let scale = object.scale
    let configuration = object.userData.configuration
    console.log(configuration)
    if(configuration){
      configuration.transform.scale.x = scale.x
      configuration.transform.scale.y = scale.y
      configuration.transform.scale.z = scale.z
    }
  }

}


export async function addSpacePlannerImages(productName:string) {
  let sprite = await addImageToScene(productName,configObj,GROUND_WIDTH,GROUND_HEIGHT,groundGroup,spacePlannerImagesGroup,textureLoader,dragControlsImages,controls,scene,camera,renderer,SELECTED_OBJECT_OUTLINE_NAME)
  if(sprite){
    undo.add("add",{addedModel:sprite})
  }
}

//Camera changes on space planner mode 
export function getIntersectsObjects(scene:any,element:any,x:number, y:number,mouseVector:any,raycaster:any,camera:any,moduleName:string) {
  
  y = y - element.getBoundingClientRect().top + window.scrollY
  x = x - element.getBoundingClientRect().left + window.scrollX

  x = (x / element.offsetWidth!) * 2 - 1
  y = -(y / element.offsetHeight!) * 2 + 1

  mouseVector?.set(x, y, 0.5)
  raycaster?.setFromCamera(mouseVector, camera)
  return raycaster?.intersectObject(scene, true)
}



export function toggleLayoutVisibility(isVisible:boolean){
  // let groundDimensions = getObjectByName(scene,GROUND_DIMENSIONS_GROUP_NAME)
  let groundOutline = getObjectByName(scene,GROUND_OUTLINE_NAME)
  // groundDimensions.visible = isVisible
  groundOutline.visible = isVisible
  if(isVisible){
    showFloorDimensions()
    $("#toggleLayoutButton").find(".icon").removeClass("fa-eye")
    $("#toggleLayoutButton").find(".icon").addClass("fa-eye-slash")
    // logger?.info("customizein3d","U-Lyt")
  }else{
    hideFloorDimensions()
    $("#toggleLayoutButton").find(".icon").removeClass("fa-eye-slash")
    $("#toggleLayoutButton").find(".icon").addClass("fa-eye")
    // logger?.info("customizein3d","H-Lyt" )
  }
}


export function loadModuleToTheScene(loader:any,modelSrc:string,isGetChildren:boolean = true) {
  return new Promise(async (resolve,reject)=>{
    await loader.load(modelSrc,
        function ( gltf:any ) {
          resolve(isGetChildren?gltf.scene.children[0]:gltf.scene)
        },
        function ( xhr:any ) {
          updateLoaderProgress("canvasLoader",xhr.loaded,xhr.total,"Loading Model")
        },
        function ( error:any ) {
            disableLoading()
            reject(error)
        }
    )
  })
}

export function addModule(configuration:Configuration,group:Group,module:any,isAddedAsAddon:boolean,postAddingActions:(object:any)=>void,isAddToConfig:boolean = true) {

  // disablelCustomizationMode()
  return new Promise(async (resolve,reject)=>{
    try {
      let moduleName = module.module_name || module.moduleName
      moduleName = moduleName.substring(0,moduleName.indexOf("-")) || moduleName
      let productName = module.product_name ||  module.productName
      let categoryName = module.category_name ||  module.categoryName
      let loader = modulesLoader || productLoader
      if(isAddedAsAddon){
        productName = module.product_name ||  module.productName
      }
      let key:string = `models/${productName}/${productName}_${moduleName}.glb`
      let url:any =  await checkFromCache(key)
      let object:any =  await loadModuleToTheScene(loader,url)
      adjustScaleUsingDimensinos(object,module)
      updateLoaderProgress("canvasLoader",1,1,"Downloading modules")
      clearProgressBarInterval()
      if(categoryName === "Sofa" || categoryName === "Wardrobe"){
        let isAdded = await postAddToSceneActionSofa(object,module,isAddedAsAddon,isAddToConfig)
        if(!isAdded){
          object.clear()
          showToast("Invalid Config",2000,"error")
          reject("Invalid Config")
        }
      }
      if(categoryName === "Cot" || categoryName === "Swing" || categoryName === "Chairs" ){
        postAddToSceneActionCot(object,module)
      }
      if(categoryName === "Sofa"){
        applyPrevModelTexture(group,configuration,object)
      }
      resolve(object)
    } catch (error) {
      console.log(error)
      reject(error)
    }
  })
}


const lshapeWithSingleSeaterModuleTypes = ["SingleSeater","DoubleSeater","ThreeSeater"]

export async function postAddToSceneActionSofa(object:any,module:any,isAddedAsAddon:boolean,isAddToConfig:boolean) {
  let productName = module.product_name ||  module.productName
  let moduleName = module.moduleName ||  module.module_name
  let moduleType = module.module_type || module.moduleType
  let subCategoryName = module.sub_category_name ||  module.subCategoryName

  let isAdded = false
  updateSofaLeg(configuration.currSofaLeg)
  // await waitFor(100)
  if(isAddedAsAddon || (lshapeWithSingleSeaterModuleTypes.includes(moduleType) && configuration.isLshapeWithSingleSeater)){
    // if(isAddedAsAddon || (moduleType.toLowerCase().includes("singleseater") && lshapeWithSingleSeater.includes(productName))){
    if(isTestingMode){
      isAdded = true
      updateModelPosInTestMode(object,configuration,boundingBox)
    }else{
      setAddonPosition(configuration,object,module)
      isAdded = await setModulePosition(group,object,module,configuration,subCategoryName)
    }
    group.attach(object)
    postAddingActions(object)
    addAddonToConfiguration(module,object,configuration)
    isAdded = true
    logger?.info("customizein3d","Add-A "+moduleName)
  }else{
    logger?.info("customizein3d","Add-M "+moduleName)
    if(isTestingMode){
      isAdded = true
      updateModelPosInTestMode(object,configuration,boundingBox)

    }else{
      isAdded = await setModulePosition(group,object,module,configuration,subCategoryName)
    }
    if(isAdded){
      group.attach(object)
      postAddingActions(object)
      if((moduleType !=="Left" && moduleType !=="Right" && isAddToConfig) || !configuration.modules.length ){
        addModuleToConfiguration(module,object,configuration)
      }
    }
  }
  return isAdded
}
 

function postAddToSceneActionCot(object:Object3D,module:any) {
  if(group.children.length){
    let currModule = group.children[0]
    currModule.visible = false
    object.position.copy(currModule.position)
    object.rotation.copy(currModule.rotation)
    group.attach(object)
    detachModule(currModule)
    currModule.clear()
    scene.remove(currModule)
  }else{
    object.position.set(0,0,0)
    group.attach(object)
  }
  configuration.reset()
  addModuleToConfiguration(module,object,configuration)
  postAddingActions(object)
  updateActiveClassCotSelection()
}



 

export function applyPrevModelTexture(group:Group,configuration:Configuration,object:any) {
  try {
    let length = group.children.length - 2
    if(length != -1){
      let lastModuleObject = group.children[length]
      if(lastModuleObject){
        copyModelFinish(configuration,lastModuleObject,object)
      }
    } 
  } catch (error) {
    
  }
  
}

function copyModelFinish(configuration:Configuration,sourceModel:any,destinationModel:any){
  destinationModel.children.forEach(mesh => {
    let lastModelMesh = sourceModel.children.find(currMesh=>stringWithoutNumbers(currMesh.name.toLowerCase()).includes(stringWithoutNumbers(mesh.name.toLowerCase())))
    if(lastModelMesh){
      let textureInfo = lastModelMesh.material?.userData.textureInfo
      if(textureInfo){
        let categoryName = configuration.product?.categoryName || sourceModel.parent?.userData.configuration?.categoryName || STORE.currProduct.category_name
        let productName = configuration.product?.productName || sourceModel.parent?.userData.configuration?.productName
        let partName = getOriginalPartName(categoryName,mesh.name)
        if(partName && textureInfo && textureInfo?.companyName!== "Default"){
          loadAndApplyTextureToMesh(destinationModel,textureInfo,mesh,productName,MODULE_NAME,categoryName,configuration) 
        }
      }
    } 
  });
}


//Loading type could be initial or append 
export async function addModuleFromSavedConfig(module:any,transform:Transform) {
  disablelCustomizationMode()
  try {
    let moduleName = module.module_name || module.moduleName
    moduleName = moduleName.substring(0,moduleName.indexOf("-")) || moduleName
    let key:string = `models/${module.productName}/${module.productName}_${moduleName}.glb`
    let url:any =  await checkFromCache(key)
    let object:any = await loadModuleToTheScene(modulesLoader,url)
    object.visible = false
    group.attach(object)
    object.userData.configuration = module
    object.position.copy(transform.position)
    object.rotation.copy(transform.rotation)               
    object.scale.copy(transform.scale)               
    module.transform.position = object.position
    module.transform.rotation = object.rotation
    module.transform.scale = object.scale
    return object
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function addProductAddonFromSavedConfig(module:any,transform:Transform) {
  disablelCustomizationMode()
  try {
    let moduleName = module.module_name || module.moduleName
    moduleName = moduleName.substring(0,moduleName.indexOf("-")) || moduleName
    let productName = module.product_name || moduleName
    let categoryName = module.category_name || module.categoryName
    let subCategoryName = module.sub_category_name || module.subCategoryName

    let clientName = applicationConfig?.clientName
    let product = getObjectByParameter(applicationConfig.data?.allProductsList,"product_name",productName)
    if(product){
      clientName = product?.storefront_name === "OVL" ? "OVL" : applicationConfig?.clientName
    }
  
    let tarfilekey = applicationConfig.awsConfig.getTarFileKey("productModels",{clientName:clientName,productName:stringWithoutWhiteSpace(productName),categoryName:stringWithoutWhiteSpace(categoryName),subCategoryName:stringWithoutWhiteSpace(subCategoryName)})
    let urlPrifix = `productModels/${clientName}/${categoryName}/${subCategoryName}`
    await untarAndSaveToCache(applicationConfig.awsConfig,urlPrifix,stringWithoutWhiteSpace(tarfilekey))
    let key:string = `productModels/${clientName}/${categoryName}/${subCategoryName}/${stringWithoutWhiteSpace(productName)}.glb`
    let url:any =  await checkFromCache(key)
    let object:any =  await loadModuleToTheScene(modulesLoader,url)
    object.visible = false
    group.attach(object)
    object.userData.configuration = module
    object.position.copy(transform.position)
    object.rotation.copy(transform.rotation)               
    object.scale.copy(transform.scale)               
    module.transform.position = object.position
    module.transform.rotation = object.rotation
    module.transform.scale = object.scale
    return object
  } catch (error) {
    console.log(error)
    return null
  }
}

export function activateXRMode() {
  activateXR(scene,camera,renderer)
}

export function createLayout(lengthFeet:string,lengthInch:string,breadthFeet:string,breadthInch:string){
    toggleLayoutVisibility(true)
    let l = (Number(lengthFeet) * 12) + Number(lengthInch)
    let b = (Number(breadthFeet) * 12) + Number(breadthInch)
    l = l / 100
    b = b / 100
    updateFloorDimensions(scene,boundingBox,fontLoader,GROUND_DIMENSIONS_GROUP_NAME,GROUND_GROUP_NAME,GROUND_WIDTH,GROUND_HEIGHT,l,b)
    $("#updateLayoutButton").removeClass("pulse-anchor")
  }


export async function  addAddonToConfiguration(module:any,object:Object3D,configuration:Configuration) {
  let moduleInstance = configuration.addAddon(module,object)
  moduleInstance.moduleObjectId = object.uuid
  object.userData.configuration = moduleInstance
}

export async function removeModuleFromConfiguration(configuration:any,object:object) {
  configuration.removeModule(object)
  updateTotalPrice(configuration,configObj)
}

export function removeAllSprites() {
  let object = getObjectByName(scene,SPACE_PLANNER_IMAGES_GROUP_NAME)
  object.clear()
}

class Module{
  allow_delete
  allow_duplication:any
  allow_movement:any
  allow_rotation:any
  category_name:any
  dim_X:any
  dim_Y:any
  dim_Z:any
  display_name:any
  is_addon:any
  module_description:any
  module_id:any
  module_name:any
  module_type:any
  module_type_id:any
  product_id:any
  product_name:any
  status:any
  sub_module_type:any
  sub_category_name:any

  constructor(module:any){
    this.allow_delete = module.allow_delete || module.allowDelete
    this.allow_duplication = module.allow_duplication || module.allowDuplication
    this.allow_movement = module.allow_movement || module.allowMovement
    this.allow_rotation = module.allow_rotation || module.allowRotation
    this.category_name = module.category_name || module.categoryName
    this.dim_X = module.dim_X || module.dimX
    this.dim_Y = module.dim_Y || module.dimY
    this.dim_Z = module.dim_Z || module.dimZ
    this.display_name = module.display_name || module.displayName
    this.is_addon = module.is_addon || module.isAddon
    this.module_description = module.module_description || module.moduleDescription
    this.module_id = module.module_id || module.moduleId
    this.module_name = module.module_name || module.moduleName
    this.module_type = module.module_type || module.moduleType
    this.module_type_id = module.module_type_id || module.moduleTypeId
    this.product_id = module.product_id || module.productId
    this.product_name = module.product_name || module.productName
    this.status = module.status || module.status
    this.sub_module_type = module.sub_category_name || module.subModuleType
    this.sub_category_name = module.sub_category_name || module.subCategoryName
  }
}

export async function appendModel(module:any,isAddedAsAddon:boolean) {

  module = new Module(module)

  deselectModule()
  showComponentLoader("changeFinishLoader")
  // let subCategoryName = module.sub_category_name ||  module.subCategoryName
  
  // if(module.is_addon === 1 && subCategoryName === "SetSofas"){
  //   isAddedAsAddon = true
  // }

  if(STORE.getCurrCategory() === "Wardrobe"){
    await downloadWardrobeModelsTar(module)
  }

  if(isAddedAsAddon){
    await downloadAddons(module)
  }
  await addModule(configuration,group,module,isAddedAsAddon,postAddingActions).then(object=>{
    postAddModuleAction(module,object)
  }).catch(err=>{
    disableLoading()
  })
}

async function downloadWardrobeModelsTar(module:any){
  let product = STORE.currProduct
  let clientName = product?.storefront_name === "OVL" ? "OVL" : applicationConfig?.clientName


  let key = applicationConfig.awsConfig.getTarFileKey("models",{clientName:clientName,productName:module?.product_name})
  await untarAndSaveToCache(applicationConfig.awsConfig,`models/${module?.product_name}`,key)?.then(async (data)=>{
  }).catch(async (err)=>{
  })
}


export async function appendProductAddon(module:any,product:any) {
  // showComponentLoader("changeFinishLoader")
  let productName = product.product_name || product.productName
  let categoryName = product.category_name || product.categoryName
  let subCategoryName = product.sub_category_name || product.subCategoryName

  let clientName = applicationConfig?.clientName
  if(product){
    clientName = product?.storefront_name === "OVL" ? "OVL" : applicationConfig?.clientName
  }

  
  let tarfilekey = applicationConfig.awsConfig.getTarFileKey("productModels",{clientName:clientName,productName:stringWithoutWhiteSpace(productName),categoryName:stringWithoutWhiteSpace(categoryName),subCategoryName:stringWithoutWhiteSpace(subCategoryName)})
  let urlPrifix = `productModels/${clientName}/${categoryName}/${subCategoryName}`


  await untarAndSaveToCache(applicationConfig.awsConfig,urlPrifix,stringWithoutWhiteSpace(tarfilekey))
  let key:string = `productModels/${clientName}/${categoryName}/${subCategoryName}/${stringWithoutWhiteSpace(productName)}.glb`
  let loader = modulesLoader || productLoader

  let url:any =  await checkFromCache(key)
  let object:any =  await loadModuleToTheScene(loader,url)
  updateLoaderProgress("canvasLoader",1,1,"Downloading modules")
  clearProgressBarInterval()

  let isAdded = await postAddToSceneActionSofa(object,module,true,true)
  if(!isAdded){
    object.clear()
    showToast("Invalid Config",2000,"error")
    return
  }
  postAddModuleAction(module,object)
  return object
}

export async function postAddModuleAction(module:any,object:any) {
  let moduleName =  module.moduleName || module.module_name
  let currModuleType = stringWithoutWhiteSpace(module.module_type || module.moduleType || "") 
    if(currModuleType !== "Left"){
      undo.add("add",{addedModel:object})
    }
    configuration.setIsConfigChanged(true)
    if(getWindowWidth() > 480){
      showToast("Added: "+ moduleName ,1000)
    }
    updateGroupCenter(group)
    udpateConfigurationNameInHeader()
    updatePointLightsPosition(group)
    if(dimensions.isDimensionsVisible){
      dimensions.hideDimensions()
      setTimeout(() => {
        dimensions.showDimensions()
      }, 500);
    }

    
    disableLoading()  
}

async function adjustScaleUsingDimensinos(object:any,module:any) {


  if(applicationConfig.clientName === "DashSquare" && module?.product_name !== "KF065"){
    // dimensions.setCurrSelectedObject(object)
    // dimensions.createDimensions(object)
    let data = getObjectDimensionPosition(null,object)
    // let dimX = annotations.find(currAnnotation => currAnnotation.axis === "x")?.dimensionInCm
    // let dimZ = annotations.find(currAnnotation => currAnnotation.axis === "z")?.dimensionInCm

    let dimX = data.dimensions.dimX * 100
    let dimZ = data.dimensions.dimZ * 100

    // let copiedModule = {...module}
    let originalDimX = module?.dim_X
    let originalDimZ = module?.dim_Y


    if(dimX && dimZ && originalDimX && originalDimZ){
      let scaleX = (originalDimX / dimX) * object.scale.x
      let scaleZ = (originalDimZ / dimZ) * object.scale.z
      let scaleY = object.scale.y

      object.scale.set(scaleX,scaleY,scaleZ)
    }
  }
  return
}

async function downloadAddons(module:any) {
  let productName = module.product_name ||  module.productName
  let moduleType = module.module_type ||  module.moduleType
  let categoryName = module.categoryName ||  module.category_name
  let subCategoryName = module.subCategoryName ||  module.sub_category_name

  let clientName = applicationConfig?.clientName
  let product = getObjectByParameter(applicationConfig.data?.allProductsList,"product_name",productName)
  if(product){
    clientName = product?.storefront_name === "OVL" ? "OVL" : applicationConfig?.clientName
  }


  let key = ""
  let urlPrefix = ""
  if(moduleType === "Product"){
    key = applicationConfig.awsConfig.getTarFileKey("productModels",{clientName:clientName,productName:stringWithoutWhiteSpace(productName),categoryName:stringWithoutWhiteSpace(categoryName),subCategoryName:stringWithoutWhiteSpace(subCategoryName)})
    urlPrefix = `productModels/${clientName}/${categoryName}/${subCategoryName}`
  }else{
    key = applicationConfig.awsConfig.getTarFileKey("models",{clientName:clientName,productName:productName})
    urlPrefix = `models/${productName}`
  }
  try {
    await untarAndSaveToCache(applicationConfig.awsConfig,urlPrefix,key)
    return "completed"
  } catch (error) {
    return "completed"
  } 
}

export function drop(event:any) {
  let texture={
    companyName:event.dataTransfer.getData("companyName"),
    collectionName:event.dataTransfer.getData("collectionName"),
    materialCode: event.dataTransfer.getData("materialCode"),
    materialType: event.dataTransfer.getData("materialType")
  }
  if(texture.materialCode){
    dropMaterial(event.clientX,event.clientY,texture,configuration,element,camera,scene,"customizein3d",configuration.product.productName,MODULE_NAME)
    composer.outlinePass.selectedObjects = []
  }
  dropProduct(event)
}


export async function loadInitialModel() {
  showComponentLoader("canvasLoader")
  logger?.info("customizein3d","START -" + configuration?.product?.productName || "")
  if(configuration.isViewProductMode){
    loadProductModelToScene(configuration.product,group)
    return
  }
  if(configuration.getLocalStorageConfiguration() && false){
    disableLoading()
    showConfigOptionsWindow()
    return
  }
  loadDefaultModuleToScene()
}



export function loadModulsesFromLocalStorage() {
  showComponentLoader("canvasLoader")
  hideConfigOptionsWindow()
  loadModulesFromSavedConfiguration(configuration.getLocalStorageConfiguration())
}

export function loadModulsesFromDefaultConfiguration() {
  showComponentLoader("canvasLoader")
  let savedConfiguration = getObjectByParameter(applicationConfig?.data?.savedProductConfiguration,"product_id",configuration.product.productId)
  if(savedConfiguration){
    hideConfigOptionsWindow()
    loadModulesFromSavedConfiguration(JSON.parse(savedConfiguration.configuration))
  }else
  alert("No config available")
}

export async function loadProductFromSavedConfig(savedConfiguration:any) {
  configuration.createConfigFromSavedConfig(savedConfiguration)
  let texturesInfoList  = []
  for (const partName in configuration.product.configuration) {
    let texture = configuration.product.configuration[partName]
    if(texture.companyName !== "Default"){
      texturesInfoList.push(texture)
    }
  }
  try {
    await downloadTexturesTarFiles(null,texturesInfoList)
  } catch (error) {
  }
  loadProductModelToScene(configuration.product,group,false,false)
}

export async function loadModulesFromSavedConfiguration(savedConfiguration:any) {
  logger?.info("customizein3d","Load -" + configuration?.product?.productName || "")
  deselectModule()
  configuration.createConfigFromSavedConfig(savedConfiguration)
  let allModules = configuration.modules.concat(configuration.addons)

  updateLoaderProgress("canvasLoader",0,1,"Downloading modules...")

  let promises = []
  if(configuration.addons?.length){
    for (const module of configuration.addons) {
      promises.push(await downloadAddons(module)) 
    }
  }
  await Promise.all(promises)

  let product = STORE.currProduct
  let clientName = product?.storefront_name === "OVL" ? "OVL" : applicationConfig?.clientName


  let key = applicationConfig.awsConfig.getTarFileKey("models",{clientName:clientName,productName:configuration.product.productName})
  await untarAndSaveToCache(applicationConfig.awsConfig,`models/${configuration.product.productName}`,key)?.then(async (data)=>{
  }).catch(async (err)=>{
    console.log(err)
  })

  let texturesInfoList  = []
  for (const currModule of allModules) {
    let configuration = currModule.configuration
     if(configuration.companyName !== "Default"){
      texturesInfoList.push(...configuration)
    }
  }
  try {
    updateLoaderProgress("canvasLoader",0,1,"Downloading textures...")
    await downloadTexturesTarFiles(null,texturesInfoList)
  } catch (error) {
  }

  updateLoaderProgress("canvasLoader",0,1,"Please wait...")
  await addModulesFromSavedConfig(allModules)
  updateGroupCenter(group)
  await waitFor(1000)
  updateGroupPositionToTopLeft()
  updateFraming(group,groupBoundingBox,camera,controls)
  disableLoading()

  if(STORE.isPresentingWebXr){
    showComponentLoader("mainLoaderSpinner")
    await waitFor(500)
    await getBlobUrlFromExporter().then((url:string)=>{
      STORE.modelViewer.updateSrc(url)
      hideComponentLoader("mainLoaderSpinner")
    }).catch(err=>{
      hideComponentLoader("mainLoaderSpinner")
    }) 
  }
}

function updateGroupPositionToTopLeft(){
  let dimensions = getObjectDimensionPosition(null,group).dimensions
  let groundData = getObjectDimensionPosition(null,groundGroup)
  let groundPos = groundData.positions.min
  let delta = 0.05
  let position = new Vector3(groundPos.x + dimensions.dimX / 2 + delta, group.position.y, groundPos.y + dimensions.dimZ / 2 + delta)
  group.position.copy(position)
}

export function addModulesFromSavedConfig(modulesList:Array<any>) {
  if(!modulesList.length){
    showToast("No saved modules found",3000)
    return "completed"
  }
  return new Promise(async (resolve,reject)=>{
    for (let i = 0; i < modulesList.length; i++) {
      const currModule = modulesList[i];
      let moduleType = currModule.moduleType || currModule.module_type
      let object = null
      if(moduleType === "Product"){
        object = await addProductAddonFromSavedConfig(currModule,currModule.transform)
      }else{
        object = await addModuleFromSavedConfig(currModule,currModule.transform)
      }

      if(object){
        currModule.moduleObjectId = object.uuid
        postAddingActions(object)
        await applySavedFinish(currModule,object)
      }
      if(i === modulesList.length-1){
        resolve("Done")
      }
    }
  })
}

async function applySavedFinish(moduleInfo:any,object:any) {
  try {
    let configurations = moduleInfo.configuration
    object.traverse(async mesh => {
      if(mesh.isMesh){
        let partName = mesh.name
        let currPartInfo = configurations.find(currConfig => currConfig.partName.includes(partName) || partName.includes(currConfig.partName))
        if(currPartInfo){
          let material = mesh.material
          await getMaterialUpdated(textureLoader,currPartInfo,material).then(result=>{
            material.userData.textureInfo = currPartInfo
          })
        }
      }
    })
  } catch (error) {
    
  }
}

export function resetConfiguration() {
  group.children.forEach(modelObject=>{
    scene.remove(modelObject)
  })
  addonsGroup.children.forEach(modelObject=>{
    modelObject.parent.remove(modelObject)
  })
  addonsGroup.clear()
  draggableGroup.clear()
  resetModulesGroup()
  // scene.remove(addonsGroup)
  // addonsGroup = new Group()
  // group.name = MODULES_GROUP_NAME
  // addonsGroup.name = MODULES_GROUP_NAME
  // scene.add(group)
  // scene.add(addonsGroup)
  configuration.reset()
}

export function addAddonsFromSavedConfig(addonsList:Array<any>) {
  if(!addonsList.length){
    return "completed"
  }
  return new Promise(async (resolve,reject)=>{
    for (let i = 0; i < addonsList.length; i++) {
      const currModule = addonsList[i];
      await addModuleFromSavedConfig(currModule,currModule.transform).then((object:any)=>{
        currModule.moduleObjectId = object.uuid
        postAddingActions(object)
        if(i === addonsList.length-1){
          resolve("Done")
        }
      }).catch(err=>{
        if(i === addonsList.length-1){
          reject("Done")
        }
      })
    }
  })
}

export async function loadDefaultModuleToScene(subCategory:string = configuration.product.subCategoryName) {
  try {
    let product = STORE.currProduct
    hideConfigOptionsWindow()
    showComponentLoader("canvasLoader")

    if(product.category_name === "Wardrobe" && false){
      loadWardrobeModuleToScene()
      return
    }
  
    let productModules = getFilteredArray(applicationConfig?.data?.productModules,"product_id",configuration.product.productId)
    if(!productModules.length){
      disableLoading()
      showToast("Modules not found",2000,"error")
      return
    }

    let clientName = product?.storefront_name === "OVL" ? "OVL" : applicationConfig?.clientName

    let module = getDefaultModel(stringWithoutWhiteSpace(subCategory),productModules)
    module = new Module(module)
    let key = applicationConfig.awsConfig.getTarFileKey("models",{clientName:clientName,productName:module.product_name})
    updateLoaderProgress("canvasLoader",0,1,"Downloading...")
    await untarAndSaveToCache(applicationConfig.awsConfig,`models/${module.product_name}`,key)
    updateLoaderProgressWithInterval("canvasLoader","Loading model",70,50)
    await addModule(configuration,group,module,false,postAddingActions)
    disableLoading()
    addPointLightsToScene(scene,group,17)
    addPointLightsToScene(scene,floorplanner.group,10)
    GRID.position.setY(group.position.y - 0.01)
    updateGroupCenter(group)
    saveInitialConfig()
    startPageTour() 
  } catch (error) {
    disableLoading()
  }
}

export function saveInitialConfig(){
  let savedConfigs = getSavedConfigList(configuration.product.productId)
  if(!savedConfigs.length){
    saveConfiguration(false,configuration).then((response:any)=>{
      configuration.updateConfigId(response.data.data.insertId)
    }).catch(err=>{
      console.log(err)
    })
  }else{
    let defaultConfig = getObjectByParameter(savedConfigs,"title","Default")
    if(defaultConfig){
      configuration.updateConfigId(defaultConfig.id)
    }
  }
}


export function disableLoading(){
  hideComponentLoader("canvasLoader")
  hideComponentLoader("changeFinishLoader")
}

export function enableLoading(){
  showComponentLoader("canvasLoader")
}

export async function updateTexture(event:any){
  // if(!sprites.enable){
    showComponentLoader("changeFinishLoader")
  // }
  let textureInfo = getTextureInfo(event)
  let urls = getAllUrls(textureInfo)
  downloadTexturesTarFiles([urls.textureUrl],[textureInfo]).then(data=>{
    updateModuleFinish(textureInfo)
  })
  // webxr presenting
  if(STORE.isPresentingWebXr){
    STORE.modelViewer.updateFinish(event)
  }
  updateCurrSelectedMaterialValue(textureInfo.materialCode)
}

export function enableCustomizationMode() {
  IS_CUSTOMIZATION_MODE = true
  updateAnnotationsPosition()
  showModuleAnnotation()
  $(".module-name-heading").text(CURR_SELECTED_MODULE?.userData.configuration?.displayName || CURR_SELECTED_MODULE?.userData.configuration?.productName)
}

export function disablelCustomizationMode() {
  IS_CUSTOMIZATION_MODE = false
  removeBoxHelper()
  hideModuleAnnotation()
  resetCurrSelectedModule()
  // composer.outlinePass.selectedObjects = []
  resetCurrSelectedMeshes()
}



export async function updateModuleFinish(textureInfo:any) {
  if(CURR_SELECTED_MESHES.length){
    const isWardrobeActions = STORE.getCurrCategory() === "Wardrobe" && !isLoftAttach(CURR_SELECTED_MODULE)
    for (let i = 0; i < CURR_SELECTED_MESHES.length; i++) {
      const mesh = CURR_SELECTED_MESHES[i];
      let object = CURR_SELECTED_MODULE
      let categoryName = object?.userData?.configuration?.categoryName || STORE.currProduct.category_name
      if(isMultipleSelectionMode){
        object = getParent(mesh)
        categoryName = object?.userData?.configuration?.categoryName || STORE.currProduct.category_name
      }
      await loadAndApplyTextureToMesh(object,textureInfo,mesh,configuration.product.productName,MODULE_NAME,categoryName,configuration) 
      if(isWardrobeActions && (CURR_SELECTED_MESHES.length === i+1)){
        applyFinishInLoft(CURR_SELECTED_MODULE,textureInfo)
      }
  
    }
    setFinishForUndo(undo,CURR_SELECTED_MESHES,textureInfo)
    disableLoading()
  }else{
    if(configuration.isViewProductMode){
      let object = getAddedProductFromScene()
      updateProductFinish(object,textureInfo)
    }else{
      let allModules = configuration.getModulesList()
      updateFinishOfAllModules(allModules,textureInfo)
    }
    let allAddons = configuration.getAddonsList()
    updateFinishOfAllModules(allAddons,textureInfo)
    logger?.info("customizein3d","Finish -" + textureInfo?.materialCode || "")
  }
  configuration.setIsConfigChanged(true)
}

async function updateFinishOfAllModules(modulesList:any,textureInfo:any){
  // let allMeshes = getAllMeshesFromGroup(group)
  // setFinishForUndo(undo,allMeshes,textureInfo)
  for (const index in modulesList) {
    let module:any = modulesList[index]
    let moduleObject = getObjectByObjectId(scene,module.moduleObjectId) 

    const isWardrobeActions = STORE.getCurrCategory() === "Wardrobe" && !isLoftAttach(moduleObject)
    // if(isWardrobeActions){
    //   attachLoftForUpdateDimensions(moduleObject)
    // }

    let categoryName = moduleObject.userData?.configuration?.categoryName || STORE.currProduct.category_name
    await loadAndApplyTexture(moduleObject,textureInfo,configuration.product.productName,MODULE_NAME,categoryName,configuration).then(data=>{
      
    if(isWardrobeActions){
      applyFinishInLoft(moduleObject,textureInfo)
    }
      if(Number(index)===modulesList.length-1){
        updateScreenshot()
        disableLoading()

        // if(isWardrobeActions){
        //   detachLoftForUpdateDimensions(moduleObject)
        // }

        
      }
    }).catch(err=>{

      // if(isWardrobeActions){
      //   detachLoftForUpdateDimensions(moduleObject)
      // }
      console.log(err)
      disableLoading()
    })

   
  }
}

 

export function render(timestamp:any,frame:any) {
  if(!STORE.isPresentingWebXr){
    controls?.update()
    // stats?.update()
    renderShadow()
    
    labelRenderer.render( scene, camera );
    dimensions.updateLabelsVisibility()
    updateFloorDimensionsPosition()
    // if (isTakeRenderMode) {
    //   renderMultipleViews(scene,renderer,canvasDimensions,VIEWS_FOR_2D)
    //   return;
    // }

    if(sprites.isEnabled){
      sprites.updateSpritesPos()
    }


    // renderer.render(scene,camera)


    composer.render()
  }
 
}

// function animate() { 
//   try {
//     animationFrameId = requestAnimationFrame( animate )
//     render()
//   } catch (error) {
    
//   }
// }


export async function setModulePosition(group:Group,modelObject:any,module:any,configuration:any,subCategory:string){

  let categoryName = STORE?.currProduct?.category_name || module.category_name

  if(subCategory.toLowerCase() === "setsofas"){
    subCategory = "3+2+1"
  }

  if(subCategory.toLowerCase() === "recliner" || categoryName.toLowerCase() === "wardrobe"){
    subCategory = "lshape"
  }

  switch(stringWithoutWhiteSpace(subCategory.toLowerCase()) ){
    case "lshape":
      return setPositionOfLShape(group,modelObject,module,configuration,subCategory)
    break

    case "3+2+1":
      return setPositionOf321(modelObject,module,subCategory,configuration)
    break

    default:
      return setPositionOf321(modelObject,module,subCategory,configuration)
  }
}

function setAddonPosition(configuration:Configuration,modelObject,module:any) {
  updatePositionOfAddon(modelObject,configuration)
}

export function performObjectAction(action:string){
  switch(action){
    case "Delete":
      let moduleInfo = configuration.getModuleInfoObjectfromModelObject(CURR_SELECTED_MODULE)
      let indexInConfig = configuration.getModuleInfoIndexfromModelObject(CURR_SELECTED_MODULE)
      undo.add("delete",{deletedModuleInfo:moduleInfo,isAddedAsAdon:moduleInfo?.isAddedAsAddon,index:indexInConfig,deletedModel:CURR_SELECTED_MODULE})
      deleteObjectFromScene(scene,spacePlannerImagesGroup,SELECTED_OBJECT_OUTLINE_NAME)
    break

    case "Duplicate":
      showComponentLoader("changeFinishLoader")
      if(STORE.getCurrCategory() === "Wardrobe"){
        let productData = CURR_SELECTED_MODULE.userData.configuration
        // let product = {
        //   product_name:productData.productName,
        //   category_name:productData.categoryName,
        //   sub_category_name:productData.subCategoryName,
        // }
        // loadWardrobeModuleToScene(product)
        appendModel(productData,productData.isAddedAsAddon)
        return
      }
      duplicateObject(scene,configuration,configObj,GROUND_WIDTH,GROUND_HEIGHT,groundGroup,spacePlannerImagesGroup,textureLoader,controls,camera,renderer,SELECTED_OBJECT_OUTLINE_NAME,dragControlsImages)
      // if(STORE.getCurrCategory() === "Wardrobe"){
      //   detachLoftForUpdateDimensions(CURR_SELECTED_MODULE)
      // }
    break

    case "Rotate":
      rotateObject(scene,boundingBox,SELECTED_OBJECT_OUTLINE_NAME,axis)
    break
    default:
    return
  }
  configuration.setIsConfigChanged(true)
  configuration.updateLocalStorage()
}


function setPositionOfLShape(group:any,modelObject:any,module:any,configuration:any,subCategory:string) {
  if(configuration.modules.length === 0){
    modelObject.position.set(0,0,0)
    return true
  }


  let currModuleType = stringWithoutWhiteSpace(module.module_type || module.moduleType || "") 
  let lastElementModuleType = stringWithoutWhiteSpace(configuration.getLastModelModuleType() || "") 
  let cornerModulesLength = configuration.getNumberOfCornerModulesTillLength(modelObject)

  if(currModuleType==="Left"){
    let firstModelObject = configuration.getFirstModelObject() 
    let object = getObjectByObjectId(scene || areaModel, firstModelObject.moduleObjectId)
    let worldPosition = new Vector3(0,0,0)
    object.getWorldPosition(worldPosition)
    modelObject.position.copy(worldPosition)

    configuration.replaceFirstModule(module,modelObject)
    copyModelFinish(configuration,object,modelObject)
    removeObjectFromScene(group,object)
    setTimeout(() => {
      updateOtherModelPositions(group,configuration,boundingBox,axis,subCategory,false)
    }, 200)
    return true
  }

  //if last module is right return
  if(currModuleType==="Right"){
    let rightModule = configuration.getRightModule()
    if(rightModule){
      let rightModel = getObjectByObjectId(scene || areaModel, rightModule.moduleObjectId)
      configuration.replaceRightModule(module,rightModel,modelObject)
      copyModelFinish(configuration,rightModel,modelObject)
      removeObjectFromScene(group,rightModel)
    }else{
      applyPrevModelTexture(group,configuration,modelObject)
      addModuleToConfiguration(module,modelObject,configuration)
    }
    updateModelPosition(modelObject,configuration,boundingBox,axis,subCategory)
    return true
  }
  //If curr and last module typea are corner 
  if(currModuleType ==="Corner" && lastElementModuleType ==="Corner"){
    showToast("Invalid Config",1000,"error")
    return  false 
  }
  //If curr and last module typea are corner 
  if(lastElementModuleType ==="Right" && currModuleType !=="Right"){
    showToast("Invalid Config",1000,"error")
    return  false 
  }
  //If already 2 corner modules are added
  if(currModuleType==="Corner" && cornerModulesLength===2){
 
    showToast("Invalid Config",1000,"error")
    return false
  }
 
  updateModelPosition(modelObject,configuration,boundingBox,axis,subCategory)
  return true
}

function setPositionOf321(modelObject:any,module:any,subCategory:string,configuration:any) {
    if(configuration.modules.length === 0){
      return true
    }
    updateModelPosition(modelObject,configuration,boundingBox,axis,subCategory)
  return true 
}


 
export async function addModelToScene(modelObject:any,name:string){
    group.attach(modelObject)
}

export async function postAddingActions(modelObject:any) {
  assignTextureMap(scene,modelObject)
  modelObject.visible = true
  modelObject.castShadow = true
  groupBoundingBox.setFromObject(group)
  groupBoundingBox.getSize(size)

  if(!IS_SPACE_PLANNER_MODE){
    updateFraming(group,groupBoundingBox,camera,controls)
    updateTotalPrice(configuration,configObj)
  }
  if(IS_SPACE_PLANNER_MODE){
    updateDragIconPosition(group,renderer,camera)
    updateTotalPrice(configuration,configObj)
  }

  postWardrobeAddActions(modelObject)
  if(STORE.getCurrCategory() === "Swing"){
    hideSwingStand(modelObject)
  }

  
  updateScreenshot()
  // setTimeout(() => {
  //   topView.addSprite(modelObject)
  // }, 1000);
  legMeshes.push(...modelObject?.children.filter(currMesh => currMesh?.name.includes("Leg")))
}

export function fitToModuelsScene() {
  updateFraming(group,groupBoundingBox,camera,controls)
}


export async function toggleSpacePlannerMode() {
  if(IS_SPACE_PLANNER_MODE){
    disableSpacePlanner()
    return
  }
  enableSpacePlanner()
}

export async function enableSpacePlanner(){
  showComponentLoader("changeFinishLoader")
  let tweenTime = 500

  composer.skybox.visible = false
  // controls.reset()
  updateFraming(group,groupBoundingBox,camera,controls,false)
  await waitFor(700)

  dimensions.hideDimensions()

  disablelCustomizationMode()
  let center = getObjectDimensionPosition(null,groundGroup).center
  tweenCameraControls(camera,controls,{x:center.x,y:20,z:center.z},{x:center.x,y:0,z:center.z},tweenTime)
  setSpacePlannerControls(controls)
  disableSmoothControls(controls)
  await waitFor(tweenTime + 500)
  camera = switchCamera(controls,camera,orthoCamera,IS_SPACE_PLANNER_MODE)
  IS_SPACE_PLANNER_MODE = true
  dimensions.setIsSpacePlannerMode(true)
  composer.onWindowResize(renderer,camera,labelRenderer,IS_SPACE_PLANNER_MODE)
 
  await waitFor(500)
  $("#moduelsDragIcon").removeClass("display-none")
  $("#moduelsDragIcon").removeClass("visibility-hidden")
  updateDragIconPosition(group,renderer,camera)
  updateRoomLayoutInformation(scene,GROUND_GROUP_NAME,boundingBox)
  spacePlannerImagesGroup.traverse(mesh=>{
    mesh.layers.set(0)
  },true)
  showObjects(scene,[DRAG_ICON_NAME,GROUND_OUTLINE_NAME,SPACE_PLANNER_IMAGES_GROUP_NAME])
  updateScreenshot()
  disableLoading()
  floorplanner.toggleVisibility(true)
  floorplanner.setIsSpacePlannerMode(true)
  // GRID.visible = !isScreenshotMode
  logger?.info("customizein3d","E-SP")
  $(".floor-dimensions").addClass("--is-active")

  if(STORE.getCurrCategory() === "Wardrobe"){
    attachAllLoftForUpdateDimensions()
  }


  
}



export async function disableSpacePlanner(isScreenshotMode:boolean = false) {
  showComponentLoader("changeFinishLoader")
  $("#moduelsDragIcon").addClass("display-none")
  composer.skybox.visible = true

  if(STORE.getCurrCategory() === "Wardrobe"){
    detachAllLoftForUpdateDimensions()
  }

  // topView.reset()

  floorplanner.toggleVisibility(false)
  floorplanner.setIsSpacePlannerMode(false)
  $(".floor-dimensions").remove()
  deselectModule()
  updateScreenshot()
  camera = switchCamera(controls,camera,perspectiveCamera,IS_SPACE_PLANNER_MODE)
  updateCamera(TWEEN,configuration,camera,groupBoundingBox,group,controls)
 
  hideFloorDimensions()
  disableDragging()
  hideObjects(scene,[DRAG_ICON_NAME,GROUND_OUTLINE_NAME,SPACE_PLANNER_IMAGES_GROUP_NAME])
  enableSmoothControls(controls)

  composer.onWindowResize(renderer,camera,labelRenderer,IS_SPACE_PLANNER_MODE)
  await waitFor(600)
  IS_SPACE_PLANNER_MODE = false
  dimensions.setIsSpacePlannerMode(false)
  updateFraming(group,groupBoundingBox,camera,controls,false)

  spacePlannerImagesGroup.traverse(mesh=>{
    mesh.layers.set(maskForSpacePlannerImages)
  },true)

  disableLoading()
  logger?.info("customizein3d","D-SP")

  setInitialOrbitControls(controls)
  camera.near = 0.1
  camera.far = 2000
  camera.updateProjectionMatrix();
  // GRID.visible = false

  $("#roomLayoutContainer").addClass("display-none")
  if(isScreenshotMode){
    $(".customize-canvas-wrapper").width(getWindowWidth())
    $(".customize-canvas-wrapper").height(getWindowHeight() - $(".customize-3d-header").height())
    fitRendererIntoElement(renderer,camera)
    fitRendererIntoElement(labelRenderer,camera)
    $(".customize-canvas-controls-wrapper").css("visibility","visible")
    dimensions.hideDimensions()
  }
}


export async function enableSpacePlannerForSS(){
  if(!IS_SPACE_PLANNER_MODE){
    let tweenTime = 0
    // let center = topView.getWorldCenterOfSprites(topView.sprites)
    let center = getObjectDimensionPosition(null,floorplanner.group).center
    // console.log(center,data.dimensions)
    
    // center.x = data.center.x - data.dimensions.dimX
    // center.y = data.center.y
    // center.z = data.center.z - data.dimensions.dimZ
    // topView.updatePerspectiveCamera()
    tweenCameraControls(camera,controls,{x:center.x,y:1,z:center.z},{x:center.x,y:0,z:center.z},tweenTime)
    await waitFor(tweenTime + 100)
    camera = switchCamera(controls,camera,orthoCamera,IS_SPACE_PLANNER_MODE)
    await waitFor(100)
    composer.onWindowResize(renderer,camera,labelRenderer,IS_SPACE_PLANNER_MODE)
    // topView.updateCamera(topView.group)
    // await waitFor(200)
    // showObjects(scene,[GROUND_OUTLINE_NAME])
    // floorplanner.toggleVisibility(true)
  }
  
}

export function enableFloorplanMode() {
  if(!IS_SPACE_PLANNER_MODE){
    enableSpacePlanner()
  }
  applicationConfig.functions.customizein3d.setIsFloorplanMode(true)
  showFloorDimensions()
}


export function disableFloorplanMode() {
  if(IS_SPACE_PLANNER_MODE){
    disableSpacePlanner()
  }
  applicationConfig.functions.customizein3d.setIsFloorplanMode(false)
  applicationConfig.functions.customizein3d.toggleSpacePlannerValue(false)
  // disableConfigureMode()
}


export function switchCamera(controls:any,camera:any,cameraToSwitch:any,IS_SPACE_PLANNER_MODE:boolean) {
  camera = cameraToSwitch
  controls.object = camera
  if(!IS_SPACE_PLANNER_MODE){
    orthoCamera.position.copy(perspectiveCamera.position)
    orthoCamera.zoom = getZoomLevel()
  }
  composer.updateCamera(cameraToSwitch)
  camera.updateProjectionMatrix();
  return camera
}

export function getZoomLevel() {
  let windowWidth = getWindowWidth()
  let windowHeight = getWindowHeight()

  //Mobile potrait
  if(windowWidth < 380){
    return 40
  }

  //Mobile potrait
  if(windowWidth < 480){
    return 45
  }

  //Ipad Potrait
  if(windowWidth >= 668 && windowWidth <= 1366 && isPotraitMode()){
    return 55
  }

  // Mobile Landscape
  if(windowWidth >= 480 && windowWidth <= 1024 && windowHeight <= 480 ){
    return 35
  }


  //Ipad landscape
  if(windowWidth >= 668 && windowWidth <= 1920 && !isPotraitMode()){
    return 65
  }
 
  if(windowWidth >= 1920){
    return 105
  }
  return 80
}

export function addToShortlist() {
  let productName = stringWithoutWhiteSpace(configuration.product.productName) 
  shortListItem(productName,configuration,configuration)
  // logger?.info("customizein3d",`Finish` )
}

export function hideObjectActionPanel() {
  $("#productInfoAnnotation").removeClass("--is-active")
  hideActionPanel()
}



export function rotateAllModules() {
  group.rotateOnAxis(axis,-Math.PI/2)
  configuration.updateGroupRotation()
  updateDragIconPosition(group,renderer,camera)
  // logger?.info("customizein3d","Rot")
}



export function fullScreenToggle(){
  customizein3dInitialLayout()
  if($(".customize-canvas-wrapper").hasClass("--is-full-screen")){
    $("#fullScreenToggle").html('<i class="fas fa-expand color-primary"></i>')
  }else{
    $("#fullScreenToggle").html('<i class="fas fa-times color-danger"></i>')
  }
  $(".customize-canvas-wrapper").toggleClass("--is-full-screen")
  $(".action-link-container").toggleClass("--is-full-screen")

  // logger?.info("customizein3d","Full-s")
  composer.onWindowResize(renderer,camera,labelRenderer,IS_SPACE_PLANNER_MODE)
}



export function goBackToPreviousPage(isGoBack:boolean = false) {
  disposeLights()
  disposeMesh()
  disposeMaterils()
  cancelAnimationFrame (animationFrameId)
  disposeVariableHelperFile()
  groundGeometry?.dispose()
  releaseMemory()
  if(isGoBack){
    window.history.back()
  }
}

export function getModuleDimensions(module:any) {
  let result = {
    dimZ:0,
    dimX:0
  }
  let object = getObjectByObjectId(scene,module.moduleObjectId)
  if(object){
    let rotation = MathUtils.radToDeg(object.rotation.y)
    let dim =  getObjectDimensionPosition(null,object).dimensions
    if(Math.abs(rotation) === 90){
      result.dimX = Math.round((dim.dimZ*100) / 2.54)
      result.dimZ = Math.round((dim.dimX*100) / 2.54)
    }else{
      result.dimX = Math.round((dim.dimX*100) / 2.54)
      result.dimZ = Math.round((dim.dimZ*100) / 2.54)
    }
  }
  return result
}


export function releaseMemory() {
  if(scene){
    cleanupScene(scene)
  }
  scene = null
  composer = null
  outlinePass = null
  camera = null
  controls = null
  // group = null
  groundGeometry = null
  groundGroup=undefined 
  orthoCamera=undefined
  perspectiveCamera=undefined
  element=undefined
  spacePlannerImagesGroup=undefined
  axis=undefined
  textureLoader=undefined
  boundingBox=undefined
  renderer=undefined
  dracoLoader=undefined
  addonsGroup=undefined
  //Drag controls for individual 3d models
  dragControls = null
  dragControlsImages = null
  // dragIconControls = null
  IS_DIMENSION_VISIBLE = false
  IS_SPACE_PLANNER_MODE = false
  IS_CUSTOMIZATION_MODE = false
  ALL_MESHES = []
  ALL_MATERIALS = []
}

function disposeLights() {
  scene?.remove(pointsLight)
  scene?.remove(hemiSphereLight)
  pointsLight = null
  hemiSphereLight = null
}

function disposeMesh(){
  for ( let i = 0 ; i < ALL_MESHES.length ; i ++ ) {
    const mesh = ALL_MESHES[i]
    mesh?.material?.dispose()
    mesh?.geometry?.dispose()
    mesh.geometry = null
    mesh.material = null
    delete mesh.geometry 
    delete mesh.material 
  }
}

function disposeMaterils() {
  for ( let i = 0 ; i < ALL_MATERIALS?.length ; i ++ ) {
    const material = ALL_MATERIALS[i]
    material?.dispose()
  }
}