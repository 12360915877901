import { BackSide, BoxGeometry, CubeTextureLoader, CustomBlending, LinearFilter, Mesh, RGBAFormat, ShaderLib, ShaderMaterial, UniformsUtils, Vector2, Vector3, WebGLRenderTarget } from 'three';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';

import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass';
import { OutputPass } from 'three/examples/jsm/postprocessing/OutputPass';

import { N8AOPostPass } from "n8ao";
import { Pass } from 'three/examples/jsm/postprocessing/Pass';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { SSAOPass } from 'three/examples/jsm/postprocessing/SSAOPass.js';
import { CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader';
import { GammaCorrectionShader } from 'three/examples/jsm/shaders/GammaCorrectionShader';
import { getCanvasContainerWidthheight } from '../UI_methods/global';
import { CURR_SELECTED_PRODUCT_GROUP } from './area3dmodel/productConfigure';
import { fitRendererIntoElement } from './common';
import { updateDragIconPosition } from './modules/annotations';
import { group } from './modules/customizein3d';
// import { sRGBEncoding } from "three/src/constants";


export class Composer{
    effectComposer: EffectComposer;
    outlinePass:OutlinePass
    effectFXAA:ShaderPass
    renderPass:RenderPass
    gtaoPass:SSAOPass
    ssaoPass:SSAOPass
    n8aoPass:N8AOPostPass

    skybox:any = null

    constructor(renderer:any,scene:any,camera:any) {
        renderer.setClearColor (0xffffff, 1);

        // this.effectComposer = new EffectComposer( renderer,this.getRenderTarget(renderer))
        this.effectComposer = new EffectComposer( renderer)
        this.renderPass = new RenderPass( scene, camera )
        this.effectComposer.addPass( this.renderPass )

        const outputPass = new OutputPass(GammaCorrectionShader);
        this.effectComposer.addPass( outputPass );


        this.ssaoPass = new SSAOPass(scene, camera);
        this.ssaoPass.kernelRadius = 16;
        this.ssaoPass.minDistance = 0.005;
        this.ssaoPass.maxDistance = 0.1;
        // this.effectComposer.addPass(this.ssaoPass);

        // this.gtaoPass.updateGtaoMaterial( aoParameters );
        // this.gtaoPass.updatePdMaterial( pdParameters );

        // configureOutlinePass()
    
        this.outlinePass = new OutlinePass( new Vector2( window.innerWidth, window.innerHeight ), scene, camera )
        prepareOutlinePass(this.outlinePass)
        this.addOutlinePass()

        this.effectFXAA = new ShaderPass( FXAAShader )
        this.effectFXAA.uniforms[ 'resolution' ].value.set( 1 / window.innerWidth, 1 / window.innerHeight );
        this.effectComposer.addPass( this.effectFXAA )
    
        // const gammaCorrectionPass = new ShaderPass(GammaCorrectionShader)
        // this.effectComposer.addPass(gammaCorrectionPass)


      

        // scene.background = new Color(0xffffff)
        this.addSkyBox(scene)

        // const n8aopass = new N8AOPostPass(scene,camera,window.innerWidth,window.innerHeight);
        // this.effectComposer.addPass(n8aopass)
      
    }

    addSkyBox(scene:any){

        let path = 'https://opusassets.s3.ap-south-1.amazonaws.com/public/images/white_texture.png'

        // const loader = new CubeTextureLoader();
        // loader.setPath(path);

        // const cubeMap = loader.load([path,path,path,path,path,path]);
        // cubeMap.encoding = sRGBEncoding;
        //scene.background = cubeMap;

        this.skybox = new Mesh(
            new BoxGeometry(30, 30, 30),
            new ShaderMaterial({
            uniforms: UniformsUtils.clone(ShaderLib.cube.uniforms),
            vertexShader: ShaderLib.cube.vertexShader,
            fragmentShader: ShaderLib.cube.fragmentShader,
            depthTest: false,
            depthWrite: false,
            side: BackSide,
            toneMapped: false
            })
        );
        

        // this.skybox.position.set(0,0,0)

        // skybox.material.uniforms.envMap.value = cubeMap;

        // Object.defineProperty(skybox.material, 'envMap', {

        //     get: function() {

        //     return this.uniforms.envMap.value;

        //     }

        // });
        scene.add(this.skybox);

        // scene.background = new Color(0xffffff);

    }

    addOutlinePass(){
        this.effectComposer.addPass( this.outlinePass )
    }

    removeOutlinePass(){
        // this.effectComposer.removePass( this.outlinePass )
    }

    updateCamera(camera:any){
        this.renderPass.camera = camera
        this.ssaoPass.camera = camera
    }

    render(){
        this.effectComposer.render()
    }

    setObjectsForOutline(object:Array<any>){
        this.outlinePass.selectedObjects = object
        // this.addOutlinePass()
    }

    onWindowResize(renderer:any,camera:any,labelRenderer:CSS2DRenderer,IS_SPACE_PLANNER_MODE:boolean) {

        let width = getCanvasContainerWidthheight("customizer").width
        let height = getCanvasContainerWidthheight("customizer").height

        // labelRenderer?.setSize( width, height );
        fitRendererIntoElement(labelRenderer,camera)
        fitRendererIntoElement(renderer,camera)
        this.effectComposer.setSize( width, height );
        this.effectFXAA.uniforms[ 'resolution' ].value.set( 1 / window.innerWidth, 1 / window.innerHeight )

        updateDragIconPosition(CURR_SELECTED_PRODUCT_GROUP || group,renderer,camera)
        // setRendererDimensions(renderer)
    }


    getRenderTarget(renderer:any){
        const size = renderer?.getSize( new Vector2() );
        const _pixelRatio = renderer?.getPixelRatio();
        const _width = size?.width;
        const _height = size?.height;
    
        //To make Antialiasing work use WebGLMultisampleRenderTarget instead of WebGLRenderTarget
        return new WebGLRenderTarget( _width * _pixelRatio, _height * _pixelRatio, {
        magFilter: LinearFilter,
        format: RGBAFormat,
    } );
    }
}

export function prepareOutlinePass(outlinePass:any) {
    outlinePass.visibleEdgeColor.set("#0058a3")
    // outlinePass.visibleEdgeColor.set("#ffffff")
    // outlinePass.hiddenEdgeColor.set("#190a05")
    outlinePass.hiddenEdgeColor.set("#ffffff")
    outlinePass.edgeStrength = 3
    outlinePass.edgeThickness = 1
    outlinePass.edgeGlow = 0
}




export function updateCameraInComposer(scene:any,camera:any,composer:any,outlinePass:any) {
   
    const renderPass = new RenderPass( scene, camera );
    composer.addPass( renderPass );
    configureOutlinePass()
    outlinePass = new OutlinePass( new Vector2( window.innerWidth, window.innerHeight ), scene, camera );
    composer.addPass( outlinePass );

    return {
        composer:composer,
        outlinePass:outlinePass
    }
}

export function configureOutlinePass() {

    const BlurDirectionX = new Vector2( 1.0, 0.0 );
    const BlurDirectionY = new Vector2( 0.0, 1.0 );

    OutlinePass.prototype = Object.assign( Object.create( Pass.prototype ), {

        constructor: OutlinePass,
    
        dispose: function () {
    
            this.renderTargetMaskBuffer.dispose();
            this.renderTargetDepthBuffer.dispose();
            this.renderTargetMaskDownSampleBuffer.dispose();
            this.renderTargetBlurBuffer1.dispose();
            this.renderTargetBlurBuffer2.dispose();
            this.renderTargetEdgeBuffer1.dispose();
            this.renderTargetEdgeBuffer2.dispose();
    
        },
    
        setSize: function ( width, height ) {
    
            this.renderTargetMaskBuffer.setSize( width, height );
    
            var resx = Math.round( width / this.downSampleRatio );
            var resy = Math.round( height / this.downSampleRatio );
            this.renderTargetMaskDownSampleBuffer.setSize( resx, resy );
            this.renderTargetBlurBuffer1.setSize( resx, resy );
            this.renderTargetEdgeBuffer1.setSize( resx, resy );
            this.separableBlurMaterial1.uniforms[ "texSize" ].value.set( resx, resy );
    
            resx = Math.round( resx / 2 );
            resy = Math.round( resy / 2 );
    
            this.renderTargetBlurBuffer2.setSize( resx, resy );
            this.renderTargetEdgeBuffer2.setSize( resx, resy );
    
            this.separableBlurMaterial2.uniforms[ "texSize" ].value.set( resx, resy );
    
        },
    
        changeVisibilityOfSelectedObjects: function ( bVisible ) {
    
            function gatherSelectedMeshesCallBack( object ) {
    
                if ( object.isMesh ) {
    
                    if ( bVisible ) {
    
                        object.layers.mask = object.userData.oldMask;
                        delete object.userData.oldMask;
    
                    } else {
    
                        object.userData.oldMask = object.layers.mask;
                        object.layers.set( 1 );
    
                    }
    
                }
    
            }
    
            for ( var i = 0; i < this.selectedObjects.length; i ++ ) {
    
                var selectedObject = this.selectedObjects[ i ];
                gatherSelectedMeshesCallBack( selectedObject );
    
            }
    
        },
    
        changeVisibilityOfNonSelectedObjects: function ( bVisible ) {
    
            var selectedMeshes = [];
    
            function gatherSelectedMeshesCallBack( object ) {
    
                if ( object.isMesh ) selectedMeshes.push( object );
    
            }
    
            for ( var i = 0; i < this.selectedObjects.length; i ++ ) {
    
                var selectedObject = this.selectedObjects[ i ];
                gatherSelectedMeshesCallBack( selectedObject );
    
            }
    
            function VisibilityChangeCallBack( object ) {

                if ( object.isMesh || object.isLine || object.isSprite ) {
    
                    var bFound = false;
    
                    for ( var i = 0; i < selectedMeshes.length; i ++ ) {
    
                        var selectedObjectId = selectedMeshes[ i ].id;
    
                        if ( selectedObjectId === object.id ) {
    
                            bFound = true;
                            break;
    
                        }
    
                    }
    
                    if ( ! bFound ) {
    
                        var visibility = object.visible;
    
                        if ( ! bVisible || object.bVisible ) object.layers.set( bVisible ? 0 : 1 );

                        object.bVisible = visibility;
    
                    }
    
                }
    
            }
    
            this.renderScene.traverse( VisibilityChangeCallBack );
    
        },
    
        updateTextureMatrix: function () {
    
            this.textureMatrix.set( 0.5, 0.0, 0.0, 0.5,
                0.0, 0.5, 0.0, 0.5,
                0.0, 0.0, 0.5, 0.5,
                0.0, 0.0, 0.0, 1.0 );
            this.textureMatrix.multiply( this.renderCamera.projectionMatrix );
            this.textureMatrix.multiply( this.renderCamera.matrixWorldInverse );
    
        },
    
     
    
        getPrepareMaskMaterial: function () {
    
            return new ShaderMaterial( {
    
                uniforms: {
                    "depthTexture": { value: null },
                    "cameraNearFar": { value: new Vector2( 0.5, 0.5 ) },
                    "textureMatrix": { value: null }
                },
    
                vertexShader: [
                    '#include <morphtarget_pars_vertex>',
                    '#include <skinning_pars_vertex>',
    
                    'varying vec4 projTexCoord;',
                    'varying vec4 vPosition;',
                    'uniform mat4 textureMatrix;',
    
                    'void main() {',
    
                    '	#include <skinbase_vertex>',
                    '	#include <begin_vertex>',
                    '	#include <morphtarget_vertex>',
                    '	#include <skinning_vertex>',
                    '	#include <project_vertex>',
    
                    '	vPosition = mvPosition;',
                    '	vec4 worldPosition = modelMatrix * vec4( position, 1.0 );',
                    '	projTexCoord = textureMatrix * worldPosition;',
    
                    '}'
                ].join( '\n' ),
    
                fragmentShader: [
                    '#include <packing>',
                    'varying vec4 vPosition;',
                    'varying vec4 projTexCoord;',
                    'uniform sampler2D depthTexture;',
                    'uniform vec2 cameraNearFar;',
    
                    'void main() {',
    
                    '	float depth = unpackRGBAToDepth(texture2DProj( depthTexture, projTexCoord ));',
                    '	float viewZ = - DEPTH_TO_VIEW_Z( depth, cameraNearFar.x, cameraNearFar.y );',
                    '	float depthTest = (-vPosition.z > viewZ) ? 1.0 : 0.0;',
                    '	gl_FragColor = vec4(0.0, depthTest, 1.0, 1.0);',
    
                    '}'
                ].join( '\n' )
    
            } );
    
        },
    
        getEdgeDetectionMaterial: function () {
    
            return new ShaderMaterial( {
    
                uniforms: {
                    "maskTexture": { value: null },
                    "texSize": { value: new Vector2( 0.5, 0.5 ) },
                    "visibleEdgeColor": { value: new Vector3( 1.0, 1.0, 1.0 ) },
                    "hiddenEdgeColor": { value: new Vector3( 1.0, 1.0, 1.0 ) },
                },
    
                vertexShader:
                    "varying vec2 vUv;\n\
                    void main() {\n\
                        vUv = uv;\n\
                        gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );\n\
                    }",
    
                fragmentShader:
                    "varying vec2 vUv;\
                    uniform sampler2D maskTexture;\
                    uniform vec2 texSize;\
                    uniform vec3 visibleEdgeColor;\
                    uniform vec3 hiddenEdgeColor;\
                    \
                    void main() {\n\
                        vec2 invSize = 1.0 / texSize;\
                        vec4 uvOffset = vec4(1.0, 0.0, 0.0, 1.0) * vec4(invSize, invSize);\
                        vec4 c1 = texture2D( maskTexture, vUv + uvOffset.xy);\
                        vec4 c2 = texture2D( maskTexture, vUv - uvOffset.xy);\
                        vec4 c3 = texture2D( maskTexture, vUv + uvOffset.yw);\
                        vec4 c4 = texture2D( maskTexture, vUv - uvOffset.yw);\
                        float diff1 = (c1.r - c2.r)*0.5;\
                        float diff2 = (c3.r - c4.r)*0.5;\
                        float d = length( vec2(diff1, diff2) );\
                        float a1 = min(c1.g, c2.g);\
                        float a2 = min(c3.g, c4.g);\
                        float visibilityFactor = min(a1, a2);\
                        vec3 edgeColor = 1.0 - visibilityFactor > 0.001 ? visibleEdgeColor : hiddenEdgeColor;\
                        gl_FragColor = vec4(edgeColor, 1.0) * vec4(d);\
                    }"
            } );
    
        },
    
        getSeperableBlurMaterial: function ( maxRadius ) {
    
            return new ShaderMaterial( {
    
                defines: {
                    "MAX_RADIUS": maxRadius,
                },
    
                uniforms: {
                    "colorTexture": { value: null },
                    "texSize": { value: new Vector2( 0.5, 0.5 ) },
                    "direction": { value: new Vector2( 0.5, 0.5 ) },
                    "kernelRadius": { value: 1.0 }
                },
    
                vertexShader:
                    "varying vec2 vUv;\n\
                    void main() {\n\
                        vUv = uv;\n\
                        gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );\n\
                    }",
    
                fragmentShader:
                    "#include <common>\
                    varying vec2 vUv;\
                    uniform sampler2D colorTexture;\
                    uniform vec2 texSize;\
                    uniform vec2 direction;\
                    uniform float kernelRadius;\
                    \
                    float gaussianPdf(in float x, in float sigma) {\
                        return 0.39894 * exp( -0.5 * x * x/( sigma * sigma))/sigma;\
                    }\
                    void main() {\
                        vec2 invSize = 1.0 / texSize;\
                        float weightSum = gaussianPdf(0.0, kernelRadius);\
                        vec4 diffuseSum = texture2D( colorTexture, vUv) * weightSum;\
                        vec2 delta = direction * invSize * kernelRadius/float(MAX_RADIUS);\
                        vec2 uvOffset = delta;\
                        for( int i = 1; i <= MAX_RADIUS; i ++ ) {\
                            float w = gaussianPdf(uvOffset.x, kernelRadius);\
                            vec4 sample1 = texture2D( colorTexture, vUv + uvOffset);\
                            vec4 sample2 = texture2D( colorTexture, vUv - uvOffset);\
                            diffuseSum += ((sample1 + sample2) * w);\
                            weightSum += (2.0 * w);\
                            uvOffset += delta;\
                        }\
                        gl_FragColor = diffuseSum/weightSum;\
                    }"
            } );
    
        },
    
        getOverlayMaterial: function () {
    
            return new ShaderMaterial( {
    
                uniforms: {
                    "maskTexture": { value: null },
                    "edgeTexture1": { value: null },
                    "edgeTexture2": { value: null },
                    "patternTexture": { value: null },
                    "edgeStrength": { value: 1.0 },
                    "edgeGlow": { value: 1.0 },
                    "usePatternTexture": { value: 0.0 }
                },
    
                vertexShader:
                    "varying vec2 vUv;\n\
                    void main() {\n\
                        vUv = uv;\n\
                        gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );\n\
                    }",
    
                fragmentShader:
                    "varying vec2 vUv;\
                    uniform sampler2D maskTexture;\
                    uniform sampler2D edgeTexture1;\
                    uniform sampler2D edgeTexture2;\
                    uniform sampler2D patternTexture;\
                    uniform float edgeStrength;\
                    uniform float edgeGlow;\
                    uniform bool usePatternTexture;\
                    \
                    void main() {\
                        vec4 edgeValue1 = texture2D(edgeTexture1, vUv);\
                        vec4 edgeValue2 = texture2D(edgeTexture2, vUv);\
                        vec4 maskColor = texture2D(maskTexture, vUv);\
                        vec4 patternColor = texture2D(patternTexture, 6.0 * vUv);\
                        float visibilityFactor = 1.0 - maskColor.g > 0.0 ? 1.0 : 0.5;\
                        vec4 edgeValue = edgeValue1 + edgeValue2 * edgeGlow;\
                        vec4 finalColor = edgeStrength * maskColor.r * edgeValue;\
                        if(usePatternTexture)\
                            finalColor += + visibilityFactor * (1.0 - maskColor.r) * (1.0 - patternColor.r);\
                        gl_FragColor = finalColor;\
                    }",
                blending: CustomBlending,
                depthTest: false,
                depthWrite: false,
                transparent: true
            } );
    
        },

        
	render( renderer, writeBuffer, readBuffer, deltaTime, maskActive ) {

		if ( this.selectedObjects.length > 0 ) {

			renderer.getClearColor( this._oldClearColor );
			this.oldClearAlpha = renderer.getClearAlpha();
			const oldAutoClear = renderer.autoClear;

			renderer.autoClear = false;

			if ( maskActive ) renderer.state.buffers.stencil.setTest( false );

			renderer.setClearColor( 0xffffff, 1 );

			// Make selected objects invisible
			this.changeVisibilityOfSelectedObjects( false );

			const currentBackground = this.renderScene.background;
			this.renderScene.background = null;

			// 1. Draw Non Selected objects in the depth buffer
			this.renderScene.overrideMaterial = this.depthMaterial;
			renderer.setRenderTarget( this.renderTargetDepthBuffer );
			renderer.clear();
			renderer.render( this.renderScene, this.renderCamera );

			// Make selected objects visible
			this.changeVisibilityOfSelectedObjects( true );

			// Update Texture Matrix for Depth compare
			this.updateTextureMatrix();

			// Make non selected objects invisible, and draw only the selected objects, by comparing the depth buffer of non selected objects
			this.changeVisibilityOfNonSelectedObjects( false );
			this.renderScene.overrideMaterial = this.prepareMaskMaterial;
			this.prepareMaskMaterial.uniforms[ 'cameraNearFar' ].value.set( this.renderCamera.near, this.renderCamera.far );
			this.prepareMaskMaterial.uniforms[ 'depthTexture' ].value = this.renderTargetDepthBuffer.texture;
			this.prepareMaskMaterial.uniforms[ 'textureMatrix' ].value = this.textureMatrix;
			renderer.setRenderTarget( this.renderTargetMaskBuffer );
			renderer.clear();
			renderer.render( this.renderScene, this.renderCamera );
			this.renderScene.overrideMaterial = null;
			this.changeVisibilityOfNonSelectedObjects( true );

			this.renderScene.background = currentBackground;

			// 2. Downsample to Half resolution
			this.fsQuad.material = this.materialCopy;
			this.copyUniforms[ 'tDiffuse' ].value = this.renderTargetMaskBuffer.texture;
			renderer.setRenderTarget( this.renderTargetMaskDownSampleBuffer );
			renderer.clear();
			this.fsQuad.render( renderer );

			this.tempPulseColor1.copy( this.visibleEdgeColor );
			this.tempPulseColor2.copy( this.hiddenEdgeColor );

			if ( this.pulsePeriod > 0 ) {

				const scalar = ( 1 + 0.25 ) / 2 + Math.cos( performance.now() * 0.01 / this.pulsePeriod ) * ( 1.0 - 0.25 ) / 2;
				this.tempPulseColor1.multiplyScalar( scalar );
				this.tempPulseColor2.multiplyScalar( scalar );

			}

			// 3. Apply Edge Detection Pass
			this.fsQuad.material = this.edgeDetectionMaterial;
			this.edgeDetectionMaterial.uniforms[ 'maskTexture' ].value = this.renderTargetMaskDownSampleBuffer.texture;
			this.edgeDetectionMaterial.uniforms[ 'texSize' ].value.set( this.renderTargetMaskDownSampleBuffer.width, this.renderTargetMaskDownSampleBuffer.height );
			this.edgeDetectionMaterial.uniforms[ 'visibleEdgeColor' ].value = this.tempPulseColor1;
			this.edgeDetectionMaterial.uniforms[ 'hiddenEdgeColor' ].value = this.tempPulseColor2;
			renderer.setRenderTarget( this.renderTargetEdgeBuffer1 );
			renderer.clear();
			this.fsQuad.render( renderer );

			// 4. Apply Blur on Half res
			this.fsQuad.material = this.separableBlurMaterial1;
			this.separableBlurMaterial1.uniforms[ 'colorTexture' ].value = this.renderTargetEdgeBuffer1.texture;
			this.separableBlurMaterial1.uniforms[ 'direction' ].value = BlurDirectionX;
			this.separableBlurMaterial1.uniforms[ 'kernelRadius' ].value = this.edgeThickness;
			renderer.setRenderTarget( this.renderTargetBlurBuffer1 );
			renderer.clear();
			this.fsQuad.render( renderer );
			this.separableBlurMaterial1.uniforms[ 'colorTexture' ].value = this.renderTargetBlurBuffer1.texture;
			this.separableBlurMaterial1.uniforms[ 'direction' ].value = BlurDirectionY;
			renderer.setRenderTarget( this.renderTargetEdgeBuffer1 );
			renderer.clear();
			this.fsQuad.render( renderer );

			// Apply Blur on quarter res
			this.fsQuad.material = this.separableBlurMaterial2;
			this.separableBlurMaterial2.uniforms[ 'colorTexture' ].value = this.renderTargetEdgeBuffer1.texture;
			this.separableBlurMaterial2.uniforms[ 'direction' ].value = BlurDirectionX;
			renderer.setRenderTarget( this.renderTargetBlurBuffer2 );
			renderer.clear();
			this.fsQuad.render( renderer );
			this.separableBlurMaterial2.uniforms[ 'colorTexture' ].value = this.renderTargetBlurBuffer2.texture;
			this.separableBlurMaterial2.uniforms[ 'direction' ].value = BlurDirectionY;
			renderer.setRenderTarget( this.renderTargetEdgeBuffer2 );
			renderer.clear();
			this.fsQuad.render( renderer );

			// Blend it additively over the input texture
			this.fsQuad.material = this.overlayMaterial;
			this.overlayMaterial.uniforms[ 'maskTexture' ].value = this.renderTargetMaskBuffer.texture;
			this.overlayMaterial.uniforms[ 'edgeTexture1' ].value = this.renderTargetEdgeBuffer1.texture;
			this.overlayMaterial.uniforms[ 'edgeTexture2' ].value = this.renderTargetEdgeBuffer2.texture;
			this.overlayMaterial.uniforms[ 'patternTexture' ].value = this.patternTexture;
			this.overlayMaterial.uniforms[ 'edgeStrength' ].value = this.edgeStrength;
			this.overlayMaterial.uniforms[ 'edgeGlow' ].value = this.edgeGlow;
			this.overlayMaterial.uniforms[ 'usePatternTexture' ].value = this.usePatternTexture;


			if ( maskActive ) renderer.state.buffers.stencil.setTest( true );

			renderer.setRenderTarget( readBuffer );
			this.fsQuad.render( renderer );

			renderer.setClearColor( this._oldClearColor, this.oldClearAlpha );
			renderer.autoClear = oldAutoClear;

		}

		if ( this.renderToScreen ) {

			this.fsQuad.material = this.materialCopy;
			this.copyUniforms[ 'tDiffuse' ].value = readBuffer.texture;
			renderer.setRenderTarget( null );
			this.fsQuad.render( renderer );

		}

	}
    
    } );
}