import React from "react";
import { closeWardrobeModulePanel, resizeLoft, toggleBase, toggleLoft } from "../../../../../utils/customizein3d/modules/wardrobe";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import ResizeModuleOptions from "./ResizeModuleOptions";
 
const WardrobeModuleControls: React.FC<{
}> = (props) => {



  const mirrors = [
    {
      mirror_name:"",
      imageSrc:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvhcZ0-mjyz7daWo6oxGSWf_59lE9SyF2GkA&s"
    },
    {
      mirror_name:"",
      imageSrc:"https://m.media-amazon.com/images/I/412fNZl8OdL.jpg"
    },
    {
      mirror_name:"",
      imageSrc:"https://m.media-amazon.com/images/I/51q-BH6EK6L._AC_UF894,1000_QL80_.jpg"
    },
    {
      mirror_name:"",
      imageSrc:"https://m.media-amazon.com/images/I/416UmA7wa7L._AC_UF350,350_QL80_DpWeblab_.jpg"
    },
    {
      mirror_name:"",
      imageSrc:"https://www.ikea.com/in/en/images/products/svansele-mirror-gold-colour__0898210_pe813213_s5.jpg"
    }
  ]

  
  const handles = [
    {
      handle_name:"",
      imageSrc:"https://img.fruugo.com/product/5/14/282990145_max.jpg"
    },
    {
      handle_name:"",
      imageSrc:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtirPvhm-Np96ht5KYkjeK5ENw6HCmqNVxwcHalgZNtBBzr0TifiVrXJgCLXjz4tMB48g&usqp=CAU"
    },
    {
      handle_name:"",
      imageSrc:"https://image.made-in-china.com/202f0j00ZDHqTblBrdoc/Modern-96mm-Zinc-Alloy-Drawer-Square-Hardware-Wardrobe-Handles.webp"
    },
    {
      handle_name:"",
      imageSrc:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRS09aRD3y1fhsCOpvmrSmvqS1y4u-BxpFwtuAkleuEjSL9Y3m81xjMfFuIojU8Iutp1bY&usqp=CAU"
    },
    {
      handle_name:"",
      imageSrc:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTuvQEpcM5zBF4dbUA48S8jN3wo_F_Ehx9wys_k7SoOVU4oP_lSkL_jasB9gJ3jxOseRA&usqp=CAU"
    }
  ]



  function LoftResize(){
    return(
      <div className="display-flex-row">

        <Button2
          configObj={{}}
          action={() => resizeLoft("minus")}
          label={""}
          fontAwesomeIcon={"fas fa-minus color-black"}
          iconPos="left"
          classNames={"shadow-light button-ex-small"}
        />
        
        <div className="center-aligned-row space-around" style={{minWidth:"8rem"}}>
          <div className="label">Height:</div>
          <div id="loftHeightValue" className="color-danger center-aligned-column marginleft5">
            20'
          </div>
        </div>
        
        <Button2
          configObj={{}}
          action={() => resizeLoft("plus")}
          label={""}
          fontAwesomeIcon={"fas fa-plus color-black"}
          iconPos="left"
          classNames={"shadow-light button-ex-small"}
        />

      </div>
    )
  }

 
    
  return (
    <div id="wardrobeModuePanel" className="full-height-width top-left padding0 zindex100" style={{display:"none"}}>

        <div className="header-content position-relative borde border-top border-bottom shadow-medium">
          <div className="heading2 left-middle display-flex-row no-wrap">
            <div className="right">
              <Button2
                configObj={{id:""}}
                action={()=>{closeWardrobeModulePanel()}}
                label={""}
                fontAwesomeIcon={"fas fa-chevron-left color-black"}
                iconPos="left"
                classNames={"shadow-light button-small"}
              />
            </div>
            <div className="heading1 marginleft5 center-aligned-column" id="modularPanelHeading">
              Wardrobe2
            </div>
          </div>
          
            
        </div>

       

        <div className="body-content-no-footer full-width padding10 overflow-y-scroll bg-color-white">

          <div className="white-card shadow-medium padding10 ">
            <div className="display-flex-row space-between">
              <div className="heading3 pointer-none">Resize Module</div>
            </div>
            <div className="center-aligned-column marginTop10">
              <ResizeModuleOptions/>
            </div>
          </div>

          <div className="white-card shadow-medium padding10 marginTop10" id="loftResizeContainer">
            <div className="display-flex-row space-between">
              <div className="heading3 pointer-none">Add / Remove Loft</div>
              <div className="heading4 color-danger" id="loftToggleValue">Hidden</div>
            </div>
            <div className="padding5 center-aligned-column">
              <LoftResize/>
            </div>
            <div className="center-aligned-column marginTop10">
              <Button2
                configObj={{id:"loftToggleButton"}}
                action={()=>{toggleLoft()}}
                label={"Show Loft"}
                fontAwesomeIcon={"fas fa-eye color-black"}
                iconPos="left"
                classNames={"shadow-light button-small border-primary"}
              />
            </div>

            <div className="center-aligned-column marginTop10">
              <Button2
                configObj={{id:"baseToggleButton"}}
                action={()=>{toggleBase()}}
                label={"Hide Base"}
                fontAwesomeIcon={"fas fa-eye color-black"}
                iconPos="left"
                classNames={"shadow-light button-small border-primary"}
              />
            </div>


          </div>

          

          <div className="white-card shadow-medium padding10 marginTop10">
            <div className="display-flex-row space-between">
              <div className="display-flex-row ">
                <div className="center-alinged-row">
                  <img src="https://opusassets.s3.ap-south-1.amazonaws.com/public/assets/icon/svg/wardrobe/handle.svg" width={20} alt="" />
                </div>
                <div className="heading3 pointer-none center-alinged-row marginleft5">Handle</div>
              </div>
              <div className="heading4 color-danger">Handle1</div>
            </div>
            <div className="center-aligned-column marginTop10">
              <Button2
                configObj={{id:""}}
                action={()=>{}}
                label={"View Handles"}
                fontAwesomeIcon={"fas fa-arrow-right color-black"}
                iconPos="right"
                classNames={"shadow-light button-small"}
              />
            </div>
          </div>

          <div className="white-card shadow-medium padding10 marginTop10">
            <div className="display-flex-row space-between">
              <div className="display-flex-row ">
                <div className="center-alinged-row">
                  <img src="https://opusassets.s3.ap-south-1.amazonaws.com/public/assets/icon/svg/wardrobe/mirror.svg" width={20} alt="" />
                </div>
                <div className="heading3 pointer-none center-alinged-row marginleft5">Mirror</div>
              </div>
              <div className="heading4 color-danger">Not selected</div>
            </div>
            <div className="center-aligned-column heading4 marginTop10">
              No Mirror Avaialable
            </div>
          </div>
           
        </div>

        {/* <div className="footer-content center-aligned-column">
            
        </div> */}

    </div>
  );
};

export default WardrobeModuleControls;
