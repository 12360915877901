import React, { useEffect, useState } from "react";
import { hideComponentLoader } from "../../../../utils/UI_methods/global";
import Loader from "../../../common/shared-components/main-ui/Loader";
import Button2 from "../../../common/ui-components/buttons/Button2";
import { applicationConfig } from "../../../../utils/methods";
import $ from "jquery"
 

const ConfiguratorLinks: React.FC<{
  index:number
}> = (props) => {

 
  const[isOpenConfigurator,setisOpenConfigurator] = useState(false)

  useEffect(()=>{
    applicationConfig.setFunctionRef("elevate",{setisOpenConfigurator:setisOpenConfigurator})
  },[])


  function Configurator1() {
    return(
      <div className="full-width center-aligned-column" style={{height: "45rem",backgroundSize:"cover", backgroundImage: "url('https://opusassets.s3.ap-south-1.amazonaws.com/public/elevate/images/Configurator3.jpg')", backgroundRepeat: "no-repeat"}}>

        <div className="full-width position-relative white-card" style={{padding:"4rem 2rem",width:"100%",maxWidth:"65rem",background:"rgba(255,255,255,0.8)"}}>
          {/* <img style={{objectFit:"cover"}} className="full-height-width" src="" alt="" /> */}
        
          <div className="">
            <div className="heading1 text-center " style={{fontSize:"2.5rem",color:"#363837"}}>Create Your Own Unique Sofa</div>
            <div className="heading4 color-black text-center font-medium line-height-2" style={{marginTop:"2rem"}}>
              Our Visual <span style={{ color: '#ff5733'}} className="font-medium">Product 3D Configurator</span> platform empowers customers to design and order custom products independently, streamlining the process by automating configuration, pricing, and quote generation.
            </div>
            <div className="center-aligned-column" style={{marginTop:"2rem"}}>
              <Button2
                  configObj={{id:""}}
                  action={()=>{setisOpenConfigurator(true)}}
                  label={"Try Now!"}
                  fontAwesomeIcon={"fas fa-cogs elevate-color-primary font-large"}
                  iconPos=""
                  classNames={"shadow-medium no-border button-ex-large text-center uppercase margin0 elevate-bg-color-warning"}
                />
            </div>
          </div>
        
        </div>


       
      
    </div> 
    )
  }

  function Configurator2() {
    return(
      <div className="configurator-link full-width">

      <div className="section padding0 center-aligned-column">
        <img style={{objectFit:"cover"}} className="full-height-width" src="https://cgifurniture.com/wp-content/uploads/2021/06/3d-augmented-furniture-for-apps-View04.jpg" alt="" />
      </div>

       <div className="section details">
          <div className="heading1 font-ex-large">View In Your Space</div>
          <div className="heading4 color-black ion-margin-top font-normal line-height-2">
          Augmented reality enables customers to visualize a new
          sofa, chair, or bed in their home. They can check the
          color and measurements to be sure the product meets
          all their requirements before hitting the buy button.

          </div>
          <div className="heading4 color-black ion-margin-top font-normal line-height-2">
          Built-in business rules ensure designs are error-free and
          only manufacturable products can be purchased.
          </div>
          <div className="ion-margin-top center-aligned-column">
            <Button2
                configObj={{id:""}}
                action={()=>{setisOpenConfigurator(true)}}
                label={"Explore AR"}
                fontAwesomeIcon={"far fa-eye elevate-color-primary font-large"}
                iconPos=""
                classNames={"shadow-light no-border button-ex-large text-center margin0 elevate-bg-color-warning"}
              />
          </div>
       </div>
      
    </div> 
    )
  }
 

  function onLoadHandle() {
    hideComponentLoader("iFrameLoader")
    const iframe:any = document.getElementById('configuratorIframe');
    // Add an event listener to capture clicks on the button inside the iframe
    iframe.contentWindow.document.addEventListener('click', function(event:any) {
        // Check if the clicked element is a button
        if ($(event.target)?.attr("id")?.includes("closeIframeButton")){
            // Prevent the default action of the button
            event.preventDefault();
            // Refresh the entire browser window
            window.location.reload();
        }
    });
  }
 

  function getSource() {
    return `${window.location.origin}/customizein3d/Fantasy`
  }
 
  return (

    <div className="full-width">
{/* 
      <Modal2
          isOpen = {isOpenConfigurator}
          Content = {()=>(
            <>
            {isOpenConfigurator?
            
            :null}
            </>
          )}
          onDismiss = {()=>setisOpenConfigurator(false)}
          FooterContent={()=>(<></>)}
          heading="Configurator"
          classNames="large-x-modal"
          id=""
      /> */}
        {isOpenConfigurator?
        <div className="full-height-width top-left" style={{position:"fixed",background:"rgba(0,0,0,0.5)",zIndex:1001}}>
          <iframe id="configuratorIframe" onLoad={onLoadHandle} src={`${getSource()}`} className="middle" style={{width:"95%",height:"95%"}}>
          </iframe>

          <div className="top-right padding5" onClick={()=>{setisOpenConfigurator(false)}}>
            <i className="fas fa-times color-white font-large"></i>
          </div>



          <Loader
            id="iFrameLoader"
            data={{message:"Fetching data"}}
            loaderType="dots"
            className="--is-active fade-background-loader"
          />


        </div>
        :null}

            

      
          {props.index===1?
      <Configurator1/>
          :
      <Configurator2/>
          }
      {/* <Configurator2/> */}

    </div>
    
  );
};

export default ConfiguratorLinks;
