import React from "react";
import { showDoors, toggleDoors } from "../../../../../utils/customizein3d/modules/wardrobe";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import $ from "jquery"
 
const InternlStructure: React.FC<{
}> = (props) => {

    
  function showDoorsHandle(){
    // showDoors()
    $("#accordion_3").find(".accordion-header").click()
  }
    
  return (
    <>
    <div className="bottom-middle padding10">
      <Button2
        configObj={{id:""}}
        action={showDoorsHandle}
        label={"Show Doors"}
        fontAwesomeIcon={"fas fa-eye color-danger"}
        iconPos="left"
        classNames={"shadow-medium button-small no-border"}
      />
    </div>
    
    <div className="padding5 wardrobe-doors" onClick={toggleDoors}>
        <img src="https://opusassets.s3.ap-south-1.amazonaws.com/public/assets/images/wardrobe/ShelfLayout1.webp" alt="" width={100} />
    </div>
    
    </>
   
  );
};

export default InternlStructure;
