import { IonModal } from "@ionic/react";
import React, { useEffect, useState } from "react";

import { applicationConfig, getUserType } from "../../../../../utils/methods";
import "../../../../style/ui-components/sharelink.css";
import Button2 from "../../buttons/Button2";
import CollectionsList from "./CollectionsList";
import { getObjectMaterialTypes, getProductModules, getTextures } from "../../../../../services/api";


const ShareLink: React.FC<{
  loaderId:string
  shareItemId:number
  moduleName:string
  textClass:string
  isShareButton:boolean
  Content?:any
  defaultConfiguration?:any
  shareItemDetails?:any
}> = (props) => {


    const[isOpenCollections,setIsOpenCollection] = useState(false)
    const[isDataLoaded,setIsDataLoaded] = useState(null)

    useEffect(()=>{
      if(isOpenCollections){
        if(applicationConfig?.data?.materials){
          setIsDataLoaded(true)
        }else{
          loadApiData()
        }
      }
    },[isOpenCollections])


    async function loadApiData() {
      let promiseArray = [getProductModules()
      ,getObjectMaterialTypes(),getTextures()]
      Promise.all(promiseArray)
      .then(function (apidata) {
          applicationConfig.data.productModules = apidata[0]
          applicationConfig.data.objectMaterialTypes = apidata[1]
          applicationConfig.data.materials = apidata[2]
          setIsDataLoaded(true)
      })
      .catch(function (error) {
        console.log(error);
      });
    }

    function handleShare() {
      setIsOpenCollection(true)
    }

 
  return (
    <React.Fragment>
     
      
    {getUserType()?.toLowerCase()!=="projects"?
      <React.Fragment>
        <IonModal
        isOpen={isOpenCollections && isDataLoaded}
        onDidDismiss={()=>setIsOpenCollection(false)}
        cssClass={"vertical-modal"}
        id=""
      >
        <CollectionsList
         shareItemId={props.shareItemId}
         moduleName={props.moduleName}
         isOpenCollections={isOpenCollections}
         setIsOpenCollection={setIsOpenCollection}
         defaultConfiguration={props.defaultConfiguration}
         shareItemDetails={props.shareItemDetails}
       />
      </IonModal>
      

      {props.isShareButton?
      <>
      {props.Content?
      <div className="pointer center-aligned-column" onClick={handleShare}>
        <props.Content/>
      </div>
      :
        <Button2
          configObj={{}}
          action={handleShare}
          label={"Share configuration"}
          fontAwesomeIcon={"fas fa-cog color-primary"}
          iconPos="left"
          classNames={"shadow-light button-large margin0 bg-color-primary-inverse"}
        />
      }
      
      </>
      
      :
      <Button2
        configObj={{}}
        action={()=>{handleShare()}}
        label={"Share"}
        fontAwesomeIcon={"fas fa-share color-danger"}
        iconPos="right"
        classNames={"no-shadow margin0 store-bg-color-primary "+props.textClass}
      />
      }

      
      </React.Fragment>
      :null}
      </React.Fragment>
  );
};

export default ShareLink;
