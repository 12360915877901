import { IonPage } from "@ionic/react";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getAllMaterialTypes, getForegroundSavedConfig,getSofaLegs,getMaterialCompanies, getObjectMaterialTypes, getProductModules, getTextures } from "../../../../../services/api";
import { showGlobalToast } from "../../../../../utils/UI_methods/global";
import { getItemFromLocalStorage } from "../../../../../utils/cache/localstorage";
import { IS_SPACE_PLANNER_MODE, disableSpacePlanner, enableSpacePlanner, releaseMemory } from "../../../../../utils/customizein3d/modules/customizein3d";
import { saveScreenShotToS3 } from "../../../../../utils/customizein3d/modules/helper";
import { applicationConfig, getObjectByParameter, getRandomString, getUser } from "../../../../../utils/methods";
import { getRandomNumber } from "../../../../../utils/products/productviewer_shopify";
import { showProductCart } from "../../../../../utils/store/product-cart";
import { STORE, SofaConfiguration, Variant } from "../../../../../utils/store/storeConfiguration";
import ConfigLoadOptions from "../../../../common/modules/customizein3d/common/ConfigLoadOptions";
import Loader from "../../../../common/shared-components/main-ui/Loader";
import SessionExpired from "../../../../common/ui-components/alerts/SessionExpired";
import ProductConfigSelector from "../product-customizer/AdvanceConfig/AdvanceConfiguration";
import CustomerMobileForm from "./CustomerMobileForm";
import CustomizeIn3D from "./CustomizeIn3D";
import Header from "./Header";
import Summary from "./Summary";

const VariantCreater: React.FC<{
    configObj:any
    isLogin:boolean
    isElevateMode?:boolean
}> = (props) => {
    const parameters:any = useParams();

    const[canvasLoaderId]:any = useState("canvasLoader")
 
    const[tabItems,setTabItems] = useState([])
    const[isOpenAdvanceConfiguration,setisOpenAdvanceConfiguration] = useState(false)
    const[isconfigConfirmed,setIsConfigConfirmed] = useState(false)
    const[isOpenAdvanced,setIsOpenAdvanced,] = useState(false)
    const[product,setProduct] = useState(null)
    const[productConfiguration,setProductConfiguration] = useState(null)
    const[currSelectedTab,setCurrSelectedTab] = useState(null)
    
    const[isDataLoaded,setIsDataLoaded] = useState(null)
    const[isProductChanged,setIsProductChanged] = useState(false)
    
    useEffect(()=>{
        if(props.isLogin){
            // showComponentLoader("mainLoader")
            const productName = parameters.productName
            if(productName){
                let resultProduct = applicationConfig?.data.productsList?.find(currProduct => currProduct.product_name.toLowerCase() === productName.toLowerCase())
                setProduct(resultProduct)
            }else{
                let currProduct = getItemFromLocalStorage("currProduct")
                setProduct(currProduct)
            }

            window.onpopstate = function(event) {
                window.location.reload();   
            };
        }
        // STORE.functionRefs.productCustomizer.openAdvanceConfiguration = openAdvanceConfig
        // return () => {
        //     releaseMemory()
        // };
    },[props.isLogin])

    useEffect(()=>{
        if(product){
            customizein3dAction(product)
            loadApiData(product)
        }
    },[product])

    function changeProduct(product:any) {
        setIsProductChanged(true)
        setProduct(product)
    }


    async function loadApiData(product:any) {
        if(isDataLoaded){
            return
        }
        let promiseArray = [getProductModules(),getMaterialCompanies(),getAllMaterialTypes()
        ,getObjectMaterialTypes(),getTextures(),getForegroundSavedConfig(0,product.product_id),getSofaLegs(product.product_id)]

        Promise.all(promiseArray)
        .then(function (apidata) {
            applicationConfig.data.productModules = apidata[0]
            applicationConfig.data.materialCompaniesList = apidata[1]
            applicationConfig.data.allMaterialTypes = apidata[2]

            //For 3d configuration
            applicationConfig.data.objectMaterialTypes = apidata[3]
            applicationConfig.data.materials = apidata[4]
            applicationConfig.data.customizerSavedConfigs = apidata[5]
            applicationConfig.data.sofaLegs = apidata[6]

            setIsDataLoaded(true)
        })
        .catch(function (error) {
            alert("Token Expired. Please Go back..")
            console.log(error);
        });
    }


 

    function getTabItems(product:any){
        let categoryName = product.category_name
        let label = categoryName === "Cot" || categoryName === "Chairs"?"Choose Module":"Add Modules"
        if(!props.isElevateMode){
           return [
                {index:1,tabName:"AddModules",label:label},
                {index:2,tabName:"ChooseFinish",label:"Choose Finish"},
                {index:3,tabName:"Addons",label:"Addons"},
                {index:4,tabName:"Advanced",label:"Advanced"},
            ] 
        }else{
            return [
                {index:1,tabName:"AddModules",label:label},
                {index:2,tabName:"ChooseFinish",label:"Choose Finish"},
                {index:3,tabName:"SpacePlanner",label:"Space Planner"},
            ]  
        }   
    }

    function customizein3dAction(currProduct:any) {
        let variant = new Variant(getRandomString(8)) 
        let config = new SofaConfiguration(variant,currProduct?.default_configuration)
        config.price = getRandomNumber(12000,15000)
        config.isCustomVariant = true
        STORE.productConfiguration = config
        STORE.currProduct = currProduct
        setProductConfiguration(config)
      }

    function openAdvanceConfig() {
        setisOpenAdvanceConfiguration(true)
    }
  
    useEffect(()=>{
        displayOption()
    },[currSelectedTab])


    function prevHandle(){
        let currIndex = currSelectedTab.index
        if(currIndex === 1){
            return 
        }
        let tab = getObjectByParameter(tabItems,"index",(currIndex-1))
        setCurrSelectedTab(tab)
    }

    function nextHandle(){
        let currIndex = currSelectedTab.index
        if(currIndex === tabItems.length){
            if(props.isElevateMode){
                // setIsOpenConfirm(true)
            }else{
                // setIsOpenSummary(true)
            }
            return
        }
        setCurrSelectedTab(getObjectByParameter(tabItems,"index",(currIndex+1)) || tabItems[0])
    }


    function displayOption() {
        if(currSelectedTab){
            let currSelectedIndex = currSelectedTab.index
            $(".currtab-heading").find(".text").text(currSelectedTab.label)
            $("#productSummaryWrapper").addClass("display-none")
            $(".customizing-option").fadeOut(250)
            $(".controls-header-heading").text(`${currSelectedIndex}. ${currSelectedTab.label}`)
            setTimeout(() => {
              $(`#Option_${currSelectedIndex}`).fadeIn(250)
            }, 250);
            if(props.isElevateMode){
                if(currSelectedTab.tabName === "SpacePlanner"){
                    enableSpacePlanner()
                }else{
                    if(IS_SPACE_PLANNER_MODE){
                        disableSpacePlanner()
                    }
                }
            }
           
        }
        
    }


    function confirmAvancedConfigHandle() {
        showGlobalToast("Saved")
        setIsOpenAdvanced(false) 
        setIsConfigConfirmed(true)
     }


     function clickTabHandle(tabName:any) {
        setCurrSelectedTab(getObjectByParameter(tabItems,"tabName",tabName))
     }
 



 

  
    return (
    <IonPage>
    {props.isLogin && props.configObj?
        <>
        {product && isDataLoaded && productConfiguration?
        <div className="full-height-width store-customizein3d-wrapper">

        <Loader
          id={canvasLoaderId}
          data={{}}
          loaderType="topProgressBar"
          className="--is-active fade-background-loader"
        />

        <Loader
          className="fade-background-loader"
          id="changeFinishLoader"
          data={{}}
          loaderType="dots"
        />

        <Loader
          className="fade-background-loader"
          id="createConfigLoader"
          data={{}}
          loaderType="dotsText"
        />

        {props.isElevateMode?
        <CustomerMobileForm/>
        :null}

        
        <ConfigLoadOptions
        modulesConfigurtion={{}}
        />


        {productConfiguration && product?
            <ProductConfigSelector
                product={product}
                isOpen={isOpenAdvanced}
                setIsOpen={setIsOpenAdvanced}
                productConfiguration={productConfiguration}
                confirmHandle={confirmAvancedConfigHandle}
                isconfigConfirmed={isconfigConfirmed}
            />
        :null}
        
        
        <div className="main-sub-header-wrapper display-none bg-color-white position-relative white-card" style={{zIndex:2,borderRadius:"0"}}>
            <div className="display-flex-row full-height no-wrap bg-color-white pointer" >

                {/* <div className="center-aligned-column zindex1 padding5" style={{width:"7rem"}}>
                    {props.isElevateMode?
                        <Button2
                            configObj={{id:""}}
                            action={prevHandle}
                            label={"Prev"}
                            fontAwesomeIcon={"fas fa-arrow-left color-danger"}
                            iconPos="left"
                            classNames={"no-shadow display-none hide-in-mobile button-small marginleft5 bg-color-light"}
                        />
                    :
                        <Button2
                            configObj={{id:""}}
                            action={goBackHandle}
                            label={"Back"}
                            fontAwesomeIcon={"fas fa-arrow-left color-danger"}
                            iconPos="left"
                            classNames={"no-shadow  button-small marginleft5 bg-color-light"}
                        />
                    }
                    
                </div> */}
                
                {/* <div className="hide-in-" style={{width:`calc(100% - ${!props.isElevateMode?"11rem":"14rem"})`}}>
                    {!props.isElevateMode?
                    <div className="full-width proccess-tab-configurator">
                        <ControlsTab
                            configObj={props.configObj}
                            tabItems={tabItems}
                            currSelectedValue={currSelectedTab.tabName}
                            action={clickTabHandle}
                            theme="black"
                        />
                    </div>
                    :
                    <div className="color-black full-height heading1 hide-in-mobile font-medium center-aligned-column text-no-wrap" >Customizing - {product.product_name}</div>
                    }
                    

                    <div className="middle configurator-mobile-heading">
                        <div className="color-black heading1 font-medium center-aligned-column text-no-wrap" >{product.product_name}</div>
                    </div>
                </div> */}


                   
                
                
            </div>
        </div>

        <Header
            product={product}
            isElevateMode={props.isElevateMode}
            changeProduct={changeProduct}
        />
        
        <div className="position-relative" style={{height:"calc(100% - var(--main-header-height))"}}>
            <CustomizeIn3D 
                isElevateMode={props.isElevateMode} 
                setIsOpenAdvanced={setIsOpenAdvanced} 
                product={product} 
                productConfiguration={productConfiguration}
                changeProduct={changeProduct}
                isProductChanged={isProductChanged}
            />
        </div>
           

    </div>
    :
    <Loader
        id={""}
        data={{message:"Fetching Data..."}}
        loaderType="dotsText"
        className="--is-active fade-background-loader"
    />
    }
    </>
    :
    <SessionExpired/>
    }
    
        
    </IonPage>
    );
};

export default VariantCreater;
